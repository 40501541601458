import * as React from 'react';
import { cn } from 'src/utils/shadcn';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, children, ...props }, ref) => {
    return (
      <div className="relative flex items-center w-full">
        {children && <span className="absolute left-3 text-txt-secondary">{children}</span>}
        <input
          type={type}
          className={cn(
            `flex w-full h-10 pr-4 py-1.5 text-sm text-txt-primary placeholder:text-txt-secondary rounded-sm border border-border-input
             file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none
             disabled:cursor-not-allowed disabled:bg-grey-100 disabled:border-border-light`,
            children ? 'pl-9' : 'pl-4',
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
