import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AiGeneratedOutput = {
  __typename?: 'AIGeneratedOutput';
  category?: Maybe<Scalars['String']>;
  gif?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  source: WorkflowSettingsSource;
};

export type Access = {
  __typename?: 'Access';
  editors: Array<Scalars['String']>;
  owners: Array<Scalars['String']>;
  viewers: Array<Scalars['String']>;
};

export type AccessInput = {
  editors?: InputMaybe<Array<Scalars['String']>>;
  owners?: InputMaybe<Array<Scalars['String']>>;
  viewers?: InputMaybe<Array<Scalars['String']>>;
};

export type AccountSetupOutput = {
  __typename?: 'AccountSetupOutput';
  completed: Scalars['Boolean'];
  link: Scalars['String'];
  step: Scalars['String'];
};

export type AddMessageInput = {
  gif?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  source: WorkflowSettingsSource;
};

export type AiBrandVoice = {
  __typename?: 'AiBrandVoice';
  brand_voice?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  tenant_uuid: Scalars['String'];
  user_content?: Maybe<Scalars['String']>;
};

export type AiConversations = {
  __typename?: 'AiConversations';
  agent_uuid: Scalars['String'];
  context: Array<Context>;
  conversation_uuid: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  tenant_uuid: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  users: Array<Scalars['String']>;
};

export enum AiMessageRoleEnum {
  AGENT = 'AGENT',
  USER = 'USER'
}

export type AiMessages = {
  __typename?: 'AiMessages';
  agent_uuid: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  conversation_uuid: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  message_uuid: Scalars['String'];
  sender?: Maybe<AiMessageRoleEnum>;
  tenant_uuid: Scalars['String'];
  timestamp?: Maybe<Scalars['Int']>;
  user_uuid?: Maybe<Scalars['String']>;
};

export type AllEventsOutput = {
  __typename?: 'AllEventsOutput';
  in_progress?: Maybe<Array<EventDetailedOutput>>;
  past?: Maybe<Array<EventDetailedOutput>>;
  scheduled?: Maybe<Array<EventDetailedOutput>>;
};

export type AnalyticsKeyValuePair = {
  __typename?: 'AnalyticsKeyValuePair';
  option: Scalars['String'];
  value: Scalars['Int'];
};

export type AnalyticsQuestion = {
  __typename?: 'AnalyticsQuestion';
  analytics_data?: Maybe<Array<AnalyticsQuestionData>>;
  analytics_type: AnalyticsTypeEnum;
  question_uuid: Scalars['String'];
};

export type AnalyticsQuestionData = {
  __typename?: 'AnalyticsQuestionData';
  average_score?: Maybe<Scalars['Int']>;
  response_distribution?: Maybe<Array<AnalyticsKeyValuePair>>;
  text_summary?: Maybe<Scalars['String']>;
  trend?: Maybe<Array<AnalyticsTrendKeyValuePair>>;
};

export type AnalyticsTrendKeyValuePair = {
  __typename?: 'AnalyticsTrendKeyValuePair';
  date: Scalars['String'];
  value: Scalars['Int'];
};

export enum AnalyticsTypeEnum {
  BAR_CHART = 'BAR_CHART',
  LINE_CHART = 'LINE_CHART',
  PIE_CHART = 'PIE_CHART',
  TEXT_ANALYSIS = 'TEXT_ANALYSIS'
}

export enum BillingFrequencyEnum {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export type Bot = {
  __typename?: 'Bot';
  id: Scalars['String'];
  token: Scalars['String'];
};

export type BuildFormInput = {
  access: AccessInput;
  addedQuestions: Array<Scalars['String']>;
  deletedQuestions: Array<Scalars['String']>;
  description: Scalars['String'];
  form_uuid: Scalars['String'];
  is_anonymous: Scalars['Boolean'];
  is_new: Scalars['Boolean'];
  questions: Array<FormQuestionInput>;
  settings?: InputMaybe<FormSettingsInput>;
  status: FormTypeEnum;
  template_uuid?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
  updatedQuestions: Array<Scalars['String']>;
};

export type CalendarEventInfo = {
  __typename?: 'CalendarEventInfo';
  account_id: Scalars['String'];
  calendar_id: Scalars['String'];
  calendar_type: Scalars['String'];
  event_id: Scalars['String'];
};

export type CalendarEventInfoInput = {
  account_id: Scalars['String'];
  calendar_id: Scalars['String'];
  calendar_type: Scalars['String'];
  event_id: Scalars['String'];
};

export type CapacityUtilization = {
  __typename?: 'CapacityUtilization';
  date: Scalars['String'];
  utilization: Scalars['Float'];
};

export type ChatDetails = {
  __typename?: 'ChatDetails';
  chat_between: Array<ChatRsvpDetails>;
  chat_uuid: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Int'];
  expiration: Scalars['DateTime'];
  meeting_details: MeetingDetails;
  meeting_time?: Maybe<Scalars['DateTime']>;
  organizer?: Maybe<Scalars['String']>;
  proposed_times: Array<ProposedTimes>;
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
  type: ChatTypeEnum;
};

export enum ChatFrequencyEnum {
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export enum ChatLocationEnum {
  GOOGLE = 'GOOGLE',
  SLACK = 'SLACK'
}

export type ChatRsvp = {
  __typename?: 'ChatRSVP';
  rsvp: ChatRsvpEnum;
  user_uuid: Scalars['String'];
};

export type ChatRsvpDetails = {
  __typename?: 'ChatRSVPDetails';
  image?: Maybe<Scalars['String']>;
  job_title: Scalars['String'];
  preferred_name: Scalars['String'];
  rsvp: ChatRsvpEnum;
  user_uuid: Scalars['String'];
};

export enum ChatRsvpEnum {
  GOING = 'GOING',
  RESCHEDULE = 'RESCHEDULE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export type ChatRsvpInput = {
  rsvp?: InputMaybe<ChatRsvpEnum>;
  user_uuid: Scalars['String'];
};

export type ChatSettings = {
  __typename?: 'ChatSettings';
  discover: ChatStreamSettings;
  mentorship: ChatStreamSettings;
  recurring?: Maybe<Array<OneOnOneSettings>>;
  stay_connected: ChatStreamSettings;
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type ChatStreamSettings = {
  __typename?: 'ChatStreamSettings';
  frequency: ChatFrequencyEnum;
  is_active: Scalars['Boolean'];
};

export enum ChatTypeEnum {
  DISCOVER = 'DISCOVER',
  MENTORSHIP = 'MENTORSHIP',
  ONE_OFF = 'ONE_OFF',
  RECURRING = 'RECURRING',
  STAY_CONNECTED = 'STAY_CONNECTED'
}

export type CommunityData = {
  __typename?: 'CommunityData';
  community_uuid: Scalars['String'];
  interactions: Scalars['Float'];
};

export type CommunityDetailedOutput = {
  __typename?: 'CommunityDetailedOutput';
  communication_channel?: Maybe<Scalars['String']>;
  communication_guidelines?: Maybe<Scalars['String']>;
  community_guidelines?: Maybe<Scalars['String']>;
  community_uuid: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  hosts?: Maybe<Array<CommunityUserDetails>>;
  image?: Maybe<Scalars['String']>;
  is_public?: Maybe<Scalars['Boolean']>;
  members?: Maybe<Array<CommunityUserDetails>>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  tenant_uuid?: Maybe<Scalars['String']>;
};

export type CommunityUserDetails = {
  __typename?: 'CommunityUserDetails';
  image?: Maybe<Scalars['String']>;
  preferred_name: Scalars['String'];
  user_uuid: Scalars['String'];
};

export enum CompanyTypeEnum {
  HYBRID = 'HYBRID',
  REMOTE = 'REMOTE'
}

export type Context = {
  __typename?: 'Context';
  content?: Maybe<Scalars['String']>;
  role?: Maybe<AiMessageRoleEnum>;
};

export type CreateCommunityInput = {
  communication_channel?: InputMaybe<Scalars['String']>;
  communication_guidelines?: InputMaybe<Scalars['String']>;
  community_guidelines?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  hosts: Array<Scalars['String']>;
  members: Array<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  tags: Array<Scalars['String']>;
  tenant_uuid: Scalars['String'];
};

export type CreateEventInput = {
  category?: InputMaybe<EventCategoryEnum>;
  checklist?: InputMaybe<Scalars['String']>;
  communities?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  faq?: InputMaybe<Scalars['String']>;
  final_date?: InputMaybe<Scalars['DateTime']>;
  guests?: InputMaybe<Array<EventGuestInput>>;
  image?: InputMaybe<Scalars['String']>;
  is_poll: Scalars['Boolean'];
  location_uuid?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizers?: InputMaybe<Array<EventGuestInput>>;
  poll_deadline?: InputMaybe<Scalars['DateTime']>;
  poll_options?: InputMaybe<Array<PollOptionInput>>;
  requirements?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
};

export type CreateFeaturesInput = {
  plans: Array<WorkspacePlanEnum>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type CreateFormBatchInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  form_uuid: Scalars['String'];
  settings: FormBatchSettingsInput;
  tags?: InputMaybe<Array<KeyValuePairInput>>;
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
};

export type CreateFormQuestionBankInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  created_by: Scalars['String'];
  label: Scalars['String'];
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  response_type: FormResponseTypeEnum;
  tags?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
  themes?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateFormQuestionInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  form_uuid: Scalars['String'];
  is_required?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  order: Scalars['Int'];
  response_type: FormResponseTypeEnum;
  tenant_uuid: Scalars['String'];
  themes?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateFormResponseInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  batch_uuid: Scalars['String'];
  form_uuid: Scalars['String'];
  qualitative_response?: InputMaybe<Scalars['String']>;
  quantitative_response?: InputMaybe<Array<QuestionOptionInput>>;
  question_uuid: Scalars['String'];
  response_status?: InputMaybe<FormResponseStatusEnum>;
  response_type?: InputMaybe<FormResponseValueEnum>;
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type CreateFormTemplateInput = {
  access: AccessInput;
  archived?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  questions: Array<QuestionReferenceInput>;
  settings: FormSettingsInput;
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
};

export type CreateLocationsInput = {
  address: Scalars['String'];
  address_notes?: InputMaybe<Scalars['String']>;
  amenities?: InputMaybe<Array<Scalars['String']>>;
  capacity_limit: Scalars['Int'];
  community?: InputMaybe<Array<Scalars['String']>>;
  hosts?: InputMaybe<Array<Scalars['String']>>;
  hours_operation?: InputMaybe<HoursOperationInput>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  slack_channel?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  wifi_information?: InputMaybe<WifiInformationInput>;
};

export type CreateRoomBookingInput = {
  description?: InputMaybe<Scalars['String']>;
  end_time?: InputMaybe<Scalars['DateTime']>;
  final_date?: InputMaybe<Scalars['DateTime']>;
  guests: Array<RoomUserInput>;
  location_uuid: Scalars['String'];
  room_booking_name: Scalars['String'];
  room_uuid: Scalars['String'];
  start_time?: InputMaybe<Scalars['DateTime']>;
  tenant_uuid: Scalars['String'];
};

export type CreateRoomInput = {
  capacity?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  location_uuid: Scalars['String'];
  room_name: Scalars['String'];
  tenant_uuid: Scalars['String'];
};

export type CreateTeamInput = {
  label: Scalars['String'];
  leads?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  tenant_uuid: Scalars['String'];
  users?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CreateTenantInput = {
  company_type?: InputMaybe<CompanyTypeEnum>;
  email_signature: Scalars['String'];
  locations?: InputMaybe<CreateLocationsInput>;
  title: Scalars['String'];
};

export type CreateTimeBlockInput = {
  calendar?: InputMaybe<TimeBlockCalendarTypeEnum>;
  calendar_info?: InputMaybe<CalendarEventInfoInput>;
  end: Scalars['DateTime'];
  end_date?: InputMaybe<Scalars['DateTime']>;
  google_cal_event_id?: InputMaybe<Scalars['String']>;
  omitted_dates?: InputMaybe<Array<Scalars['DateTime']>>;
  repeat?: InputMaybe<RepeatFrequencyEnum>;
  resource?: InputMaybe<ResourceInfoInput>;
  start: Scalars['DateTime'];
  tenant_uuid: Scalars['String'];
  type?: InputMaybe<TimeBlockTypeEnum>;
};

export type CreateWatercoolerTopicInput = {
  category: Scalars['String'];
  image_link?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
};

export type CreateWorkflowInput = {
  channel_id: Scalars['String'];
  channel_name: Scalars['String'];
  days: Array<WorkflowSettingsTriggerDayEnum>;
  frequency: WorkflowSettingsFrequencyEnum;
  send_message_as: Scalars['String'];
  send_time?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  time_zone: Scalars['String'];
  workflow_title: Scalars['String'];
  workflow_type: WorkflowSettingsType;
};

export enum CurrencyEnum {
  CAD = 'CAD',
  USD = 'USD'
}

export enum DayOfWeekEnum {
  FRI = 'FRI',
  MON = 'MON',
  SAT = 'SAT',
  SUN = 'SUN',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED'
}

export enum DurationEnum {
  ALL_DAY = 'ALL_DAY',
  HALF_DAY = 'HALF_DAY'
}

export enum DurationFilterEnum {
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export type EventCatalog = {
  __typename?: 'EventCatalog';
  capacity: LabelObj;
  category: EventCategoryEnum;
  checklist?: Maybe<Scalars['String']>;
  cost: LabelObj;
  description: Scalars['String'];
  duration: LabelObj;
  event_uuid: Scalars['String'];
  faq?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  location: Array<Scalars['String']>;
  name: Scalars['String'];
  recommended: Scalars['Boolean'];
  requirements?: Maybe<Scalars['String']>;
  resources?: Maybe<Scalars['String']>;
};

export type EventCatalogInput = {
  capacity?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<EventCategoryEnum>;
  limit?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<EventLocationType>;
  page?: InputMaybe<Scalars['Int']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
};

export type EventCatalogOutput = {
  __typename?: 'EventCatalogOutput';
  events: Array<EventCatalog>;
  total_records: Scalars['Int'];
};

export enum EventCategoryEnum {
  ACTIVE = 'ACTIVE',
  FOOD = 'FOOD',
  FUN = 'FUN',
  GAMES = 'GAMES',
  WORK = 'WORK'
}

export type EventDateRecommendationOutput = {
  __typename?: 'EventDateRecommendationOutput';
  date: Scalars['String'];
  recommended: Scalars['Boolean'];
  statuses?: Maybe<Array<StatusDetails>>;
};

export type EventDetailedOutput = {
  __typename?: 'EventDetailedOutput';
  category?: Maybe<EventCategoryEnum>;
  checklist?: Maybe<Scalars['String']>;
  communities: Array<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  event_uuid: Scalars['String'];
  faq?: Maybe<Scalars['String']>;
  final_date?: Maybe<Scalars['DateTime']>;
  guests: Array<EventUserDetails>;
  image?: Maybe<Scalars['String']>;
  is_poll?: Maybe<Scalars['Boolean']>;
  location_uuid?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  no_times_work?: Maybe<Array<EventUserDetails>>;
  organizers: Array<EventUserDetails>;
  poll_deadline?: Maybe<Scalars['DateTime']>;
  poll_options?: Maybe<Array<PollOptionDetails>>;
  requirements?: Maybe<Scalars['String']>;
  resources?: Maybe<Scalars['String']>;
  status?: Maybe<EventStatusEnum>;
  teams: Array<Scalars['String']>;
  tenant_uuid: Scalars['String'];
};

export type EventGuestInput = {
  rsvp?: InputMaybe<EventRsvpEnum>;
  user_uuid: Scalars['String'];
};

export enum EventLocationType {
  IN_PERSON = 'IN_PERSON',
  VIRTUAL = 'VIRTUAL'
}

export enum EventRsvpEnum {
  GOING = 'GOING',
  MAYBE = 'MAYBE',
  NOT_GOING = 'NOT_GOING',
  PENDING = 'PENDING'
}

export enum EventStatusEnum {
  FINALIZED = 'FINALIZED',
  NOT_FINALIZED = 'NOT_FINALIZED',
  VOTE_COMPLETED = 'VOTE_COMPLETED',
  VOTE_REQUIRED = 'VOTE_REQUIRED'
}

export type EventUser = {
  __typename?: 'EventUser';
  rsvp: EventRsvpEnum;
  user_uuid: Scalars['String'];
};

export type EventUserDetails = {
  __typename?: 'EventUserDetails';
  image?: Maybe<Scalars['String']>;
  preferred_name: Scalars['String'];
  rsvp: EventRsvpEnum;
  user_uuid: Scalars['String'];
};

export type Feature = {
  __typename?: 'Feature';
  feature_uuid: Scalars['String'];
  plans: Array<WorkspacePlanEnum>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type Form = {
  __typename?: 'Form';
  access: Access;
  archived?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  form_uuid: Scalars['String'];
  is_anonymous?: Maybe<Scalars['Boolean']>;
  status: FormTypeEnum;
  template_uuid?: Maybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type FormBatch = {
  __typename?: 'FormBatch';
  archived?: Maybe<Scalars['Boolean']>;
  batch_uuid: Scalars['String'];
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  form_uuid: Scalars['String'];
  settings: FormBatchSettings;
  tags?: Maybe<Array<KeyValuePair>>;
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type FormBatchDetails = {
  __typename?: 'FormBatchDetails';
  archived: Scalars['Boolean'];
  batch_uuid: Scalars['String'];
  completion_rate: Scalars['Float'];
  end_date: Scalars['DateTime'];
  form_uuid: Scalars['String'];
  open_rate: Scalars['Float'];
  responses: Scalars['Int'];
  start_date: Scalars['DateTime'];
  title: Scalars['String'];
};

export type FormBatchReminder = {
  __typename?: 'FormBatchReminder';
  days: Array<DayOfWeekEnum>;
  days_utc: Array<DayOfWeekEnum>;
  frequency: FormReminderFrequencyEnum;
  time: Scalars['String'];
  time_utc: Scalars['String'];
  timezone: Scalars['String'];
};

export type FormBatchReminderInput = {
  days: Array<DayOfWeekEnum>;
  days_utc?: InputMaybe<Array<DayOfWeekEnum>>;
  frequency?: InputMaybe<FormReminderFrequencyEnum>;
  time: Scalars['String'];
  time_utc?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type FormBatchSettings = {
  __typename?: 'FormBatchSettings';
  distribution_list: Array<FormBatchUserStatus>;
  end_date?: Maybe<Scalars['DateTime']>;
  is_single_response: Scalars['Boolean'];
  reminder?: Maybe<FormBatchReminder>;
  start_date?: Maybe<Scalars['DateTime']>;
};

export type FormBatchSettingsInput = {
  distribution_list: Array<FormBatchUserStatusInput>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  is_single_response: Scalars['Boolean'];
  reminder?: InputMaybe<FormBatchReminderInput>;
  start_date?: InputMaybe<Scalars['DateTime']>;
};

export type FormBatchUserStatus = {
  __typename?: 'FormBatchUserStatus';
  opened: Scalars['Boolean'];
  response_status: FormResponseStatusEnum;
  user_uuid: Scalars['String'];
};

export type FormBatchUserStatusInput = {
  opened?: InputMaybe<Scalars['Boolean']>;
  response_status?: InputMaybe<FormResponseStatusEnum>;
  user_uuid: Scalars['String'];
};

export type FormDetails = {
  __typename?: 'FormDetails';
  active_batches: Scalars['Int'];
  form_uuid: Scalars['String'];
  last_updated: Scalars['DateTime'];
  status: FormTypeEnum;
  title: Scalars['String'];
};

export type FormQuantitativeOptionDetails = {
  __typename?: 'FormQuantitativeOptionDetails';
  option_count?: Maybe<Scalars['Float']>;
  option_label: Scalars['String'];
  option_percentage?: Maybe<Scalars['Float']>;
  option_uuid: Scalars['String'];
};

export type FormQuestion = {
  __typename?: 'FormQuestion';
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  form_uuid: Scalars['String'];
  is_locked?: Maybe<Scalars['Boolean']>;
  is_required?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options?: Maybe<Array<QuestionOption>>;
  order: Scalars['Int'];
  question_uuid: Scalars['String'];
  response_type: FormResponseTypeEnum;
  tenant_uuid: Scalars['String'];
  themes?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type FormQuestionBank = {
  __typename?: 'FormQuestionBank';
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  created_by: Scalars['String'];
  label: Scalars['String'];
  options?: Maybe<Array<QuestionOption>>;
  question_uuid: Scalars['String'];
  response_type: FormResponseTypeEnum;
  tags?: Maybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
  themes?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type FormQuestionInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  form_uuid: Scalars['String'];
  is_locked?: InputMaybe<Scalars['Boolean']>;
  is_required?: InputMaybe<Scalars['Boolean']>;
  label: Scalars['String'];
  options?: InputMaybe<Array<QuestionOptionInput>>;
  order: Scalars['Int'];
  question_uuid: Scalars['String'];
  response_type: FormResponseTypeEnum;
  tenant_uuid: Scalars['String'];
  themes?: InputMaybe<Array<Scalars['String']>>;
};

export type FormQuestionInsights = {
  __typename?: 'FormQuestionInsights';
  qualitative_response_analytics?: Maybe<Scalars['String']>;
  quantitative_response_analytics?: Maybe<Array<FormQuantitativeOptionDetails>>;
  question_uuid: Scalars['String'];
  response_type: FormResponseValueEnum;
};

export enum FormReminderFrequencyEnum {
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  MULTIPLE_PER_WEEK = 'MULTIPLE_PER_WEEK',
  WEEKLY = 'WEEKLY'
}

export type FormResponse = {
  __typename?: 'FormResponse';
  archived?: Maybe<Scalars['Boolean']>;
  batch_uuid: Scalars['String'];
  created_at: Scalars['DateTime'];
  form_uuid: Scalars['String'];
  qualitative_response?: Maybe<Scalars['String']>;
  quantitative_response?: Maybe<Array<QuestionOption>>;
  question_uuid: Scalars['String'];
  response_status: FormResponseStatusEnum;
  response_type: FormResponseValueEnum;
  response_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  updated_at: Scalars['DateTime'];
  user_uuid: Scalars['String'];
};

export type FormResponseData = {
  __typename?: 'FormResponseData';
  qualitative_response?: Maybe<Scalars['String']>;
  quantitative_response?: Maybe<Array<QuestionOption>>;
  question_uuid: Scalars['String'];
  response_type: FormResponseValueEnum;
  response_uuid: Scalars['String'];
};

export type FormResponseDetails = {
  __typename?: 'FormResponseDetails';
  batch_name: Scalars['String'];
  batch_uuid: Scalars['String'];
  form_uuid: Scalars['String'];
  is_anonymous: Scalars['Boolean'];
  response_data: Array<FormResponseData>;
  updated_at: Scalars['DateTime'];
  user_uuid: Scalars['String'];
};

export enum FormResponseStatusEnum {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING'
}

export enum FormResponseTypeEnum {
  LONG_TEXT = 'LONG_TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  SCALE = 'SCALE',
  SHORT_TEXT = 'SHORT_TEXT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  YES_NO = 'YES_NO'
}

export enum FormResponseValueEnum {
  QUALITATIVE = 'QUALITATIVE',
  QUANTITATIVE = 'QUANTITATIVE'
}

export type FormSettingsInput = {
  allow_partial_completion: Scalars['Boolean'];
  is_active: Scalars['Boolean'];
  is_anonymous: Scalars['Boolean'];
  is_single_response: Scalars['Boolean'];
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  access: Access;
  archived?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  questions: Array<QuestionReference>;
  template_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
  version: Scalars['Int'];
};

export type FormToComplete = {
  __typename?: 'FormToComplete';
  batch_uuid: Scalars['String'];
  creator_name: Scalars['String'];
  creator_uuid: Scalars['String'];
  end_date: Scalars['DateTime'];
  form_name: Scalars['String'];
  form_uuid: Scalars['String'];
  opened: Scalars['Boolean'];
  questionResponses: Array<FormResponse>;
};

export enum FormTypeEnum {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export type GetEmployeeInteractionInput = {
  duration: DurationFilterEnum;
  location_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};

export type GetOfficeUtilizationInput = {
  duration: DurationFilterEnum;
  location_uuids: Array<Scalars['String']>;
  tenant_uuid: Scalars['String'];
};

export type GetPastFourWeeksInput = {
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type GetPastFourWeeksOutput = {
  __typename?: 'GetPastFourWeeksOutput';
  eventCount: Scalars['Int'];
  peopleMetAtEvents: Scalars['Int'];
  peopleMetAtOffice: Scalars['Int'];
  teamsAtOffice: Scalars['Int'];
};

export type GetReportInput = {
  location_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};

export type GetReportOutput = {
  __typename?: 'GetReportOutput';
  capacity_utilization: Array<CapacityUtilization>;
  most_used_status: Scalars['String'];
  office_utilization: Scalars['Float'];
  status_rate: StatusRate;
  unique_users: Scalars['Int'];
};

export type GetReportingOutput = {
  __typename?: 'GetReportingOutput';
  report: Array<ReportingDataCluster>;
};

export type GetStatusesInput = {
  checked_in?: InputMaybe<Scalars['Boolean']>;
  end_date: Scalars['DateTime'];
  location_uuid: Scalars['String'];
  start_date: Scalars['DateTime'];
  status: Array<StatusEnum>;
  teams?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
  user_uuid?: InputMaybe<Scalars['String']>;
};

export type GetTeamInsightOutput = {
  __typename?: 'GetTeamInsightOutput';
  event_engagement: Scalars['Float'];
  event_recommendation: EventCatalog;
  in_office_frequency: InOfficeFrequency;
  status_rate: StatusRate;
  top_five_interests: Array<Scalars['String']>;
};

export type GetTeamOfficePresenceInput = {
  location_uuid: Scalars['String'];
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};

export type GetTeamOutput = {
  __typename?: 'GetTeamOutput';
  insights: GetTeamInsightOutput;
  label: Scalars['String'];
  leads: Array<User>;
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  users: Array<User>;
};

export type GetTeamUsersOutput = {
  __typename?: 'GetTeamUsersOutput';
  label: Scalars['String'];
  leads: Array<User>;
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  users: Array<User>;
};

export type GetTenantUsersFilterInput = {
  is_followed_by_me?: InputMaybe<Scalars['Boolean']>;
  locations?: InputMaybe<Array<Scalars['String']>>;
  role_labels?: InputMaybe<Array<RoleEnum>>;
  search?: InputMaybe<Scalars['String']>;
  team_uuids?: InputMaybe<Array<Scalars['String']>>;
};

export type GetTenantUsersInput = {
  filters?: InputMaybe<GetTenantUsersFilterInput>;
  is_signed_up: Scalars['Boolean'];
  page?: InputMaybe<Scalars['Int']>;
  tenant_uuid: Scalars['String'];
};

export type GetWorkspaceInsightsInput = {
  date: Scalars['String'];
  tenant_uuid: Scalars['String'];
};

export type GetWorkspaceInsightsOutput = {
  __typename?: 'GetWorkspaceInsightsOutput';
  admin_hours: Scalars['Int'];
  engagement_score: Scalars['Int'];
  num_creators: Scalars['Int'];
  num_events: Scalars['Int'];
  num_guests: Scalars['Int'];
  trend_events: Scalars['Float'];
  trend_hours: Scalars['Float'];
  trend_score: Scalars['Float'];
};

export enum GoalEnum {
  LEARN_ABOUT_TEAMS = 'LEARN_ABOUT_TEAMS',
  MAKE_FRIENDS = 'MAKE_FRIENDS',
  MENTORSHIP = 'MENTORSHIP',
  OTHER = 'OTHER',
  PRODUCTIVE = 'PRODUCTIVE',
  WORK_LIFE_BALANCE = 'WORK_LIFE_BALANCE'
}

export type GoogleCalEvent = {
  add_conference: Scalars['Boolean'];
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTime'];
  guests?: InputMaybe<Array<Scalars['String']>>;
  start: Scalars['DateTime'];
  summary: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type GraphDbConnectionData = {
  __typename?: 'GraphDBConnectionData';
  connection_strength: Scalars['Int'];
  personal_message_count: Scalars['Int'];
  work_message_count: Scalars['Int'];
};

export type HoursOperation = {
  __typename?: 'HoursOperation';
  fri?: Maybe<Scalars['String']>;
  mon?: Maybe<Scalars['String']>;
  sat?: Maybe<Scalars['String']>;
  sun?: Maybe<Scalars['String']>;
  thu?: Maybe<Scalars['String']>;
  tue?: Maybe<Scalars['String']>;
  wed?: Maybe<Scalars['String']>;
};

export type HoursOperationInput = {
  fri?: InputMaybe<Scalars['String']>;
  mon?: InputMaybe<Scalars['String']>;
  sat?: InputMaybe<Scalars['String']>;
  sun?: InputMaybe<Scalars['String']>;
  thu?: InputMaybe<Scalars['String']>;
  tue?: InputMaybe<Scalars['String']>;
  wed?: InputMaybe<Scalars['String']>;
};

export type InOfficeFrequency = {
  __typename?: 'InOfficeFrequency';
  FRI: Scalars['Float'];
  MON: Scalars['Float'];
  SAT: Scalars['Float'];
  SUN: Scalars['Float'];
  THU: Scalars['Float'];
  TUE: Scalars['Float'];
  WED: Scalars['Float'];
};

export type Job = {
  __typename?: 'Job';
  job_title: Scalars['String'];
  job_uuid: Scalars['String'];
  non_technical_skills: Array<Skill>;
  occupation: Scalars['String'];
  technical_skills: Array<Skill>;
  top_skills: Array<Skill>;
};

export type KeyValuePair = {
  __typename?: 'KeyValuePair';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValuePairInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type LabelObj = {
  __typename?: 'LabelObj';
  label: Scalars['String'];
  num: Scalars['Int'];
};

export type Locations = {
  __typename?: 'Locations';
  address: Scalars['String'];
  address_notes?: Maybe<Scalars['String']>;
  amenities: Array<Scalars['String']>;
  archive?: Maybe<Scalars['Boolean']>;
  capacity_limit: Scalars['Float'];
  community?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['DateTime']>;
  hosts?: Maybe<Array<Scalars['String']>>;
  hours_operation?: Maybe<HoursOperation>;
  image?: Maybe<Scalars['String']>;
  location_uuid: Scalars['String'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  slack_channel?: Maybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  wifi_information?: Maybe<WifiInformation>;
};

export type LocationsOutput = {
  __typename?: 'LocationsOutput';
  address?: Maybe<Scalars['String']>;
  address_notes?: Maybe<Scalars['String']>;
  amenities: Array<Scalars['String']>;
  archive?: Maybe<Scalars['Boolean']>;
  capacity_limit?: Maybe<Scalars['Float']>;
  community?: Maybe<Array<User>>;
  hosts?: Maybe<Array<User>>;
  hours_operation?: Maybe<HoursOperation>;
  image?: Maybe<Scalars['String']>;
  location_uuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  slack_channel?: Maybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  wifi_information?: Maybe<WifiInformation>;
};

export type MeetingDetails = {
  __typename?: 'MeetingDetails';
  calendar?: Maybe<CalendarEventInfo>;
  label: Scalars['String'];
  link?: Maybe<Scalars['String']>;
};

export type MessagePart = {
  __typename?: 'MessagePart';
  text: Scalars['String'];
  text_weight: TextWeightEnum;
};

export type Mutation = {
  __typename?: 'Mutation';
  addPremiumUserSubscription: StripePortal;
  addTeamUser: User;
  addTeamUsers: Array<User>;
  archiveForm: Scalars['Boolean'];
  archiveFormBatch: Scalars['Boolean'];
  archiveFormQuestion: Scalars['Boolean'];
  archiveFormQuestionInBank: Scalars['Boolean'];
  archiveFormResponse: Scalars['Boolean'];
  archiveFormTemplate: Scalars['Boolean'];
  buildForm: Scalars['String'];
  cancelChat: Scalars['Boolean'];
  cancelPremiumSubscription: StripePortal;
  confirmRoomBooking: RoomBookingDetailedOutput;
  createAgentConversation: AiConversations;
  createCheckoutSession: StripePortal;
  createCommunity: CommunityDetailedOutput;
  createEvent: EventDetailedOutput;
  createFeature: Feature;
  createFormBatch: FormBatch;
  createFormQuestion: FormQuestion;
  createFormQuestionBank: FormQuestionBank;
  createFormResponse: FormResponse;
  createFormTemplate: FormQuestion;
  createGoogleCalAuthSession: Scalars['String'];
  createGoogleCalEvent: Scalars['String'];
  createLocation: Locations;
  createMessages: Array<WorkflowMessages>;
  createOneOnOnes: Scalars['Boolean'];
  createProfileAndLinkCognitoRecord: Scalars['Boolean'];
  createRoom: RoomDetailedOutput;
  createRoomBooking: RoomBookingDetailedOutput;
  createTeam: Team;
  createTenant: Tenant;
  createTimeBlock: TimeBlock;
  createWatercoolerTopic: WatercoolerTopic;
  createWorkflow: WorkflowDetailedOutput;
  deactivateUser: TenantUserOutput;
  deleteAllFormBatchesInTenant: Scalars['Boolean'];
  deleteAllFormQuestionsInForm: Scalars['Boolean'];
  deleteAllFormQuestionsInTenant: Scalars['Boolean'];
  deleteAllFormResponsesInBatch: Scalars['Boolean'];
  deleteAllFormResponsesInTenant: Scalars['Boolean'];
  deleteAllFormTemplates: Scalars['Boolean'];
  deleteAllFormsInTenant: Scalars['Boolean'];
  deleteAllTenantFormQuestionsInBank: Scalars['Boolean'];
  deleteCommunity: Scalars['String'];
  deleteEvent: Scalars['String'];
  deleteForm: Scalars['Boolean'];
  deleteFormBatch: Scalars['Boolean'];
  deleteFormQuestion: Scalars['Boolean'];
  deleteFormQuestionInBank: Scalars['Boolean'];
  deleteFormResponse: Scalars['Boolean'];
  deleteFormTemplate: Scalars['Boolean'];
  deleteLocation: Scalars['String'];
  deleteMessageFromQueue: Scalars['String'];
  deleteMultipleMessages: Scalars['String'];
  deleteRoom: Scalars['String'];
  deleteRoomBooking: Scalars['String'];
  deleteTimeBlock: Scalars['String'];
  deleteWorkflow: Scalars['String'];
  directSignUp: Scalars['Boolean'];
  disconnectGoogleCalUserAuth: Scalars['Boolean'];
  disconnectSlackWorkspace: Scalars['Boolean'];
  followUser: Array<Scalars['String']>;
  generateAIMessage: Array<AiGeneratedOutput>;
  generateLLMFormQuestions: Array<FormQuestion>;
  inviteUsers: Scalars['Boolean'];
  joinCommunity: CommunityDetailedOutput;
  leaveCommunity: CommunityDetailedOutput;
  readAllNotifications: Scalars['Boolean'];
  removeGoogleCalEvent: Scalars['String'];
  removeInvite: Scalars['Boolean'];
  removeOneOnOneSetting: ChatSettings;
  removeRoomBookingUsers: RoomBookingDetailedOutput;
  removeTeam: Scalars['String'];
  removeTenant: Tenant;
  removeUser: UserOutput;
  removeUserTeam: User;
  renewPendingCancelledSubscription: Scalars['Boolean'];
  rescheduleChat: Scalars['Boolean'];
  sendAgentMessage: AiMessages;
  storeGoogleCalAuthTokens: Scalars['Boolean'];
  storeSlackUserInsights: Scalars['Boolean'];
  submitFormResponse: Array<FormResponse>;
  unfollowUser: Array<Scalars['String']>;
  updateChatSettings: ChatSettings;
  updateCommunity: CommunityDetailedOutput;
  updateEvent: EventDetailedOutput;
  updateForm: Form;
  updateFormBatch: FormBatch;
  updateFormBatchUserOpenedStatus: FormBatch;
  updateFormQuestion: FormQuestion;
  updateFormQuestionBank: FormQuestion;
  updateFormResponse: FormResponse;
  updateFormTemplate: FormQuestion;
  updateGlobalLocation: Scalars['String'];
  updateGoogleCalAuthTokens: Scalars['Boolean'];
  updateGoogleCalEvent: Scalars['String'];
  updateGoogleCalendarColor: Scalars['Boolean'];
  updateLocation: Locations;
  updateMessageInQueue: WorkflowMessages;
  updateNotificationSettings: NotificationSettings;
  updateOneOnOneSettings: ChatSettings;
  updatePaymentMethod: StripePortal;
  updateRoom: RoomDetailedOutput;
  updateRoomBooking: RoomBookingDetailedOutput;
  updateTeam: Team;
  updateTenant: Tenant;
  updateTenantPlan: StripeCancellationDetails;
  updateTimeBlock: TimeBlock;
  updateUser: UserOutput;
  updateUserBehaviour: Scalars['String'];
  updateWorkflow: WorkflowDetailedOutput;
  uploadFile: Scalars['String'];
  upsertAiBrandVoice: AiBrandVoice;
  upsertEventVote: EventDetailedOutput;
  upsertRSVPStatus: EventDetailedOutput;
  upsertRecommendationLog: Scalars['Boolean'];
  upsertSlackWorkflows?: Maybe<SlackWorkflows>;
  upsertStatus: Scalars['String'];
};


export type MutationAddPremiumUserSubscriptionArgs = {
  billing_frequency: BillingFrequencyEnum;
  tenant_uuid: Scalars['String'];
};


export type MutationAddTeamUserArgs = {
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationAddTeamUsersArgs = {
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuids: Array<Scalars['String']>;
};


export type MutationArchiveFormArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationArchiveFormBatchArgs = {
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationArchiveFormQuestionArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationArchiveFormQuestionInBankArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationArchiveFormResponseArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationArchiveFormTemplateArgs = {
  template_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationBuildFormArgs = {
  buildFormInput: BuildFormInput;
};


export type MutationCancelChatArgs = {
  chat_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationCancelPremiumSubscriptionArgs = {
  return_url: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationConfirmRoomBookingArgs = {
  end_time: Scalars['DateTime'];
  final_date: Scalars['DateTime'];
  room_booking_uuid: Scalars['String'];
  start_time: Scalars['DateTime'];
};


export type MutationCreateAgentConversationArgs = {
  agent_uuid: Scalars['String'];
  message: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationCreateCheckoutSessionArgs = {
  billing_frequency: BillingFrequencyEnum;
  return_url: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationCreateCommunityArgs = {
  createCommunityInput: CreateCommunityInput;
};


export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};


export type MutationCreateFeatureArgs = {
  createFeaturesInput: CreateFeaturesInput;
};


export type MutationCreateFormBatchArgs = {
  createFormBatchInput: CreateFormBatchInput;
};


export type MutationCreateFormQuestionArgs = {
  createFormQuestionInput: CreateFormQuestionInput;
};


export type MutationCreateFormQuestionBankArgs = {
  createFormQuestionBankInput: CreateFormQuestionBankInput;
};


export type MutationCreateFormResponseArgs = {
  createFormResponseInput: CreateFormResponseInput;
};


export type MutationCreateFormTemplateArgs = {
  createFormTemplateInput: CreateFormTemplateInput;
};


export type MutationCreateGoogleCalEventArgs = {
  event: GoogleCalEvent;
};


export type MutationCreateLocationArgs = {
  createLocationsInput: CreateLocationsInput;
};


export type MutationCreateMessagesArgs = {
  AddMessageInput: Array<AddMessageInput>;
  tenant_uuid: Scalars['String'];
  workflow_uuid: Scalars['String'];
};


export type MutationCreateOneOnOnesArgs = {
  oneOnOneInput: OneOnOneInput;
};


export type MutationCreateProfileAndLinkCognitoRecordArgs = {
  email: Scalars['String'];
  full_name: Scalars['String'];
};


export type MutationCreateRoomArgs = {
  CreateRoomInput: CreateRoomInput;
};


export type MutationCreateRoomBookingArgs = {
  CreateRoomBookingInput: CreateRoomBookingInput;
};


export type MutationCreateTeamArgs = {
  createTeamInput: CreateTeamInput;
};


export type MutationCreateTenantArgs = {
  createTenantInput: CreateTenantInput;
};


export type MutationCreateTimeBlockArgs = {
  createTimeBlockInput: CreateTimeBlockInput;
};


export type MutationCreateWatercoolerTopicArgs = {
  createWatercoolerInput: CreateWatercoolerTopicInput;
};


export type MutationCreateWorkflowArgs = {
  CreateWorkflowInput: CreateWorkflowInput;
};


export type MutationDeactivateUserArgs = {
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationDeleteAllFormBatchesInTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteAllFormQuestionsInFormArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteAllFormQuestionsInTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteAllFormResponsesInBatchArgs = {
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteAllFormResponsesInTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteAllFormTemplatesArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteAllFormsInTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteAllTenantFormQuestionsInBankArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteCommunityArgs = {
  community_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteEventArgs = {
  event_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteFormArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteFormBatchArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteFormQuestionArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteFormQuestionInBankArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteFormResponseArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteFormTemplateArgs = {
  template_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationDeleteLocationArgs = {
  location_uuid: Scalars['String'];
};


export type MutationDeleteMessageFromQueueArgs = {
  message_uuid: Scalars['String'];
};


export type MutationDeleteMultipleMessagesArgs = {
  message_uuid: Array<Scalars['String']>;
};


export type MutationDeleteRoomArgs = {
  room_uuid: Scalars['String'];
};


export type MutationDeleteRoomBookingArgs = {
  room_booking_uuid: Scalars['String'];
};


export type MutationDeleteTimeBlockArgs = {
  time_block_uuid: Scalars['String'];
};


export type MutationDeleteWorkflowArgs = {
  workflow_uuid: Scalars['String'];
};


export type MutationDirectSignUpArgs = {
  email: Scalars['String'];
  full_name: Scalars['String'];
  password: Scalars['String'];
  timezone: Scalars['String'];
};


export type MutationDisconnectSlackWorkspaceArgs = {
  tenant_id: Scalars['String'];
};


export type MutationFollowUserArgs = {
  user_uuid: Scalars['String'];
};


export type MutationGenerateAiMessageArgs = {
  count: Scalars['Float'];
  generateGIFs: Scalars['Boolean'];
  prompt: Scalars['String'];
};


export type MutationGenerateLlmFormQuestionsArgs = {
  prompt: Scalars['String'];
};


export type MutationInviteUsersArgs = {
  emails: Array<Scalars['String']>;
  office_uuid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
};


export type MutationJoinCommunityArgs = {
  community_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationLeaveCommunityArgs = {
  community_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationReadAllNotificationsArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationRemoveGoogleCalEventArgs = {
  google_event_id: Scalars['String'];
};


export type MutationRemoveInviteArgs = {
  email: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationRemoveOneOnOneSettingArgs = {
  chat_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationRemoveRoomBookingUsersArgs = {
  room_booking_uuid: Scalars['String'];
  user_uuids: Array<Scalars['String']>;
};


export type MutationRemoveTeamArgs = {
  team_uuid: Scalars['String'];
};


export type MutationRemoveTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  user_uuid: Scalars['Int'];
};


export type MutationRemoveUserTeamArgs = {
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationRenewPendingCancelledSubscriptionArgs = {
  return_url: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationRescheduleChatArgs = {
  chat_uuid: Scalars['String'];
  meeting_time: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationSendAgentMessageArgs = {
  agent_uuid: Scalars['String'];
  conversation_uuid: Scalars['String'];
  message: Scalars['String'];
  sender: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationStoreGoogleCalAuthTokensArgs = {
  authorization_code: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationStoreSlackUserInsightsArgs = {
  tenant_id: Scalars['String'];
};


export type MutationSubmitFormResponseArgs = {
  submitFormResponseInput: SubmitFormResponseInput;
};


export type MutationUnfollowUserArgs = {
  user_uuid: Scalars['String'];
};


export type MutationUpdateChatSettingsArgs = {
  chat_type: Scalars['String'];
  frequency: Scalars['String'];
  is_active: Scalars['Boolean'];
  tenant_uuid: Scalars['String'];
};


export type MutationUpdateCommunityArgs = {
  updateCommunityInput: UpdateCommunityInput;
};


export type MutationUpdateEventArgs = {
  is_finalized?: InputMaybe<Scalars['Boolean']>;
  updateEventInput: UpdateEventInput;
};


export type MutationUpdateFormArgs = {
  updateFormsInput: UpdateFormInput;
};


export type MutationUpdateFormBatchArgs = {
  updateFormBatchInput: UpdateFormBatchInput;
};


export type MutationUpdateFormBatchUserOpenedStatusArgs = {
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type MutationUpdateFormQuestionArgs = {
  updateFormQuestionInput: UpdateFormQuestionInput;
};


export type MutationUpdateFormQuestionBankArgs = {
  updateFormQuestionBankInput: UpdateFormQuestionBankInput;
};


export type MutationUpdateFormResponseArgs = {
  updateFormResponseInput: UpdateFormResponseInput;
};


export type MutationUpdateFormTemplateArgs = {
  updateFormTemplateInput: UpdateFormTemplateInput;
};


export type MutationUpdateGlobalLocationArgs = {
  location_uuid: Scalars['String'];
};


export type MutationUpdateGoogleCalEventArgs = {
  event: UpdateGoogleCalEvent;
};


export type MutationUpdateGoogleCalendarColorArgs = {
  color: Scalars['String'];
};


export type MutationUpdateLocationArgs = {
  updateLocationsInput: UpdateLocationsInput;
};


export type MutationUpdateMessageInQueueArgs = {
  UpdateMessageInput: UpdateMessageInput;
};


export type MutationUpdateNotificationSettingsArgs = {
  email_active: Scalars['Boolean'];
  email_coffee_chat_notification: Scalars['Boolean'];
  email_event_notification: Scalars['Boolean'];
  email_one_on_one_notification: Scalars['Boolean'];
  slack_active: Scalars['Boolean'];
  slack_coffee_chat_notification: Scalars['Boolean'];
  slack_event_notification: Scalars['Boolean'];
  slack_one_on_one_notification: Scalars['Boolean'];
  tenant_uuid: Scalars['String'];
};


export type MutationUpdateOneOnOneSettingsArgs = {
  oneOnOneSettings: UpdateOneOnOneSettingsInput;
  tenant_uuid: Scalars['String'];
};


export type MutationUpdatePaymentMethodArgs = {
  return_url: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationUpdateRoomArgs = {
  updateRoom: UpdateRoomInput;
};


export type MutationUpdateRoomBookingArgs = {
  updateRoomBooking: UpdateRoomBookingInput;
};


export type MutationUpdateTeamArgs = {
  updateTeamInput: UpdateTeamInput;
};


export type MutationUpdateTenantArgs = {
  updateTenantInput: UpdateTenantInput;
};


export type MutationUpdateTenantPlanArgs = {
  tenant_uuid: Scalars['String'];
};


export type MutationUpdateTimeBlockArgs = {
  updateTimeBlockInput: UpdateTimeBlockInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUserBehaviourArgs = {
  updateUserBehaviourInput: UpdateUserBehaviourInput;
};


export type MutationUpdateWorkflowArgs = {
  UpdateWorkflowInput: UpdateWorkflowInput;
};


export type MutationUploadFileArgs = {
  uploadFileInput: UploadFileInput;
};


export type MutationUpsertAiBrandVoiceArgs = {
  content: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type MutationUpsertEventVoteArgs = {
  event_uuid: Scalars['String'];
  no_times_work: Scalars['Boolean'];
  poll_options: Array<Scalars['DateTime']>;
  tenant_uuid: Scalars['String'];
};


export type MutationUpsertRsvpStatusArgs = {
  event_uuid: Scalars['String'];
  rsvp: EventRsvpEnum;
  tenant_uuid: Scalars['String'];
};


export type MutationUpsertRecommendationLogArgs = {
  RecommendationLogInput: RecommendationLogInput;
};


export type MutationUpsertSlackWorkflowsArgs = {
  watercoolerChatsInput: SlackWorkflowsInput;
};


export type MutationUpsertStatusArgs = {
  upsertStatusInput: Array<UpsertStatusInput>;
};

export type NotificationOutput = {
  __typename?: 'NotificationOutput';
  created_at?: Maybe<Scalars['DateTime']>;
  is_read: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  message_parts: Array<MessagePart>;
  notification_type: NotificationTypeEnum;
  notification_uuid: Scalars['String'];
  sender_image?: Maybe<Scalars['String']>;
  sender_name: Scalars['String'];
  sender_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  email_active: Scalars['Boolean'];
  email_coffee_chat_notification: Scalars['Boolean'];
  email_event_notification: Scalars['Boolean'];
  email_one_on_one_notification: Scalars['Boolean'];
  slack_active: Scalars['Boolean'];
  slack_coffee_chat_notification: Scalars['Boolean'];
  slack_event_notification: Scalars['Boolean'];
  slack_one_on_one_notification: Scalars['Boolean'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type NotificationType = {
  __typename?: 'NotificationType';
  tenant_uuid: Scalars['String'];
  unread_notifications: Scalars['Boolean'];
  user_uuid: Scalars['String'];
};

export enum NotificationTypeEnum {
  ANNIVERSARY = 'ANNIVERSARY',
  BIRTHDAY = 'BIRTHDAY',
  COFFEE_CHAT_CANCELED = 'COFFEE_CHAT_CANCELED',
  COFFEE_CHAT_RESCHEDULED = 'COFFEE_CHAT_RESCHEDULED',
  EVENT_INVITE = 'EVENT_INVITE',
  EVENT_POLL = 'EVENT_POLL',
  EVENT_SCHEDULED = 'EVENT_SCHEDULED',
  EVENT_UPDATED = 'EVENT_UPDATED',
  FORM_REMINDER = 'FORM_REMINDER',
  HYBRID_SCHEDULE = 'HYBRID_SCHEDULE',
  KUDO = 'KUDO',
  NEW_COFFEE_CHAT_WITHOUT_TIME = 'NEW_COFFEE_CHAT_WITHOUT_TIME',
  NEW_COFFEE_CHAT_WITH_TIME = 'NEW_COFFEE_CHAT_WITH_TIME',
  NEW_ONE_ON_ONE_WITHOUT_TIME = 'NEW_ONE_ON_ONE_WITHOUT_TIME',
  NEW_ONE_ON_ONE_WITH_TIME = 'NEW_ONE_ON_ONE_WITH_TIME',
  ONE_ON_ONE_CANCELED = 'ONE_ON_ONE_CANCELED',
  ONE_ON_ONE_RESCHEDULED = 'ONE_ON_ONE_RESCHEDULED',
  POLL_UPDATED = 'POLL_UPDATED'
}

export type OfficeRecommendationsInput = {
  end_date: Scalars['DateTime'];
  start_date: Scalars['DateTime'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type OfficeRecommendationsOutput = {
  __typename?: 'OfficeRecommendationsOutput';
  date: Scalars['String'];
  ranking: Scalars['Float'];
  reasons: Array<Scalars['String']>;
};

export type OneOnOneInput = {
  duration: Scalars['Float'];
  frequency?: InputMaybe<ChatFrequencyEnum>;
  matched_user_uuids: Array<Scalars['String']>;
  meeting_location?: InputMaybe<ChatLocationEnum>;
  meeting_name: Scalars['String'];
  override_focus_time?: InputMaybe<Scalars['Boolean']>;
  tenant_uuid: Scalars['String'];
  type?: InputMaybe<ChatTypeEnum>;
};

export type OneOnOneSettings = {
  __typename?: 'OneOnOneSettings';
  chat_uuid: Scalars['String'];
  chat_with: Array<ChatRsvp>;
  created_at?: Maybe<Scalars['DateTime']>;
  duration: Scalars['Int'];
  frequency: ChatFrequencyEnum;
  is_active: Scalars['Boolean'];
  location: ChatLocationEnum;
  title: Scalars['String'];
};

export type OrgAnalytics = {
  __typename?: 'OrgAnalytics';
  available_hours: Scalars['Float'];
  blocked_hours: Scalars['Float'];
  chat_engagement: Scalars['Float'];
  created_at: Scalars['DateTime'];
  engagement_score: Scalars['Float'];
  event_count: Scalars['Float'];
  event_engagement: Scalars['Float'];
  focused_hours: Scalars['Float'];
  hours_saved: Scalars['Float'];
  office_engagement: Scalars['Float'];
  slack_community_data: Array<CommunityData>;
  slack_engagement: Scalars['Float'];
  tenant_uuid: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  billing_frequency?: Maybe<BillingFrequencyEnum>;
  currency?: Maybe<CurrencyEnum>;
  discount: Scalars['Float'];
  plan: WorkspacePlanEnum;
  premium_user_count?: Maybe<Scalars['Float']>;
  stripe_id: Scalars['String'];
};

export type PaymentInput = {
  currency?: InputMaybe<CurrencyEnum>;
  discount?: InputMaybe<Scalars['Float']>;
  plan?: InputMaybe<WorkspacePlanEnum>;
  stripe_id?: InputMaybe<Scalars['String']>;
};

export enum Platform {
  SLACK = 'SLACK',
  TEAMS = 'TEAMS'
}

export type PollOption = {
  __typename?: 'PollOption';
  date: Scalars['DateTime'];
  votes?: Maybe<Array<Scalars['String']>>;
};

export type PollOptionDetails = {
  __typename?: 'PollOptionDetails';
  date: Scalars['DateTime'];
  votes?: Maybe<Array<EventUserDetails>>;
};

export type PollOptionInput = {
  date: Scalars['DateTime'];
  votes?: InputMaybe<Array<Scalars['String']>>;
};

export type PreBuiltFormQuestionBank = {
  __typename?: 'PreBuiltFormQuestionBank';
  archived?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  options?: Maybe<Array<QuestionOption>>;
  question_uuid: Scalars['String'];
  response_type: FormResponseTypeEnum;
  tags?: Maybe<Array<Scalars['String']>>;
  themes?: Maybe<Array<Scalars['String']>>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type PreBuiltFormTemplate = {
  __typename?: 'PreBuiltFormTemplate';
  archived?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  questions: Array<QuestionReference>;
  template_uuid: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
  version: Scalars['Int'];
};

export enum PreferredDayEnum {
  FRI = 'FRI',
  MON = 'MON',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED'
}

export type ProposedTimes = {
  __typename?: 'ProposedTimes';
  time: Scalars['DateTime'];
  user_uuid: Scalars['String'];
};

export type QualitativeResponse = {
  qualitative_response: Scalars['String'];
  response_uuid: Scalars['String'];
};

export type QuantitativeResponse = {
  quantitative_response: Array<QuestionOptionInput>;
  response_uuid: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkScheduleRecommendationAccepted: Scalars['Boolean'];
  classifyText: Scalars['String'];
  createDummyNotification: Scalars['Boolean'];
  generateGIFs: Array<Scalars['String']>;
  generateText: Scalars['String'];
  getAccountSetup: Array<AccountSetupOutput>;
  getAgentConversationMessages: Array<AiMessages>;
  getAgentConversations: Array<AiConversations>;
  getAiBrandVoice: AiBrandVoice;
  getAllCommunities: Array<CommunityDetailedOutput>;
  getAllEventCatalog: EventCatalogOutput;
  getAllEvents: AllEventsOutput;
  getAllFormQuestionsInForm: Array<FormQuestion>;
  getAllFormQuestionsInTenant: Array<FormQuestion>;
  getAllFormResponsesInBatch: Array<FormResponse>;
  getAllFormResponsesInForm: Array<FormResponse>;
  getAllFormResponsesInTenant: Array<FormResponse>;
  getAllFormTemplates: Array<FormTemplate>;
  getAllMessages: Array<WorkflowMessages>;
  getAllPreBuiltFormQuestionsInBank: Array<PreBuiltFormQuestionBank>;
  getAllTeams: Array<GetTeamUsersOutput>;
  getAllTenantFormQuestionsInBank: Array<FormTemplate>;
  getAllWorkflows: Array<WorkflowDetailedOutput>;
  getBirthdays: Array<UserOutput>;
  getChatSettings: ChatSettings;
  getChats: Array<ChatDetails>;
  getCoffeeChatAvailability: Array<Scalars['String']>;
  getCommunity: CommunityDetailedOutput;
  getEmployeeInteraction: GetReportingOutput;
  getEvent: EventDetailedOutput;
  getEventDateRecommendations: Array<EventDateRecommendationOutput>;
  getEventTemplate: EventCatalog;
  getFeatures: Array<Feature>;
  getFollowingUsers: Array<Scalars['String']>;
  getForm: Form;
  getFormBatch: FormBatch;
  getFormBatchesDetails: Array<FormBatchDetails>;
  getFormBatchesInForm: Array<FormBatch>;
  getFormBatchesInTenant: Array<FormBatch>;
  getFormQuestion: FormQuestion;
  getFormQuestionInBank: FormTemplate;
  getFormQuestionInsights: Array<FormQuestionInsights>;
  getFormResponse: FormResponse;
  getFormResponsesDetailsInForm: Array<FormResponseDetails>;
  getFormResponsesToSubmit: Array<FormResponse>;
  getFormTemplate: FormTemplate;
  getForms: Array<Form>;
  getFormsDetails: Array<FormDetails>;
  getFormsToComplete: Array<FormToComplete>;
  getInvoiceItems: Array<StripeInvoice>;
  getJob: Job;
  getJobs: Array<Job>;
  getLocation: LocationsOutput;
  getMostFrequentDay: Scalars['String'];
  getNotifications: Array<NotificationOutput>;
  getNotificationsSettings: NotificationSettings;
  getOfficeRecommendations: Array<OfficeRecommendationsOutput>;
  getOfficeUtilization: GetReportingOutput;
  getOrgAnalytics: Array<OrgAnalytics>;
  getPastFourWeeks: GetPastFourWeeksOutput;
  getPreBuiltFormQuestionInBank: PreBuiltFormQuestionBank;
  getPreBuiltFormQuestionsInBank: Array<PreBuiltFormQuestionBank>;
  getPreBuiltFormTemplate: PreBuiltFormTemplate;
  getPreBuiltFormTemplates: Array<PreBuiltFormTemplate>;
  getReport: GetReportOutput;
  getRoomBooking: RoomBookingDetailedOutput;
  getSlackChannels: Array<SlackChannel>;
  getSlackWorkflows?: Maybe<SlackWorkflows>;
  getStatuses: Array<StatusDetails>;
  getTeam: GetTeamOutput;
  getTeamOfficePresence: GetReportingOutput;
  getTeamUsers: GetTeamUsersOutput;
  getTenant: Tenant;
  getTenantUserCount: Scalars['Float'];
  getTenantUsers: TenantUserOutput;
  getTimeBlocks: Array<TimeBlock>;
  getUser: UserOutput;
  getUserAnalytics: Array<UserAnalytics>;
  getUserSelectedLocation: Scalars['String'];
  getUserSteps: UserStepsOutput;
  getWaterCoolerTopics: Array<WaterCoolerTopicOutput>;
  getWorkflow: WorkflowDetailedOutput;
  getWorkspaceInsights: GetWorkspaceInsightsOutput;
  listAllRoomBookings: RoomBookingListOutput;
  listAllRooms: RoomListOutput;
  listGoogleCalEvents: Scalars['Boolean'];
  listLocations: Array<LocationsOutput>;
};


export type QueryCheckScheduleRecommendationAcceptedArgs = {
  RecommendationLogInput: RecommendationLogInput;
};


export type QueryClassifyTextArgs = {
  prompt: Scalars['String'];
};


export type QueryGenerateGiFsArgs = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search_term: Scalars['String'];
};


export type QueryGenerateTextArgs = {
  prompt: Scalars['String'];
};


export type QueryGetAccountSetupArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAgentConversationMessagesArgs = {
  agent_uuid: Scalars['String'];
  conversation_uuid: Scalars['String'];
  page: Scalars['Float'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetAgentConversationsArgs = {
  agent_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetAiBrandVoiceArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllCommunitiesArgs = {
  search?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllEventCatalogArgs = {
  eventCatalogInput: EventCatalogInput;
};


export type QueryGetAllEventsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllFormQuestionsInFormArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllFormQuestionsInTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllFormResponsesInBatchArgs = {
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllFormResponsesInFormArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllFormResponsesInTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllFormTemplatesArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllMessagesArgs = {
  workflow_uuid: Scalars['String'];
};


export type QueryGetAllTeamsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllTenantFormQuestionsInBankArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetAllWorkflowsArgs = {
  search?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
};


export type QueryGetBirthdaysArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetChatSettingsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetChatsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetCoffeeChatAvailabilityArgs = {
  chat_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetCommunityArgs = {
  community_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetEmployeeInteractionArgs = {
  getEmployeeInteractionInput: GetEmployeeInteractionInput;
};


export type QueryGetEventArgs = {
  event_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetEventDateRecommendationsArgs = {
  end_date: Scalars['DateTime'];
  guests: Array<Scalars['String']>;
  location_uuid?: InputMaybe<Scalars['String']>;
  start_date: Scalars['DateTime'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetEventTemplateArgs = {
  event_uuid: Scalars['String'];
};


export type QueryGetFeaturesArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormArgs = {
  edit: Scalars['Boolean'];
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormBatchArgs = {
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormBatchesDetailsArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormBatchesInFormArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormBatchesInTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormQuestionArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormQuestionInBankArgs = {
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormQuestionInsightsArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormResponseArgs = {
  response_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormResponsesDetailsInFormArgs = {
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormResponsesToSubmitArgs = {
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type QueryGetFormTemplateArgs = {
  template_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormsDetailsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetFormsToCompleteArgs = {
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type QueryGetInvoiceItemsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetJobArgs = {
  job_title: Scalars['String'];
};


export type QueryGetJobsArgs = {
  search_keyword?: InputMaybe<Scalars['String']>;
};


export type QueryGetLocationArgs = {
  location_uuid: Scalars['String'];
};


export type QueryGetMostFrequentDayArgs = {
  updateUserBehaviourInput: UpdateUserBehaviourInput;
};


export type QueryGetNotificationsArgs = {
  limit: Scalars['Float'];
  page: Scalars['Float'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetNotificationsSettingsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetOfficeRecommendationsArgs = {
  officeRecommendationsInput: OfficeRecommendationsInput;
};


export type QueryGetOfficeUtilizationArgs = {
  getOfficeUtilizationInput: GetOfficeUtilizationInput;
};


export type QueryGetOrgAnalyticsArgs = {
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetPastFourWeeksArgs = {
  getReportInput: GetPastFourWeeksInput;
};


export type QueryGetPreBuiltFormQuestionInBankArgs = {
  question_uuid: Scalars['String'];
};


export type QueryGetPreBuiltFormQuestionsInBankArgs = {
  question_uuids: Array<Scalars['String']>;
};


export type QueryGetPreBuiltFormTemplateArgs = {
  template_uuid: Scalars['String'];
};


export type QueryGetReportArgs = {
  getReportInput: GetReportInput;
};


export type QueryGetRoomBookingArgs = {
  room_booking_uuid: Scalars['String'];
};


export type QueryGetSlackChannelsArgs = {
  tenant_id: Scalars['String'];
};


export type QueryGetSlackWorkflowsArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetStatusesArgs = {
  getStatusesInput: GetStatusesInput;
};


export type QueryGetTeamArgs = {
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetTeamOfficePresenceArgs = {
  getTeamOfficePresenceInput: GetTeamOfficePresenceInput;
};


export type QueryGetTeamUsersArgs = {
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryGetTenantArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetTenantUserCountArgs = {
  tenant_uuid: Scalars['String'];
};


export type QueryGetTenantUsersArgs = {
  getTenantUsersInput: GetTenantUsersInput;
};


export type QueryGetTimeBlocksArgs = {
  end_date: Scalars['DateTime'];
  start_date: Scalars['DateTime'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type QueryGetUserArgs = {
  user_uuid: Scalars['String'];
};


export type QueryGetUserAnalyticsArgs = {
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};


export type QueryGetUserSelectedLocationArgs = {
  user_uuid: Scalars['String'];
};


export type QueryGetUserStepsArgs = {
  user_uuid: Scalars['String'];
};


export type QueryGetWorkflowArgs = {
  tenant_uuid: Scalars['String'];
  workflow_uuid: Scalars['String'];
};


export type QueryGetWorkspaceInsightsArgs = {
  getWorkspaceInsightsInput: GetWorkspaceInsightsInput;
};


export type QueryListAllRoomBookingsArgs = {
  location_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryListAllRoomsArgs = {
  location_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};


export type QueryListGoogleCalEventsArgs = {
  end_time: Scalars['DateTime'];
  start_time: Scalars['DateTime'];
};


export type QueryListLocationsArgs = {
  tenant_uuid: Scalars['String'];
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  label: Scalars['String'];
  option_uuid: Scalars['String'];
};

export type QuestionOptionInput = {
  label: Scalars['String'];
  option_uuid: Scalars['String'];
};

export type QuestionOptionsInput = {
  label: Scalars['String'];
  option_uuid?: InputMaybe<Scalars['String']>;
};

export type QuestionReference = {
  __typename?: 'QuestionReference';
  is_locked: Scalars['Boolean'];
  order: Scalars['Int'];
  question_uuid: Scalars['String'];
};

export type QuestionReferenceInput = {
  is_locked: Scalars['Boolean'];
  order: Scalars['Int'];
  question_uuid: Scalars['String'];
};

export type RecommendationLogInput = {
  location_uuid: Scalars['String'];
  recommendation_accepted?: InputMaybe<Scalars['Boolean']>;
  recommended_week: Scalars['Int'];
  recommended_year: Scalars['Int'];
  tenant_uuid: Scalars['String'];
};

export enum RepeatFrequencyEnum {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
  WEEKDAYS = 'WEEKDAYS',
  WEEKLY = 'WEEKLY',
  YEARLY = 'YEARLY'
}

export type ReportingData = {
  __typename?: 'ReportingData';
  x: Scalars['String'];
  y: Scalars['Float'];
};

export type ReportingDataCluster = {
  __typename?: 'ReportingDataCluster';
  data: Array<ReportingData>;
  id: Scalars['String'];
};

export type ResourceInfo = {
  __typename?: 'ResourceInfo';
  resource_id: Scalars['String'];
  type: ResourceTypeEnum;
};

export type ResourceInfoInput = {
  resource_id: Scalars['String'];
  type?: InputMaybe<ResourceTypeEnum>;
};

export enum ResourceTypeEnum {
  CHAT = 'CHAT',
  EVENT = 'EVENT'
}

export enum RoleEnum {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER'
}

export type RoomBookingDetailedOutput = {
  __typename?: 'RoomBookingDetailedOutput';
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['DateTime']>;
  final_date?: Maybe<Scalars['DateTime']>;
  guests: Array<RoomUser>;
  location_uuid: Scalars['String'];
  room_booking_name?: Maybe<Scalars['String']>;
  room_booking_uuid: Scalars['String'];
  room_uuid: Scalars['String'];
  start_time?: Maybe<Scalars['DateTime']>;
  tenant_uuid: Scalars['String'];
};

export type RoomBookingDetails = {
  __typename?: 'RoomBookingDetails';
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['DateTime']>;
  final_date?: Maybe<Scalars['DateTime']>;
  guests: Array<RoomUser>;
  room_booking_name: Scalars['String'];
  room_booking_uuid: Scalars['String'];
  start_time?: Maybe<Scalars['DateTime']>;
};

export type RoomBookingListOutput = {
  __typename?: 'RoomBookingListOutput';
  booked_rooms?: Maybe<Array<RoomDetails>>;
};

export type RoomDetailedOutput = {
  __typename?: 'RoomDetailedOutput';
  capacity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  location_uuid: Scalars['String'];
  room_name: Scalars['String'];
  room_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
};

export type RoomDetails = {
  __typename?: 'RoomDetails';
  capacity?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  room_name?: Maybe<Scalars['String']>;
  room_uuid: Scalars['String'];
  scheduled?: Maybe<Array<RoomBookingDetails>>;
};

export type RoomListOutput = {
  __typename?: 'RoomListOutput';
  booked_rooms?: Maybe<Array<RoomDetailedOutput>>;
};

export type RoomUser = {
  __typename?: 'RoomUser';
  image?: Maybe<Scalars['String']>;
  preferred_name: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type RoomUserInput = {
  image?: InputMaybe<Scalars['String']>;
  preferred_name: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type Skill = {
  __typename?: 'Skill';
  skill_label: Scalars['String'];
  skill_uuid: Scalars['String'];
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SlackInstallUser = {
  __typename?: 'SlackInstallUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SlackTeam = {
  __typename?: 'SlackTeam';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SlackWorkflows = {
  __typename?: 'SlackWorkflows';
  tenant_uuid: Scalars['String'];
  watercooler_chats: WatercoolerChats;
};

export type SlackWorkflowsInput = {
  tenant_uuid: Scalars['String'];
  watercooler_chats?: InputMaybe<WatercoolerChatsInput>;
};

export type StatusDetails = {
  __typename?: 'StatusDetails';
  checked_in?: Maybe<Scalars['Boolean']>;
  date: Scalars['String'];
  duration: DurationEnum;
  preferred_name?: Maybe<Scalars['String']>;
  status: StatusEnum;
  status_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export enum StatusEnum {
  NOT_WORKING = 'NOT_WORKING',
  NO_STATUS = 'NO_STATUS',
  OFFICE = 'OFFICE',
  REMOTE = 'REMOTE'
}

export type StatusRate = {
  __typename?: 'StatusRate';
  NOT_WORKING: Scalars['Float'];
  OFFICE: Scalars['Float'];
  REMOTE: Scalars['Float'];
};

export type StripeCancellationDetails = {
  __typename?: 'StripeCancellationDetails';
  cancel_plan_date?: Maybe<Scalars['DateTime']>;
  cancel_request_date?: Maybe<Scalars['DateTime']>;
};

export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  amount: Scalars['String'];
  date: Scalars['String'];
  description: Scalars['String'];
  invoice_id: Scalars['String'];
  link: Scalars['String'];
};

export type StripePortal = {
  __typename?: 'StripePortal';
  portal_id: Scalars['String'];
  url: Scalars['String'];
};

export type SubmitFormResponseInput = {
  batch_uuid: Scalars['String'];
  is_draft?: InputMaybe<Scalars['Boolean']>;
  qualitative?: InputMaybe<Array<QualitativeResponse>>;
  quantitative?: InputMaybe<Array<QuantitativeResponse>>;
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  has_notifications: NotificationType;
};


export type SubscriptionHas_NotificationsArgs = {
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type Team = {
  __typename?: 'Team';
  label: Scalars['String'];
  leads: Array<Scalars['String']>;
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  users: Array<Scalars['String']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  company_type: CompanyTypeEnum;
  created_at?: Maybe<Scalars['DateTime']>;
  email_signature: Scalars['String'];
  logo?: Maybe<Scalars['String']>;
  payment: Payment;
  pending_deletion: Scalars['Boolean'];
  preferred_office_day_count: Scalars['Float'];
  schedule_weekends: Scalars['Boolean'];
  tenant_uuid: Scalars['String'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  users: Array<Scalars['String']>;
};

export type TenantUserOutput = {
  __typename?: 'TenantUserOutput';
  current_page?: Maybe<Scalars['Int']>;
  total_pages?: Maybe<Scalars['Int']>;
  total_users?: Maybe<Scalars['Int']>;
  users: Array<UserOutput>;
};

export enum TextWeightEnum {
  BOLD = 'BOLD',
  NORMAL = 'NORMAL'
}

export type TimeBlock = {
  __typename?: 'TimeBlock';
  calendar: TimeBlockCalendarTypeEnum;
  calendar_id?: Maybe<Scalars['String']>;
  calendar_info?: Maybe<CalendarEventInfo>;
  created_at?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  end_date?: Maybe<Scalars['DateTime']>;
  google_cal_event_id?: Maybe<Scalars['String']>;
  omitted_dates?: Maybe<Array<Scalars['DateTime']>>;
  repeat: RepeatFrequencyEnum;
  resource?: Maybe<ResourceInfo>;
  start: Scalars['DateTime'];
  tenant_uuid: Scalars['String'];
  time_block_uuid: Scalars['String'];
  type: TimeBlockTypeEnum;
  user_uuid: Scalars['String'];
};

export enum TimeBlockCalendarTypeEnum {
  GOOGLE = 'GOOGLE',
  LITESPACE = 'LITESPACE',
  OUTLOOK = 'OUTLOOK'
}

export enum TimeBlockTypeEnum {
  AVAILABLE = 'AVAILABLE',
  AWAY = 'AWAY',
  BLOCKED = 'BLOCKED',
  FOCUSED = 'FOCUSED'
}

export type TopicValueOutput = {
  __typename?: 'TopicValueOutput';
  gif?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type UpdateCommunityInput = {
  communication_channel?: InputMaybe<Scalars['String']>;
  communication_guidelines?: InputMaybe<Scalars['String']>;
  community_guidelines?: InputMaybe<Scalars['String']>;
  community_uuid: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  hosts?: InputMaybe<Array<Scalars['String']>>;
  members?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
};

export type UpdateEventInput = {
  category?: InputMaybe<EventCategoryEnum>;
  checklist?: InputMaybe<Scalars['String']>;
  communities?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  event_uuid: Scalars['String'];
  faq?: InputMaybe<Scalars['String']>;
  final_date?: InputMaybe<Scalars['DateTime']>;
  guests?: InputMaybe<Array<EventGuestInput>>;
  image?: InputMaybe<Scalars['String']>;
  is_poll?: InputMaybe<Scalars['Boolean']>;
  location_uuid?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizers?: InputMaybe<Array<EventGuestInput>>;
  poll_deadline?: InputMaybe<Scalars['DateTime']>;
  poll_options?: InputMaybe<Array<PollOptionInput>>;
  requirements?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
};

export type UpdateFormBatchInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  batch_uuid: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<FormBatchSettingsInput>;
  tags?: InputMaybe<Array<KeyValuePairInput>>;
  tenant_uuid: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateFormInput = {
  access?: InputMaybe<AccessInput>;
  archived?: InputMaybe<Scalars['Boolean']>;
  batches?: InputMaybe<Array<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  form_uuid: Scalars['String'];
  settings?: InputMaybe<FormSettingsInput>;
  status?: InputMaybe<FormTypeEnum>;
  template_uuid?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateFormQuestionBankInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  is_archive?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  question_uuid: Scalars['String'];
  response_type?: InputMaybe<FormResponseTypeEnum>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
  themes?: InputMaybe<Array<Scalars['String']>>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateFormQuestionInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  form_uuid?: InputMaybe<Scalars['String']>;
  is_required?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<QuestionOptionsInput>>;
  order?: InputMaybe<Scalars['Int']>;
  question_uuid: Scalars['String'];
  response_type?: InputMaybe<FormResponseTypeEnum>;
  tenant_uuid: Scalars['String'];
  themes?: InputMaybe<Array<Scalars['String']>>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateFormResponseInput = {
  archived?: InputMaybe<Scalars['Boolean']>;
  batch_uuid?: InputMaybe<Scalars['String']>;
  form_uuid?: InputMaybe<Scalars['String']>;
  qualitative_response?: InputMaybe<Scalars['String']>;
  quantitative_response?: InputMaybe<Array<QuestionOptionInput>>;
  question_uuid?: InputMaybe<Scalars['String']>;
  response_status?: InputMaybe<FormResponseStatusEnum>;
  response_type?: InputMaybe<FormResponseValueEnum>;
  response_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  updated_at?: InputMaybe<Scalars['DateTime']>;
  user_uuid: Scalars['String'];
};

export type UpdateFormTemplateInput = {
  access?: InputMaybe<AccessInput>;
  archived?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuestionReferenceInput>>;
  settings?: InputMaybe<FormSettingsInput>;
  template_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateGoogleCalEvent = {
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  google_event_id?: InputMaybe<Scalars['String']>;
  guests?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  summary?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
};

export type UpdateLocationsInput = {
  address?: InputMaybe<Scalars['String']>;
  address_notes?: InputMaybe<Scalars['String']>;
  amenities?: InputMaybe<Array<Scalars['String']>>;
  archive?: InputMaybe<Scalars['Boolean']>;
  capacity_limit?: InputMaybe<Scalars['Int']>;
  community?: InputMaybe<Array<Scalars['String']>>;
  hosts?: InputMaybe<Array<Scalars['String']>>;
  hours_operation?: InputMaybe<HoursOperationInput>;
  image?: InputMaybe<Scalars['String']>;
  location_uuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  slack_channel?: InputMaybe<Scalars['String']>;
  tenant_uuid?: InputMaybe<Scalars['String']>;
  wifi_information?: InputMaybe<WifiInformationInput>;
};

export type UpdateMessageInput = {
  gif?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  message_uuid: Scalars['String'];
  source?: InputMaybe<WorkflowSettingsSource>;
  tenant_uuid: Scalars['String'];
};

export type UpdateOneOnOneSettingsInput = {
  chat_uuid: Scalars['String'];
  chat_with?: InputMaybe<Array<ChatRsvpInput>>;
  duration?: InputMaybe<Scalars['Float']>;
  frequency?: InputMaybe<ChatFrequencyEnum>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<ChatLocationEnum>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateRoomBookingInput = {
  description?: InputMaybe<Scalars['String']>;
  end_time?: InputMaybe<Scalars['DateTime']>;
  final_date?: InputMaybe<Scalars['DateTime']>;
  guests?: InputMaybe<Array<RoomUserInput>>;
  location_uuid: Scalars['String'];
  room_booking_name?: InputMaybe<Scalars['String']>;
  room_booking_uuid: Scalars['String'];
  start_time?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateRoomInput = {
  capacity?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<Scalars['String']>>;
  location_uuid: Scalars['String'];
  room_name: Scalars['String'];
  room_uuid: Scalars['String'];
};

export type UpdateTeamInput = {
  label?: InputMaybe<Scalars['String']>;
  leads?: InputMaybe<Array<Scalars['String']>>;
  team_uuid: Scalars['String'];
  tenant_uuid?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateTenantInput = {
  email_signature?: InputMaybe<Scalars['String']>;
  payment?: InputMaybe<PaymentInput>;
  pending_deletion?: InputMaybe<Scalars['Boolean']>;
  preferred_office_day_count?: InputMaybe<Scalars['Float']>;
  schedule_weekends?: InputMaybe<Scalars['Boolean']>;
  tenant_uuid: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateTimeBlockInput = {
  calendar?: InputMaybe<TimeBlockCalendarTypeEnum>;
  calendar_info?: InputMaybe<CalendarEventInfoInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  end_date?: InputMaybe<Scalars['DateTime']>;
  google_cal_event_id?: InputMaybe<Scalars['String']>;
  omitted_dates?: InputMaybe<Array<Scalars['DateTime']>>;
  repeat?: InputMaybe<RepeatFrequencyEnum>;
  resource?: InputMaybe<ResourceInfoInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  tenant_uuid: Scalars['String'];
  time_block_uuid: Scalars['String'];
  type?: InputMaybe<TimeBlockTypeEnum>;
};

export type UpdateUserBehaviourInput = {
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UpdateUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<GoalEnum>>;
  image?: InputMaybe<Scalars['String']>;
  interests?: InputMaybe<Array<Scalars['String']>>;
  is_onboarded?: InputMaybe<Scalars['Boolean']>;
  job_title?: InputMaybe<Scalars['String']>;
  onboarding_step?: InputMaybe<Scalars['Int']>;
  phone_number?: InputMaybe<Scalars['String']>;
  preferred_days?: InputMaybe<Array<PreferredDayEnum>>;
  preferred_name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  selected_location_id?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Array<Scalars['String']>>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  teams?: InputMaybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
  timezone?: InputMaybe<Scalars['String']>;
  user_uuid: Scalars['String'];
};

export type UpdateWorkflowInput = {
  channel_id?: InputMaybe<Scalars['String']>;
  channel_name?: InputMaybe<Scalars['String']>;
  days?: InputMaybe<Array<WorkflowSettingsTriggerDayEnum>>;
  frequency?: InputMaybe<WorkflowSettingsFrequencyEnum>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  send_message_as?: InputMaybe<Scalars['String']>;
  send_time?: InputMaybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  time_zone?: InputMaybe<Scalars['String']>;
  workflow_title?: InputMaybe<Scalars['String']>;
  workflow_uuid: Scalars['String'];
};

export type UploadFileInput = {
  base64: Scalars['String'];
  location_uuid?: InputMaybe<Scalars['String']>;
  mimetype: Scalars['String'];
  tenant_uuid: Scalars['String'];
  upload_type: UploadTypeEnum;
};

export enum UploadTypeEnum {
  BANNER = 'BANNER',
  COMPANY_LOGO = 'COMPANY_LOGO',
  LOCATION = 'LOCATION',
  PROFILE_PIC = 'PROFILE_PIC'
}

export type UpsertStatusInput = {
  checked_in?: InputMaybe<Scalars['Boolean']>;
  date: Scalars['DateTime'];
  duration?: InputMaybe<DurationEnum>;
  location_uuid: Scalars['String'];
  status: StatusEnum;
  tenant_uuid: Scalars['String'];
  was_recommended?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  archived: Scalars['Boolean'];
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  following?: Maybe<Array<Scalars['String']>>;
  full_name?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<GoalEnum>>;
  image?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Scalars['String']>>;
  invited_by?: Maybe<Scalars['String']>;
  isFollowedByMe: Scalars['Boolean'];
  is_email_confirmed: Scalars['Boolean'];
  is_onboarded?: Maybe<Scalars['Boolean']>;
  is_signed_up?: Maybe<Scalars['Boolean']>;
  job_title?: Maybe<Scalars['String']>;
  onboarding_step?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  preferred_days?: Maybe<Array<PreferredDayEnum>>;
  preferred_name?: Maybe<Scalars['String']>;
  role: RoleEnum;
  selected_location_id?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['String']>>;
  start_date?: Maybe<Scalars['DateTime']>;
  teams?: Maybe<Array<Scalars['String']>>;
  tenant_uuid: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_uuid: Scalars['String'];
  was_invited?: Maybe<Scalars['Boolean']>;
};

export type UserAnalytics = {
  __typename?: 'UserAnalytics';
  connections: Scalars['Float'];
  created_at: Scalars['DateTime'];
  meeting_hours: Array<WeeklyMeetingData>;
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
};

export type UserOutput = {
  __typename?: 'UserOutput';
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  following?: Maybe<Array<Scalars['String']>>;
  full_name?: Maybe<Scalars['String']>;
  goals?: Maybe<Array<GoalEnum>>;
  image?: Maybe<Scalars['String']>;
  interests?: Maybe<Array<Scalars['String']>>;
  isConnectedSlackUser: Scalars['Boolean'];
  isConnectedWithGoogleCal: Scalars['Boolean'];
  isConnectedWithSlack: Scalars['Boolean'];
  isFollowedByMe: Scalars['Boolean'];
  is_onboarded?: Maybe<Scalars['Boolean']>;
  is_signed_up?: Maybe<Scalars['Boolean']>;
  job_title?: Maybe<Scalars['String']>;
  onboarding_step?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  preferred_days?: Maybe<Array<PreferredDayEnum>>;
  preferred_name?: Maybe<Scalars['String']>;
  role: RoleEnum;
  selected_location_id?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Scalars['String']>>;
  start_date?: Maybe<Scalars['DateTime']>;
  teams?: Maybe<Array<Team>>;
  tenant_uuid: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_uuid: Scalars['String'];
  was_invited?: Maybe<Scalars['Boolean']>;
};

export type UserStepsOutput = {
  __typename?: 'UserStepsOutput';
  create_teams: Scalars['Boolean'];
  filled_profile: Scalars['Boolean'];
  follow_people: Scalars['Boolean'];
  google_calendar: Scalars['Boolean'];
  invite_members: Scalars['Boolean'];
  is_admin: Scalars['Boolean'];
  slack_integration: Scalars['Boolean'];
};

export type WaterCoolerTopicOutput = {
  __typename?: 'WaterCoolerTopicOutput';
  category: Scalars['String'];
  values: Array<TopicValueOutput>;
};

export type WatercoolerChats = {
  __typename?: 'WatercoolerChats';
  channel_id: Scalars['String'];
  channel_name: Scalars['String'];
  days: Array<WorkflowTriggerDayEnum>;
  frequency: WorkflowFrequencyEnum;
  is_active: Scalars['Boolean'];
};

export type WatercoolerChatsInput = {
  channel_id: Scalars['String'];
  channel_name: Scalars['String'];
  days: Array<WorkflowTriggerDayEnum>;
  frequency: WorkflowFrequencyEnum;
  is_active?: InputMaybe<Scalars['Boolean']>;
};

export type WatercoolerTopic = {
  __typename?: 'WatercoolerTopic';
  category: Scalars['String'];
  image_link: Scalars['String'];
  message: Scalars['String'];
  topic_uuid: Scalars['String'];
};

export type WeeklyMeetingData = {
  __typename?: 'WeeklyMeetingData';
  day_number: Scalars['Float'];
  hours: Scalars['Float'];
};

export type WifiInformation = {
  __typename?: 'WifiInformation';
  certificates?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  network_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  security_type?: Maybe<Scalars['String']>;
};

export type WifiInformationInput = {
  certificates?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  network_name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  security_type?: InputMaybe<Scalars['String']>;
};

export type WorkflowDetailedOutput = {
  __typename?: 'WorkflowDetailedOutput';
  channel_id: Scalars['String'];
  channel_name: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  days: Array<WorkflowSettingsTriggerDayEnum>;
  frequency: WorkflowSettingsFrequencyEnum;
  is_active?: Maybe<Scalars['Boolean']>;
  platform: Platform;
  send_message_as: Scalars['String'];
  send_time?: Maybe<Scalars['String']>;
  tenant_uuid: Scalars['String'];
  time_zone: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  workflow_access?: Maybe<Scalars['String']>;
  workflow_owner: Scalars['String'];
  workflow_title: Scalars['String'];
  workflow_type: WorkflowSettingsType;
  workflow_uuid: Scalars['String'];
};

export enum WorkflowFrequencyEnum {
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export type WorkflowMessages = {
  __typename?: 'WorkflowMessages';
  category?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  gif?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  message_uuid: Scalars['String'];
  sent?: Maybe<Scalars['Boolean']>;
  source: WorkflowSettingsSource;
  tenant_uuid: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  workflow_uuid: Scalars['String'];
};

export enum WorkflowSettingsFrequencyEnum {
  MULTIPLE_PER_WEEK = 'MULTIPLE_PER_WEEK',
  ONCE_EVERY_FOUR_WEEKS = 'ONCE_EVERY_FOUR_WEEKS',
  ONCE_EVERY_TWO_WEEKS = 'ONCE_EVERY_TWO_WEEKS',
  ONCE_PER_WEEK = 'ONCE_PER_WEEK'
}

export enum WorkflowSettingsSource {
  AI_GENERATED = 'AI_GENERATED',
  CUSTOM = 'CUSTOM',
  LITESPACE = 'LITESPACE'
}

export enum WorkflowSettingsTriggerDayEnum {
  FRI = 'FRI',
  MON = 'MON',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED'
}

export enum WorkflowSettingsType {
  WATER_COOLER = 'WATER_COOLER'
}

export enum WorkflowTriggerDayEnum {
  FRI = 'FRI',
  MON = 'MON',
  THU = 'THU',
  TUE = 'TUE',
  WED = 'WED'
}

export enum WorkspacePlanEnum {
  FREE = 'FREE',
  PREMIUM = 'PREMIUM'
}

export type AddTeamUserMutationVariables = Exact<{
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type AddTeamUserMutation = (
  { __typename?: 'Mutation' }
  & { addTeamUser: (
    { __typename?: 'User' }
    & Pick<User, 'email' | 'preferred_name' | 'teams' | 'user_uuid'>
  ) }
);

export type ArchiveFormMutationVariables = Exact<{
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type ArchiveFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveForm'>
);

export type ArchiveFormBatchMutationVariables = Exact<{
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type ArchiveFormBatchMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'archiveFormBatch'>
);

export type BuildFormMutationVariables = Exact<{
  buildFormInput: BuildFormInput;
}>;


export type BuildFormMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'buildForm'>
);

export type CancelChatMutationVariables = Exact<{
  chatUuid: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type CancelChatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelChat'>
);

export type CancelPremiumSubscriptionMutationVariables = Exact<{
  returnUrl: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type CancelPremiumSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelPremiumSubscription: (
    { __typename?: 'StripePortal' }
    & Pick<StripePortal, 'portal_id' | 'url'>
  ) }
);

export type CreateAgentConversationMutationVariables = Exact<{
  agent_uuid: Scalars['String'];
  message: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type CreateAgentConversationMutation = (
  { __typename?: 'Mutation' }
  & { createAgentConversation: (
    { __typename?: 'AiConversations' }
    & Pick<AiConversations, 'agent_uuid' | 'conversation_uuid' | 'tenant_uuid' | 'title' | 'users'>
  ) }
);

export type CreateCheckoutSessionMutationVariables = Exact<{
  tenantUuid: Scalars['String'];
  billingFrequency: BillingFrequencyEnum;
  returnUrl: Scalars['String'];
}>;


export type CreateCheckoutSessionMutation = (
  { __typename?: 'Mutation' }
  & { createCheckoutSession: (
    { __typename?: 'StripePortal' }
    & Pick<StripePortal, 'portal_id' | 'url'>
  ) }
);

export type CreateCommunityMutationVariables = Exact<{
  createCommunityInput: CreateCommunityInput;
}>;


export type CreateCommunityMutation = (
  { __typename?: 'Mutation' }
  & { createCommunity: (
    { __typename?: 'CommunityDetailedOutput' }
    & Pick<CommunityDetailedOutput, 'communication_channel' | 'communication_guidelines' | 'community_guidelines' | 'community_uuid' | 'description' | 'image' | 'is_public' | 'name' | 'notes' | 'tags' | 'tenant_uuid'>
    & { hosts?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, members?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>> }
  ) }
);

export type CreateEventMutationVariables = Exact<{
  createEventInput: CreateEventInput;
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'EventDetailedOutput' }
    & Pick<EventDetailedOutput, 'category' | 'checklist' | 'communities' | 'description' | 'event_uuid' | 'faq' | 'final_date' | 'image' | 'is_poll' | 'location_uuid' | 'name' | 'poll_deadline' | 'requirements' | 'resources' | 'status' | 'teams' | 'tenant_uuid'>
    & { guests: Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, no_times_work?: Maybe<Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>>, organizers: Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, poll_options?: Maybe<Array<(
      { __typename?: 'PollOptionDetails' }
      & Pick<PollOptionDetails, 'date'>
      & { votes?: Maybe<Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'image'>
      )>> }
    )>> }
  ) }
);

export type CreateFormBatchMutationVariables = Exact<{
  createFormBatchInput: CreateFormBatchInput;
}>;


export type CreateFormBatchMutation = (
  { __typename?: 'Mutation' }
  & { createFormBatch: (
    { __typename?: 'FormBatch' }
    & Pick<FormBatch, 'archived' | 'batch_uuid' | 'created_at' | 'description' | 'form_uuid' | 'tenant_uuid' | 'title' | 'updated_at'>
    & { settings: (
      { __typename?: 'FormBatchSettings' }
      & Pick<FormBatchSettings, 'end_date' | 'is_single_response' | 'start_date'>
      & { distribution_list: Array<(
        { __typename?: 'FormBatchUserStatus' }
        & Pick<FormBatchUserStatus, 'opened' | 'response_status' | 'user_uuid'>
      )>, reminder?: Maybe<(
        { __typename?: 'FormBatchReminder' }
        & Pick<FormBatchReminder, 'days' | 'frequency' | 'time' | 'timezone'>
      )> }
    ), tags?: Maybe<Array<(
      { __typename?: 'KeyValuePair' }
      & Pick<KeyValuePair, 'key' | 'value'>
    )>> }
  ) }
);

export type CreateGoogleCalAuthSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateGoogleCalAuthSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGoogleCalAuthSession'>
);

export type CreateGoogleCalEventMutationVariables = Exact<{
  event: GoogleCalEvent;
}>;


export type CreateGoogleCalEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGoogleCalEvent'>
);

export type CreateLocationMutationVariables = Exact<{
  createLocationsInput: CreateLocationsInput;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'Locations' }
    & Pick<Locations, 'location_uuid'>
  ) }
);

export type CreateMessagesMutationVariables = Exact<{
  addMessageInput: Array<AddMessageInput> | AddMessageInput;
  tenant_uuid: Scalars['String'];
  workflow_uuid: Scalars['String'];
}>;


export type CreateMessagesMutation = (
  { __typename?: 'Mutation' }
  & { createMessages: Array<(
    { __typename?: 'WorkflowMessages' }
    & Pick<WorkflowMessages, 'category' | 'created_at' | 'created_by' | 'gif' | 'message' | 'message_uuid' | 'sent' | 'source' | 'tenant_uuid' | 'updated_at' | 'workflow_uuid'>
  )> }
);

export type CreateOneOnOnesMutationVariables = Exact<{
  oneOnOneInput: OneOnOneInput;
}>;


export type CreateOneOnOnesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOneOnOnes'>
);

export type CreateRoomMutationVariables = Exact<{
  createRoomInput: CreateRoomInput;
}>;


export type CreateRoomMutation = (
  { __typename?: 'Mutation' }
  & { createRoom: (
    { __typename?: 'RoomDetailedOutput' }
    & Pick<RoomDetailedOutput, 'capacity' | 'description' | 'images' | 'location_uuid' | 'room_name' | 'room_uuid' | 'tenant_uuid'>
  ) }
);

export type CreateRoomBookingMutationVariables = Exact<{
  createRoomBookingInput: CreateRoomBookingInput;
}>;


export type CreateRoomBookingMutation = (
  { __typename?: 'Mutation' }
  & { createRoomBooking: (
    { __typename?: 'RoomBookingDetailedOutput' }
    & Pick<RoomBookingDetailedOutput, 'created_at' | 'description' | 'end_time' | 'final_date' | 'location_uuid' | 'room_booking_name' | 'room_booking_uuid' | 'room_uuid' | 'start_time' | 'tenant_uuid'>
    & { guests: Array<(
      { __typename?: 'RoomUser' }
      & Pick<RoomUser, 'image' | 'preferred_name' | 'user_uuid'>
    )> }
  ) }
);

export type CreateTeamMutationVariables = Exact<{
  createTeamInput: CreateTeamInput;
}>;


export type CreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam: (
    { __typename?: 'Team' }
    & Pick<Team, 'label' | 'team_uuid' | 'tenant_uuid'>
  ) }
);

export type CreateTenantMutationVariables = Exact<{
  createTenantInput: CreateTenantInput;
}>;


export type CreateTenantMutation = (
  { __typename?: 'Mutation' }
  & { createTenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'tenant_uuid' | 'title'>
  ) }
);

export type CreateTimeBlockMutationVariables = Exact<{
  createTimeBlockInput: CreateTimeBlockInput;
}>;


export type CreateTimeBlockMutation = (
  { __typename?: 'Mutation' }
  & { createTimeBlock: (
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'calendar' | 'end' | 'repeat' | 'start' | 'tenant_uuid' | 'user_uuid' | 'type' | 'time_block_uuid'>
  ) }
);

export type CreateWorkflowMutationVariables = Exact<{
  createWorkflowInput: CreateWorkflowInput;
}>;


export type CreateWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { createWorkflow: (
    { __typename?: 'WorkflowDetailedOutput' }
    & Pick<WorkflowDetailedOutput, 'channel_id' | 'channel_name' | 'created_at' | 'days' | 'frequency' | 'is_active' | 'platform' | 'send_message_as' | 'send_time' | 'tenant_uuid' | 'time_zone' | 'updated_at' | 'workflow_access' | 'workflow_title' | 'workflow_owner' | 'workflow_uuid'>
  ) }
);

export type DeactivateUserMutationVariables = Exact<{
  user_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type DeactivateUserMutation = (
  { __typename?: 'Mutation' }
  & { deactivateUser: (
    { __typename?: 'TenantUserOutput' }
    & { users: Array<(
      { __typename?: 'UserOutput' }
      & Pick<UserOutput, 'user_uuid' | 'tenant_uuid' | 'email' | 'role'>
    )> }
  ) }
);

export type DeleteCommunityMutationVariables = Exact<{
  community_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type DeleteCommunityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCommunity'>
);

export type DeleteEventMutationVariables = Exact<{
  eventUuid: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteEvent'>
);

export type DeleteLocationMutationVariables = Exact<{
  location_uuid: Scalars['String'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteLocation'>
);

export type DeleteMultipleMessagesMutationVariables = Exact<{
  message_uuid: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteMultipleMessagesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMultipleMessages'>
);

export type DeleteRoomMutationVariables = Exact<{
  room_uuid: Scalars['String'];
}>;


export type DeleteRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRoom'>
);

export type DeleteRoomBookingMutationVariables = Exact<{
  room_booking_uuid: Scalars['String'];
}>;


export type DeleteRoomBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRoomBooking'>
);

export type DeleteTimeBlockMutationVariables = Exact<{
  timeBlockUuid: Scalars['String'];
}>;


export type DeleteTimeBlockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteTimeBlock'>
);

export type DeleteWorkflowMutationVariables = Exact<{
  workflow_uuid: Scalars['String'];
}>;


export type DeleteWorkflowMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWorkflow'>
);

export type DeleteMessageFromQueueMutationVariables = Exact<{
  messageUuid: Scalars['String'];
}>;


export type DeleteMessageFromQueueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteMessageFromQueue'>
);

export type DisconnectGoogleCalUserAuthMutationVariables = Exact<{ [key: string]: never; }>;


export type DisconnectGoogleCalUserAuthMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disconnectGoogleCalUserAuth'>
);

export type DisconnectSlackWorkspaceMutationVariables = Exact<{
  tenant_id: Scalars['String'];
}>;


export type DisconnectSlackWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'disconnectSlackWorkspace'>
);

export type FollowUserMutationVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type FollowUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'followUser'>
);

export type GenerateAiMessageMutationVariables = Exact<{
  count: Scalars['Float'];
  prompt: Scalars['String'];
  generateGIFs: Scalars['Boolean'];
}>;


export type GenerateAiMessageMutation = (
  { __typename?: 'Mutation' }
  & { generateAIMessage: Array<(
    { __typename?: 'AIGeneratedOutput' }
    & Pick<AiGeneratedOutput, 'category' | 'gif' | 'message' | 'source'>
  )> }
);

export type GenerateLlmFormQuestionsMutationVariables = Exact<{
  prompt: Scalars['String'];
}>;


export type GenerateLlmFormQuestionsMutation = (
  { __typename?: 'Mutation' }
  & { generateLLMFormQuestions: Array<(
    { __typename?: 'FormQuestion' }
    & Pick<FormQuestion, 'archived' | 'created_at' | 'form_uuid' | 'is_locked' | 'is_required' | 'label' | 'order' | 'question_uuid' | 'response_type' | 'tenant_uuid' | 'themes' | 'updated_at'>
    & { options?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type InviteUsersMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
  tenant_uuid: Scalars['String'];
  office_uuid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
}>;


export type InviteUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteUsers'>
);

export type JoinCommunityMutationVariables = Exact<{
  community_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type JoinCommunityMutation = (
  { __typename?: 'Mutation' }
  & { joinCommunity: (
    { __typename?: 'CommunityDetailedOutput' }
    & Pick<CommunityDetailedOutput, 'communication_channel' | 'communication_guidelines' | 'community_guidelines' | 'community_uuid' | 'description' | 'image' | 'is_public' | 'name' | 'notes' | 'tags' | 'tenant_uuid'>
    & { hosts?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, members?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>> }
  ) }
);

export type LeaveCommunityMutationVariables = Exact<{
  community_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type LeaveCommunityMutation = (
  { __typename?: 'Mutation' }
  & { leaveCommunity: (
    { __typename?: 'CommunityDetailedOutput' }
    & Pick<CommunityDetailedOutput, 'communication_channel' | 'communication_guidelines' | 'community_guidelines' | 'community_uuid' | 'description' | 'image' | 'is_public' | 'name' | 'notes' | 'tags' | 'tenant_uuid'>
    & { hosts?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, members?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>> }
  ) }
);

export type ReadAllNotificationsMutationVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type ReadAllNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'readAllNotifications'>
);

export type RemoveGoogleCalEventMutationVariables = Exact<{
  google_event_id: Scalars['String'];
}>;


export type RemoveGoogleCalEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeGoogleCalEvent'>
);

export type RemoveInviteMutationVariables = Exact<{
  email: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type RemoveInviteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeInvite'>
);

export type RemoveOneOnOneSettingMutationVariables = Exact<{
  chatUuid: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type RemoveOneOnOneSettingMutation = (
  { __typename?: 'Mutation' }
  & { removeOneOnOneSetting: (
    { __typename?: 'ChatSettings' }
    & { recurring?: Maybe<Array<(
      { __typename?: 'OneOnOneSettings' }
      & Pick<OneOnOneSettings, 'chat_uuid' | 'created_at' | 'duration' | 'frequency' | 'is_active' | 'location' | 'title'>
      & { chat_with: Array<(
        { __typename?: 'ChatRSVP' }
        & Pick<ChatRsvp, 'rsvp' | 'user_uuid'>
      )> }
    )>> }
  ) }
);

export type RemoveTeamMutationVariables = Exact<{
  team_uuid: Scalars['String'];
}>;


export type RemoveTeamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTeam'>
);

export type RemoveUserTeamMutationVariables = Exact<{
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type RemoveUserTeamMutation = (
  { __typename?: 'Mutation' }
  & { removeUserTeam: (
    { __typename?: 'User' }
    & Pick<User, 'teams' | 'user_uuid'>
  ) }
);

export type RenewPendingCancelledSubscriptionMutationVariables = Exact<{
  returnUrl: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type RenewPendingCancelledSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'renewPendingCancelledSubscription'>
);

export type RescheduleChatMutationVariables = Exact<{
  chatUuid: Scalars['String'];
  meetingTime: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type RescheduleChatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rescheduleChat'>
);

export type SendAgentMessageMutationVariables = Exact<{
  agent_uuid: Scalars['String'];
  conversation_uuid: Scalars['String'];
  message: Scalars['String'];
  sender: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type SendAgentMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendAgentMessage: (
    { __typename?: 'AiMessages' }
    & Pick<AiMessages, 'agent_uuid' | 'content' | 'conversation_uuid' | 'created_at' | 'message_uuid' | 'sender' | 'tenant_uuid' | 'user_uuid'>
  ) }
);

export type DirectSignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  full_name: Scalars['String'];
  timezone: Scalars['String'];
}>;


export type DirectSignUpMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'directSignUp'>
);

export type StoreGoogleCalAuthTokensMutationVariables = Exact<{
  authorizationCode: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type StoreGoogleCalAuthTokensMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'storeGoogleCalAuthTokens'>
);

export type SubmitFormResponseMutationVariables = Exact<{
  submitFormResponseInput: SubmitFormResponseInput;
}>;


export type SubmitFormResponseMutation = (
  { __typename?: 'Mutation' }
  & { submitFormResponse: Array<(
    { __typename?: 'FormResponse' }
    & Pick<FormResponse, 'archived' | 'batch_uuid' | 'created_at' | 'form_uuid' | 'qualitative_response' | 'question_uuid' | 'response_status' | 'response_type' | 'response_uuid' | 'tenant_uuid' | 'updated_at' | 'user_uuid'>
    & { quantitative_response?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type UnfollowUserMutationVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type UnfollowUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unfollowUser'>
);

export type UpdateChatSettingsMutationVariables = Exact<{
  chat_type: Scalars['String'];
  frequency: Scalars['String'];
  is_active: Scalars['Boolean'];
  tenant_uuid: Scalars['String'];
}>;


export type UpdateChatSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateChatSettings: (
    { __typename?: 'ChatSettings' }
    & { discover: (
      { __typename?: 'ChatStreamSettings' }
      & Pick<ChatStreamSettings, 'frequency' | 'is_active'>
    ), stay_connected: (
      { __typename?: 'ChatStreamSettings' }
      & Pick<ChatStreamSettings, 'frequency' | 'is_active'>
    ), mentorship: (
      { __typename?: 'ChatStreamSettings' }
      & Pick<ChatStreamSettings, 'frequency' | 'is_active'>
    ), recurring?: Maybe<Array<(
      { __typename?: 'OneOnOneSettings' }
      & Pick<OneOnOneSettings, 'duration' | 'frequency' | 'is_active' | 'title'>
      & { chat_with: Array<(
        { __typename?: 'ChatRSVP' }
        & Pick<ChatRsvp, 'rsvp' | 'user_uuid'>
      )> }
    )>> }
  ) }
);

export type UpdateCommunityMutationVariables = Exact<{
  updateCommunityInput: UpdateCommunityInput;
}>;


export type UpdateCommunityMutation = (
  { __typename?: 'Mutation' }
  & { updateCommunity: (
    { __typename?: 'CommunityDetailedOutput' }
    & Pick<CommunityDetailedOutput, 'communication_channel' | 'communication_guidelines' | 'community_guidelines' | 'community_uuid' | 'description' | 'image' | 'is_public' | 'name' | 'notes' | 'tags' | 'tenant_uuid'>
    & { hosts?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, members?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>> }
  ) }
);

export type UpdateEventMutationVariables = Exact<{
  updateEventInput: UpdateEventInput;
  is_finalized?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent: (
    { __typename?: 'EventDetailedOutput' }
    & Pick<EventDetailedOutput, 'category' | 'checklist' | 'communities' | 'description' | 'event_uuid' | 'faq' | 'final_date' | 'image' | 'is_poll' | 'location_uuid' | 'name' | 'poll_deadline' | 'requirements' | 'resources' | 'status' | 'teams' | 'tenant_uuid'>
    & { guests: Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, no_times_work?: Maybe<Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>>, organizers: Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, poll_options?: Maybe<Array<(
      { __typename?: 'PollOptionDetails' }
      & Pick<PollOptionDetails, 'date'>
      & { votes?: Maybe<Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'image'>
      )>> }
    )>> }
  ) }
);

export type UpdateFormBatchMutationVariables = Exact<{
  updateFormBatchInput: UpdateFormBatchInput;
}>;


export type UpdateFormBatchMutation = (
  { __typename?: 'Mutation' }
  & { updateFormBatch: (
    { __typename?: 'FormBatch' }
    & Pick<FormBatch, 'archived' | 'batch_uuid' | 'created_at' | 'description' | 'form_uuid' | 'tenant_uuid' | 'title' | 'updated_at'>
    & { settings: (
      { __typename?: 'FormBatchSettings' }
      & Pick<FormBatchSettings, 'end_date' | 'is_single_response' | 'start_date'>
      & { distribution_list: Array<(
        { __typename?: 'FormBatchUserStatus' }
        & Pick<FormBatchUserStatus, 'opened' | 'response_status' | 'user_uuid'>
      )>, reminder?: Maybe<(
        { __typename?: 'FormBatchReminder' }
        & Pick<FormBatchReminder, 'days' | 'frequency' | 'time' | 'timezone'>
      )> }
    ), tags?: Maybe<Array<(
      { __typename?: 'KeyValuePair' }
      & Pick<KeyValuePair, 'key' | 'value'>
    )>> }
  ) }
);

export type UpdateFormBatchUserOpenedStatusMutationVariables = Exact<{
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type UpdateFormBatchUserOpenedStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateFormBatchUserOpenedStatus: (
    { __typename?: 'FormBatch' }
    & Pick<FormBatch, 'batch_uuid'>
    & { settings: (
      { __typename?: 'FormBatchSettings' }
      & { distribution_list: Array<(
        { __typename?: 'FormBatchUserStatus' }
        & Pick<FormBatchUserStatus, 'opened' | 'response_status' | 'user_uuid'>
      )> }
    ) }
  ) }
);

export type UpdateGlobalLocationMutationVariables = Exact<{
  locationUuid: Scalars['String'];
}>;


export type UpdateGlobalLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGlobalLocation'>
);

export type UpdateGoogleCalAuthTokensMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateGoogleCalAuthTokensMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGoogleCalAuthTokens'>
);

export type UpdateGoogleCalendarColorMutationVariables = Exact<{
  color: Scalars['String'];
}>;


export type UpdateGoogleCalendarColorMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGoogleCalendarColor'>
);

export type UpdateGoogleCalEventMutationVariables = Exact<{
  event: UpdateGoogleCalEvent;
}>;


export type UpdateGoogleCalEventMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGoogleCalEvent'>
);

export type UpdateLocationMutationVariables = Exact<{
  updateLocationsInput: UpdateLocationsInput;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation: (
    { __typename?: 'Locations' }
    & Pick<Locations, 'location_uuid'>
  ) }
);

export type UpdateMessageInQueueMutationVariables = Exact<{
  UpdateMessageInput: UpdateMessageInput;
}>;


export type UpdateMessageInQueueMutation = (
  { __typename?: 'Mutation' }
  & { updateMessageInQueue: (
    { __typename?: 'WorkflowMessages' }
    & Pick<WorkflowMessages, 'workflow_uuid' | 'message_uuid' | 'message' | 'gif' | 'source'>
  ) }
);

export type UpdateNotificationSettingsMutationVariables = Exact<{
  email_active: Scalars['Boolean'];
  email_coffee_chat_notification: Scalars['Boolean'];
  email_event_notification: Scalars['Boolean'];
  email_one_on_one_notification: Scalars['Boolean'];
  slack_active: Scalars['Boolean'];
  slack_coffee_chat_notification: Scalars['Boolean'];
  slack_event_notification: Scalars['Boolean'];
  slack_one_on_one_notification: Scalars['Boolean'];
  tenant_uuid: Scalars['String'];
}>;


export type UpdateNotificationSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateNotificationSettings: (
    { __typename?: 'NotificationSettings' }
    & Pick<NotificationSettings, 'email_active' | 'email_coffee_chat_notification' | 'email_event_notification' | 'email_one_on_one_notification' | 'slack_active' | 'slack_coffee_chat_notification' | 'slack_event_notification' | 'slack_one_on_one_notification' | 'tenant_uuid' | 'user_uuid'>
  ) }
);

export type UpdateOneOnOneSettingsMutationVariables = Exact<{
  oneOnOneSettings: UpdateOneOnOneSettingsInput;
  tenantUuid: Scalars['String'];
}>;


export type UpdateOneOnOneSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateOneOnOneSettings: (
    { __typename?: 'ChatSettings' }
    & { recurring?: Maybe<Array<(
      { __typename?: 'OneOnOneSettings' }
      & Pick<OneOnOneSettings, 'chat_uuid' | 'created_at' | 'duration' | 'frequency' | 'is_active' | 'location' | 'title'>
      & { chat_with: Array<(
        { __typename?: 'ChatRSVP' }
        & Pick<ChatRsvp, 'rsvp' | 'user_uuid'>
      )> }
    )>> }
  ) }
);

export type UpdatePaymentMethodMutationVariables = Exact<{
  returnUrl: Scalars['String'];
  tenantUuid: Scalars['String'];
}>;


export type UpdatePaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & { updatePaymentMethod: (
    { __typename?: 'StripePortal' }
    & Pick<StripePortal, 'portal_id' | 'url'>
  ) }
);

export type UpdateRoomMutationVariables = Exact<{
  updateRoom: UpdateRoomInput;
}>;


export type UpdateRoomMutation = (
  { __typename?: 'Mutation' }
  & { updateRoom: (
    { __typename?: 'RoomDetailedOutput' }
    & Pick<RoomDetailedOutput, 'capacity' | 'description' | 'images' | 'location_uuid' | 'room_name' | 'room_uuid' | 'tenant_uuid'>
  ) }
);

export type UpdateRoomBookingMutationVariables = Exact<{
  updateRoomBookingInput: UpdateRoomBookingInput;
}>;


export type UpdateRoomBookingMutation = (
  { __typename?: 'Mutation' }
  & { updateRoomBooking: (
    { __typename?: 'RoomBookingDetailedOutput' }
    & Pick<RoomBookingDetailedOutput, 'created_at' | 'description' | 'end_time' | 'final_date' | 'location_uuid' | 'room_booking_name' | 'room_booking_uuid' | 'room_uuid' | 'start_time' | 'tenant_uuid'>
    & { guests: Array<(
      { __typename?: 'RoomUser' }
      & Pick<RoomUser, 'image' | 'preferred_name' | 'user_uuid'>
    )> }
  ) }
);

export type AddPremiumUserSubscriptionMutationVariables = Exact<{
  tenantUuid: Scalars['String'];
  billingFrequency: BillingFrequencyEnum;
}>;


export type AddPremiumUserSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { addPremiumUserSubscription: (
    { __typename?: 'StripePortal' }
    & Pick<StripePortal, 'portal_id' | 'url'>
  ) }
);

export type UpdateTeamMutationVariables = Exact<{
  updateTeamInput: UpdateTeamInput;
}>;


export type UpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam: (
    { __typename?: 'Team' }
    & Pick<Team, 'label' | 'team_uuid' | 'tenant_uuid'>
  ) }
);

export type UpdateTenantMutationVariables = Exact<{
  updateTenantInput: UpdateTenantInput;
}>;


export type UpdateTenantMutation = (
  { __typename?: 'Mutation' }
  & { updateTenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'tenant_uuid'>
  ) }
);

export type UpdateTenantPlanMutationVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type UpdateTenantPlanMutation = (
  { __typename?: 'Mutation' }
  & { updateTenantPlan: (
    { __typename?: 'StripeCancellationDetails' }
    & Pick<StripeCancellationDetails, 'cancel_plan_date' | 'cancel_request_date'>
  ) }
);

export type UpdateTimeBlockMutationVariables = Exact<{
  updateTimeBlockInput: UpdateTimeBlockInput;
}>;


export type UpdateTimeBlockMutation = (
  { __typename?: 'Mutation' }
  & { updateTimeBlock: (
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'calendar' | 'created_at' | 'end' | 'end_date' | 'omitted_dates' | 'repeat' | 'start' | 'tenant_uuid' | 'time_block_uuid' | 'type' | 'user_uuid'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserOutput' }
    & Pick<UserOutput, 'user_uuid' | 'preferred_name' | 'full_name' | 'email' | 'role' | 'tenant_uuid' | 'created_at' | 'updated_at' | 'selected_location_id' | 'was_invited' | 'onboarding_step'>
    & { teams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'team_uuid' | 'label'>
    )>> }
  ) }
);

export type UpdateUserRoleMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserOutput' }
    & Pick<UserOutput, 'role' | 'tenant_uuid' | 'user_uuid'>
  ) }
);

export type UpdateWorkflowMutationVariables = Exact<{
  updateWorkflowInput: UpdateWorkflowInput;
}>;


export type UpdateWorkflowMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkflow: (
    { __typename?: 'WorkflowDetailedOutput' }
    & Pick<WorkflowDetailedOutput, 'channel_id' | 'channel_name' | 'created_at' | 'days' | 'frequency' | 'is_active' | 'platform' | 'send_message_as' | 'send_time' | 'tenant_uuid' | 'time_zone' | 'updated_at' | 'workflow_access' | 'workflow_title' | 'workflow_owner' | 'workflow_type' | 'workflow_uuid'>
  ) }
);

export type UploadFileMutationVariables = Exact<{
  uploadFileInput: UploadFileInput;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadFile'>
);

export type UpsertAiBrandVoiceMutationVariables = Exact<{
  content: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type UpsertAiBrandVoiceMutation = (
  { __typename?: 'Mutation' }
  & { upsertAiBrandVoice: (
    { __typename?: 'AiBrandVoice' }
    & Pick<AiBrandVoice, 'brand_voice' | 'tenant_uuid'>
  ) }
);

export type UpsertEventVoteMutationVariables = Exact<{
  eventUuid: Scalars['String'];
  noTimesWork: Scalars['Boolean'];
  pollOptions: Array<Scalars['DateTime']> | Scalars['DateTime'];
  tenantUuid: Scalars['String'];
}>;


export type UpsertEventVoteMutation = (
  { __typename?: 'Mutation' }
  & { upsertEventVote: (
    { __typename?: 'EventDetailedOutput' }
    & { poll_options?: Maybe<Array<(
      { __typename?: 'PollOptionDetails' }
      & Pick<PollOptionDetails, 'date'>
      & { votes?: Maybe<Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'rsvp'>
      )>> }
    )>>, no_times_work?: Maybe<Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'rsvp'>
    )>> }
  ) }
);

export type UpsertRsvpStatusMutationVariables = Exact<{
  eventUuid: Scalars['String'];
  rsvp: EventRsvpEnum;
  tenantUuid: Scalars['String'];
}>;


export type UpsertRsvpStatusMutation = (
  { __typename?: 'Mutation' }
  & { upsertRSVPStatus: (
    { __typename?: 'EventDetailedOutput' }
    & { guests: Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )> }
  ) }
);

export type UpsertRecommendationLogMutationVariables = Exact<{
  recommendationLogInput: RecommendationLogInput;
}>;


export type UpsertRecommendationLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertRecommendationLog'>
);

export type UpsertSlackWorkflowsMutationVariables = Exact<{
  watercoolerChatsInput: SlackWorkflowsInput;
}>;


export type UpsertSlackWorkflowsMutation = (
  { __typename?: 'Mutation' }
  & { upsertSlackWorkflows?: Maybe<(
    { __typename?: 'SlackWorkflows' }
    & Pick<SlackWorkflows, 'tenant_uuid'>
    & { watercooler_chats: (
      { __typename?: 'WatercoolerChats' }
      & Pick<WatercoolerChats, 'channel_id' | 'channel_name' | 'days' | 'frequency' | 'is_active'>
    ) }
  )> }
);

export type UpsertStatusMutationVariables = Exact<{
  upsertStatusInput: Array<UpsertStatusInput> | UpsertStatusInput;
}>;


export type UpsertStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'upsertStatus'>
);

export type CheckScheduleRecommendationAcceptedQueryVariables = Exact<{
  recommendationLogInput: RecommendationLogInput;
}>;


export type CheckScheduleRecommendationAcceptedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkScheduleRecommendationAccepted'>
);

export type GenerateGiFsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  searchTerm: Scalars['String'];
}>;


export type GenerateGiFsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'generateGIFs'>
);

export type GetAccountSetupQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetAccountSetupQuery = (
  { __typename?: 'Query' }
  & { getAccountSetup: Array<(
    { __typename?: 'AccountSetupOutput' }
    & Pick<AccountSetupOutput, 'completed' | 'link' | 'step'>
  )> }
);

export type GetAgentConversationMessagesQueryVariables = Exact<{
  agent_uuid: Scalars['String'];
  page: Scalars['Float'];
  tenant_uuid: Scalars['String'];
  conversation_uuid: Scalars['String'];
}>;


export type GetAgentConversationMessagesQuery = (
  { __typename?: 'Query' }
  & { getAgentConversationMessages: Array<(
    { __typename?: 'AiMessages' }
    & Pick<AiMessages, 'agent_uuid' | 'content' | 'conversation_uuid' | 'created_at' | 'message_uuid' | 'sender' | 'tenant_uuid' | 'user_uuid'>
  )> }
);

export type GetAgentConversationsQueryVariables = Exact<{
  agent_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetAgentConversationsQuery = (
  { __typename?: 'Query' }
  & { getAgentConversations: Array<(
    { __typename?: 'AiConversations' }
    & Pick<AiConversations, 'agent_uuid' | 'conversation_uuid' | 'tenant_uuid' | 'title' | 'users'>
  )> }
);

export type GetAiBrandVoiceQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetAiBrandVoiceQuery = (
  { __typename?: 'Query' }
  & { getAiBrandVoice: (
    { __typename?: 'AiBrandVoice' }
    & Pick<AiBrandVoice, 'brand_voice' | 'tenant_uuid'>
  ) }
);

export type GetAllCommunitiesQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetAllCommunitiesQuery = (
  { __typename?: 'Query' }
  & { getAllCommunities: Array<(
    { __typename?: 'CommunityDetailedOutput' }
    & Pick<CommunityDetailedOutput, 'community_uuid' | 'description' | 'image' | 'is_public' | 'name' | 'tags'>
    & { hosts?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, members?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>> }
  )> }
);

export type GetAllEventCatalogQueryVariables = Exact<{
  eventCatalogInput: EventCatalogInput;
}>;


export type GetAllEventCatalogQuery = (
  { __typename?: 'Query' }
  & { getAllEventCatalog: (
    { __typename?: 'EventCatalogOutput' }
    & Pick<EventCatalogOutput, 'total_records'>
    & { events: Array<(
      { __typename?: 'EventCatalog' }
      & Pick<EventCatalog, 'category' | 'checklist' | 'description' | 'event_uuid' | 'faq' | 'image' | 'location' | 'name' | 'recommended' | 'requirements' | 'resources'>
      & { capacity: (
        { __typename?: 'LabelObj' }
        & Pick<LabelObj, 'label' | 'num'>
      ), cost: (
        { __typename?: 'LabelObj' }
        & Pick<LabelObj, 'label' | 'num'>
      ), duration: (
        { __typename?: 'LabelObj' }
        & Pick<LabelObj, 'label' | 'num'>
      ) }
    )> }
  ) }
);

export type GetAllEventsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetAllEventsQuery = (
  { __typename?: 'Query' }
  & { getAllEvents: (
    { __typename?: 'AllEventsOutput' }
    & { in_progress?: Maybe<Array<(
      { __typename?: 'EventDetailedOutput' }
      & Pick<EventDetailedOutput, 'category' | 'checklist' | 'communities' | 'description' | 'event_uuid' | 'faq' | 'final_date' | 'image' | 'is_poll' | 'location_uuid' | 'name' | 'poll_deadline' | 'requirements' | 'resources' | 'status' | 'teams' | 'tenant_uuid'>
      & { guests: Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>, no_times_work?: Maybe<Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
      )>>, organizers: Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>, poll_options?: Maybe<Array<(
        { __typename?: 'PollOptionDetails' }
        & Pick<PollOptionDetails, 'date'>
        & { votes?: Maybe<Array<(
          { __typename?: 'EventUserDetails' }
          & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'image'>
        )>> }
      )>> }
    )>>, past?: Maybe<Array<(
      { __typename?: 'EventDetailedOutput' }
      & Pick<EventDetailedOutput, 'category' | 'checklist' | 'communities' | 'description' | 'event_uuid' | 'faq' | 'final_date' | 'image' | 'is_poll' | 'location_uuid' | 'name' | 'poll_deadline' | 'requirements' | 'resources' | 'status' | 'teams' | 'tenant_uuid'>
      & { guests: Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>, no_times_work?: Maybe<Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>>, organizers: Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>, poll_options?: Maybe<Array<(
        { __typename?: 'PollOptionDetails' }
        & Pick<PollOptionDetails, 'date'>
        & { votes?: Maybe<Array<(
          { __typename?: 'EventUserDetails' }
          & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'image'>
        )>> }
      )>> }
    )>>, scheduled?: Maybe<Array<(
      { __typename?: 'EventDetailedOutput' }
      & Pick<EventDetailedOutput, 'category' | 'checklist' | 'communities' | 'description' | 'event_uuid' | 'faq' | 'final_date' | 'image' | 'is_poll' | 'location_uuid' | 'name' | 'poll_deadline' | 'requirements' | 'resources' | 'status' | 'teams' | 'tenant_uuid'>
      & { guests: Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>, no_times_work?: Maybe<Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>>, organizers: Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
      )>, poll_options?: Maybe<Array<(
        { __typename?: 'PollOptionDetails' }
        & Pick<PollOptionDetails, 'date'>
        & { votes?: Maybe<Array<(
          { __typename?: 'EventUserDetails' }
          & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'image'>
        )>> }
      )>> }
    )>> }
  ) }
);

export type GetAllMessagesQueryVariables = Exact<{
  workflow_uuid: Scalars['String'];
}>;


export type GetAllMessagesQuery = (
  { __typename?: 'Query' }
  & { getAllMessages: Array<(
    { __typename?: 'WorkflowMessages' }
    & Pick<WorkflowMessages, 'category' | 'created_at' | 'created_by' | 'gif' | 'message' | 'message_uuid' | 'sent' | 'source' | 'tenant_uuid' | 'updated_at' | 'workflow_uuid'>
  )> }
);

export type GetAllTeamsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetAllTeamsQuery = (
  { __typename?: 'Query' }
  & { getAllTeams: Array<(
    { __typename?: 'GetTeamUsersOutput' }
    & Pick<GetTeamUsersOutput, 'label' | 'team_uuid' | 'tenant_uuid'>
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'user_uuid' | 'preferred_name' | 'image'>
    )>, leads: Array<(
      { __typename?: 'User' }
      & Pick<User, 'user_uuid' | 'preferred_name'>
    )> }
  )> }
);

export type GetAllWorkflowsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetAllWorkflowsQuery = (
  { __typename?: 'Query' }
  & { getAllWorkflows: Array<(
    { __typename?: 'WorkflowDetailedOutput' }
    & Pick<WorkflowDetailedOutput, 'channel_id' | 'channel_name' | 'created_at' | 'days' | 'frequency' | 'is_active' | 'platform' | 'send_message_as' | 'send_time' | 'tenant_uuid' | 'time_zone' | 'updated_at' | 'workflow_access' | 'workflow_title' | 'workflow_owner' | 'workflow_uuid' | 'workflow_type'>
  )> }
);

export type GetChatAvailabilityQueryVariables = Exact<{
  chat_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetChatAvailabilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCoffeeChatAvailability'>
);

export type GetChatSettingsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetChatSettingsQuery = (
  { __typename?: 'Query' }
  & { getChatSettings: (
    { __typename?: 'ChatSettings' }
    & { discover: (
      { __typename?: 'ChatStreamSettings' }
      & Pick<ChatStreamSettings, 'frequency' | 'is_active'>
    ), mentorship: (
      { __typename?: 'ChatStreamSettings' }
      & Pick<ChatStreamSettings, 'frequency' | 'is_active'>
    ), recurring?: Maybe<Array<(
      { __typename?: 'OneOnOneSettings' }
      & Pick<OneOnOneSettings, 'chat_uuid' | 'created_at' | 'duration' | 'frequency' | 'is_active' | 'title' | 'location'>
      & { chat_with: Array<(
        { __typename?: 'ChatRSVP' }
        & Pick<ChatRsvp, 'user_uuid' | 'rsvp'>
      )> }
    )>>, stay_connected: (
      { __typename?: 'ChatStreamSettings' }
      & Pick<ChatStreamSettings, 'frequency' | 'is_active'>
    ) }
  ) }
);

export type GetChatsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetChatsQuery = (
  { __typename?: 'Query' }
  & { getChats: Array<(
    { __typename?: 'ChatDetails' }
    & Pick<ChatDetails, 'chat_uuid' | 'created_at' | 'duration' | 'expiration' | 'meeting_time' | 'organizer' | 'tenant_uuid' | 'title' | 'type'>
    & { chat_between: Array<(
      { __typename?: 'ChatRSVPDetails' }
      & Pick<ChatRsvpDetails, 'image' | 'job_title' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, meeting_details: (
      { __typename?: 'MeetingDetails' }
      & Pick<MeetingDetails, 'label' | 'link'>
      & { calendar?: Maybe<(
        { __typename?: 'CalendarEventInfo' }
        & Pick<CalendarEventInfo, 'account_id' | 'calendar_id' | 'calendar_type' | 'event_id'>
      )> }
    ), proposed_times: Array<(
      { __typename?: 'ProposedTimes' }
      & Pick<ProposedTimes, 'time' | 'user_uuid'>
    )> }
  )> }
);

export type GetCommunityQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
  community_uuid: Scalars['String'];
}>;


export type GetCommunityQuery = (
  { __typename?: 'Query' }
  & { getCommunity: (
    { __typename?: 'CommunityDetailedOutput' }
    & Pick<CommunityDetailedOutput, 'communication_channel' | 'communication_guidelines' | 'community_guidelines' | 'community_uuid' | 'description' | 'image' | 'is_public' | 'name' | 'notes' | 'tags'>
    & { hosts?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, members?: Maybe<Array<(
      { __typename?: 'CommunityUserDetails' }
      & Pick<CommunityUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>> }
  ) }
);

export type GetEmployeeInteractionQueryVariables = Exact<{
  getEmployeeInteractionInput: GetEmployeeInteractionInput;
}>;


export type GetEmployeeInteractionQuery = (
  { __typename?: 'Query' }
  & { getEmployeeInteraction: (
    { __typename?: 'GetReportingOutput' }
    & { report: Array<(
      { __typename?: 'ReportingDataCluster' }
      & Pick<ReportingDataCluster, 'id'>
      & { data: Array<(
        { __typename?: 'ReportingData' }
        & Pick<ReportingData, 'y' | 'x'>
      )> }
    )> }
  ) }
);

export type GetEventQueryVariables = Exact<{
  event_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetEventQuery = (
  { __typename?: 'Query' }
  & { getEvent: (
    { __typename?: 'EventDetailedOutput' }
    & Pick<EventDetailedOutput, 'category' | 'checklist' | 'communities' | 'description' | 'event_uuid' | 'faq' | 'final_date' | 'image' | 'is_poll' | 'location_uuid' | 'name' | 'poll_deadline' | 'requirements' | 'resources' | 'status' | 'teams' | 'tenant_uuid'>
    & { guests: Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, no_times_work?: Maybe<Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, organizers: Array<(
      { __typename?: 'EventUserDetails' }
      & Pick<EventUserDetails, 'image' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, poll_options?: Maybe<Array<(
      { __typename?: 'PollOptionDetails' }
      & Pick<PollOptionDetails, 'date'>
      & { votes?: Maybe<Array<(
        { __typename?: 'EventUserDetails' }
        & Pick<EventUserDetails, 'user_uuid' | 'preferred_name' | 'image'>
      )>> }
    )>> }
  ) }
);

export type GetEventDateRecommendationsQueryVariables = Exact<{
  guests: Array<Scalars['String']> | Scalars['String'];
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  tenant_uuid: Scalars['String'];
  location_uuid?: InputMaybe<Scalars['String']>;
}>;


export type GetEventDateRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getEventDateRecommendations: Array<(
    { __typename?: 'EventDateRecommendationOutput' }
    & Pick<EventDateRecommendationOutput, 'date' | 'recommended'>
    & { statuses?: Maybe<Array<(
      { __typename?: 'StatusDetails' }
      & Pick<StatusDetails, 'date' | 'duration' | 'preferred_name' | 'status' | 'status_uuid' | 'user_uuid'>
    )>> }
  )> }
);

export type GetEventTemplateQueryVariables = Exact<{
  event_uuid: Scalars['String'];
}>;


export type GetEventTemplateQuery = (
  { __typename?: 'Query' }
  & { getEventTemplate: (
    { __typename?: 'EventCatalog' }
    & Pick<EventCatalog, 'category' | 'checklist' | 'description' | 'event_uuid' | 'faq' | 'image' | 'location' | 'name' | 'recommended' | 'requirements' | 'resources'>
    & { capacity: (
      { __typename?: 'LabelObj' }
      & Pick<LabelObj, 'label' | 'num'>
    ), cost: (
      { __typename?: 'LabelObj' }
      & Pick<LabelObj, 'label' | 'num'>
    ), duration: (
      { __typename?: 'LabelObj' }
      & Pick<LabelObj, 'label' | 'num'>
    ) }
  ) }
);

export type GetFeaturesQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetFeaturesQuery = (
  { __typename?: 'Query' }
  & { getFeatures: Array<(
    { __typename?: 'Feature' }
    & Pick<Feature, 'feature_uuid' | 'plans' | 'slug' | 'title'>
  )> }
);

export type GetFormQueryVariables = Exact<{
  edit: Scalars['Boolean'];
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormQuery = (
  { __typename?: 'Query' }
  & { getForm: (
    { __typename?: 'Form' }
    & Pick<Form, 'archived' | 'created_at' | 'description' | 'form_uuid' | 'is_anonymous' | 'status' | 'template_uuid' | 'tenant_uuid' | 'title' | 'updated_at'>
    & { access: (
      { __typename?: 'Access' }
      & Pick<Access, 'editors' | 'owners' | 'viewers'>
    ) }
  ) }
);

export type GetFormBatchQueryVariables = Exact<{
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormBatchQuery = (
  { __typename?: 'Query' }
  & { getFormBatch: (
    { __typename?: 'FormBatch' }
    & Pick<FormBatch, 'archived' | 'batch_uuid' | 'created_at' | 'description' | 'form_uuid' | 'tenant_uuid' | 'title' | 'updated_at'>
    & { settings: (
      { __typename?: 'FormBatchSettings' }
      & Pick<FormBatchSettings, 'end_date' | 'is_single_response' | 'start_date'>
      & { distribution_list: Array<(
        { __typename?: 'FormBatchUserStatus' }
        & Pick<FormBatchUserStatus, 'opened' | 'response_status' | 'user_uuid'>
      )>, reminder?: Maybe<(
        { __typename?: 'FormBatchReminder' }
        & Pick<FormBatchReminder, 'timezone' | 'time' | 'frequency' | 'days'>
      )> }
    ), tags?: Maybe<Array<(
      { __typename?: 'KeyValuePair' }
      & Pick<KeyValuePair, 'key' | 'value'>
    )>> }
  ) }
);

export type GetFormBatchesDetailsQueryVariables = Exact<{
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormBatchesDetailsQuery = (
  { __typename?: 'Query' }
  & { getFormBatchesDetails: Array<(
    { __typename?: 'FormBatchDetails' }
    & Pick<FormBatchDetails, 'batch_uuid' | 'completion_rate' | 'open_rate' | 'form_uuid' | 'responses' | 'title' | 'archived' | 'start_date' | 'end_date'>
  )> }
);

export type GetFormBatchesInFormQueryVariables = Exact<{
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormBatchesInFormQuery = (
  { __typename?: 'Query' }
  & { getFormBatchesInForm: Array<(
    { __typename?: 'FormBatch' }
    & Pick<FormBatch, 'archived' | 'batch_uuid' | 'created_at' | 'description' | 'form_uuid' | 'tenant_uuid' | 'title' | 'updated_at'>
    & { settings: (
      { __typename?: 'FormBatchSettings' }
      & Pick<FormBatchSettings, 'end_date' | 'is_single_response' | 'start_date'>
      & { distribution_list: Array<(
        { __typename?: 'FormBatchUserStatus' }
        & Pick<FormBatchUserStatus, 'opened' | 'response_status' | 'user_uuid'>
      )>, reminder?: Maybe<(
        { __typename?: 'FormBatchReminder' }
        & Pick<FormBatchReminder, 'timezone' | 'time' | 'frequency' | 'days'>
      )> }
    ), tags?: Maybe<Array<(
      { __typename?: 'KeyValuePair' }
      & Pick<KeyValuePair, 'key' | 'value'>
    )>> }
  )> }
);

export type GetFormBatchesInTenantQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetFormBatchesInTenantQuery = (
  { __typename?: 'Query' }
  & { getFormBatchesInTenant: Array<(
    { __typename?: 'FormBatch' }
    & Pick<FormBatch, 'archived' | 'batch_uuid' | 'created_at' | 'description' | 'form_uuid' | 'tenant_uuid' | 'title' | 'updated_at'>
    & { settings: (
      { __typename?: 'FormBatchSettings' }
      & Pick<FormBatchSettings, 'end_date' | 'is_single_response' | 'start_date'>
      & { distribution_list: Array<(
        { __typename?: 'FormBatchUserStatus' }
        & Pick<FormBatchUserStatus, 'opened' | 'response_status' | 'user_uuid'>
      )>, reminder?: Maybe<(
        { __typename?: 'FormBatchReminder' }
        & Pick<FormBatchReminder, 'timezone' | 'time' | 'frequency' | 'days'>
      )> }
    ), tags?: Maybe<Array<(
      { __typename?: 'KeyValuePair' }
      & Pick<KeyValuePair, 'key' | 'value'>
    )>> }
  )> }
);

export type GetFormsDetailsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetFormsDetailsQuery = (
  { __typename?: 'Query' }
  & { getFormsDetails: Array<(
    { __typename?: 'FormDetails' }
    & Pick<FormDetails, 'form_uuid' | 'status' | 'last_updated' | 'title' | 'active_batches'>
  )> }
);

export type GetPreBuiltFormQuestionsInBankQueryVariables = Exact<{
  question_uuids: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetPreBuiltFormQuestionsInBankQuery = (
  { __typename?: 'Query' }
  & { getPreBuiltFormQuestionsInBank: Array<(
    { __typename?: 'PreBuiltFormQuestionBank' }
    & Pick<PreBuiltFormQuestionBank, 'archived' | 'created_at' | 'label' | 'question_uuid' | 'response_type' | 'tags' | 'themes' | 'updated_at'>
    & { options?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormPreBuiltTemplateQueryVariables = Exact<{
  template_uuid: Scalars['String'];
}>;


export type GetFormPreBuiltTemplateQuery = (
  { __typename?: 'Query' }
  & { getPreBuiltFormTemplate: (
    { __typename?: 'PreBuiltFormTemplate' }
    & Pick<PreBuiltFormTemplate, 'archived' | 'created_at' | 'description' | 'template_uuid' | 'title' | 'updated_at' | 'version'>
    & { questions: Array<(
      { __typename?: 'QuestionReference' }
      & Pick<QuestionReference, 'is_locked' | 'order' | 'question_uuid'>
    )> }
  ) }
);

export type GetFormPreBuiltTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFormPreBuiltTemplatesQuery = (
  { __typename?: 'Query' }
  & { getPreBuiltFormTemplates: Array<(
    { __typename?: 'PreBuiltFormTemplate' }
    & Pick<PreBuiltFormTemplate, 'archived' | 'created_at' | 'description' | 'template_uuid' | 'title' | 'updated_at' | 'version'>
    & { questions: Array<(
      { __typename?: 'QuestionReference' }
      & Pick<QuestionReference, 'is_locked' | 'order' | 'question_uuid'>
    )> }
  )> }
);

export type GetFormQuestionQueryVariables = Exact<{
  question_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormQuestionQuery = (
  { __typename?: 'Query' }
  & { getFormQuestion: (
    { __typename?: 'FormQuestion' }
    & Pick<FormQuestion, 'archived' | 'created_at' | 'form_uuid' | 'is_required' | 'label' | 'question_uuid' | 'response_type' | 'tenant_uuid' | 'themes' | 'updated_at'>
    & { options?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  ) }
);

export type GetFormQuestionInsightsQueryVariables = Exact<{
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormQuestionInsightsQuery = (
  { __typename?: 'Query' }
  & { getFormQuestionInsights: Array<(
    { __typename?: 'FormQuestionInsights' }
    & Pick<FormQuestionInsights, 'qualitative_response_analytics' | 'question_uuid' | 'response_type'>
    & { quantitative_response_analytics?: Maybe<Array<(
      { __typename?: 'FormQuantitativeOptionDetails' }
      & Pick<FormQuantitativeOptionDetails, 'option_count' | 'option_label' | 'option_percentage' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormQuestionsInFormQueryVariables = Exact<{
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormQuestionsInFormQuery = (
  { __typename?: 'Query' }
  & { getAllFormQuestionsInForm: Array<(
    { __typename?: 'FormQuestion' }
    & Pick<FormQuestion, 'archived' | 'created_at' | 'form_uuid' | 'is_required' | 'label' | 'question_uuid' | 'response_type' | 'tenant_uuid' | 'themes' | 'updated_at' | 'order' | 'is_locked'>
    & { options?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormQuestionsInTenantQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetFormQuestionsInTenantQuery = (
  { __typename?: 'Query' }
  & { getAllFormQuestionsInTenant: Array<(
    { __typename?: 'FormQuestion' }
    & Pick<FormQuestion, 'archived' | 'created_at' | 'form_uuid' | 'is_required' | 'label' | 'question_uuid' | 'response_type' | 'tenant_uuid' | 'themes' | 'updated_at'>
    & { options?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormResponseQueryVariables = Exact<{
  response_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormResponseQuery = (
  { __typename?: 'Query' }
  & { getFormResponse: (
    { __typename?: 'FormResponse' }
    & Pick<FormResponse, 'archived' | 'batch_uuid' | 'created_at' | 'form_uuid' | 'qualitative_response' | 'question_uuid' | 'response_status' | 'response_type' | 'response_uuid' | 'tenant_uuid' | 'updated_at' | 'user_uuid'>
    & { quantitative_response?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  ) }
);

export type GetFormResponsesDetailsInFormQueryVariables = Exact<{
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormResponsesDetailsInFormQuery = (
  { __typename?: 'Query' }
  & { getFormResponsesDetailsInForm: Array<(
    { __typename?: 'FormResponseDetails' }
    & Pick<FormResponseDetails, 'batch_name' | 'batch_uuid' | 'form_uuid' | 'is_anonymous' | 'updated_at' | 'user_uuid'>
    & { response_data: Array<(
      { __typename?: 'FormResponseData' }
      & Pick<FormResponseData, 'qualitative_response' | 'question_uuid' | 'response_type' | 'response_uuid'>
      & { quantitative_response?: Maybe<Array<(
        { __typename?: 'QuestionOption' }
        & Pick<QuestionOption, 'label' | 'option_uuid'>
      )>> }
    )> }
  )> }
);

export type GetFormResponsesToSubmitQueryVariables = Exact<{
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type GetFormResponsesToSubmitQuery = (
  { __typename?: 'Query' }
  & { getFormResponsesToSubmit: Array<(
    { __typename?: 'FormResponse' }
    & Pick<FormResponse, 'archived' | 'batch_uuid' | 'created_at' | 'form_uuid' | 'qualitative_response' | 'question_uuid' | 'response_status' | 'response_type' | 'response_uuid' | 'tenant_uuid' | 'updated_at' | 'user_uuid'>
    & { quantitative_response?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormResponsesInBatchQueryVariables = Exact<{
  batch_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormResponsesInBatchQuery = (
  { __typename?: 'Query' }
  & { getAllFormResponsesInBatch: Array<(
    { __typename?: 'FormResponse' }
    & Pick<FormResponse, 'archived' | 'batch_uuid' | 'created_at' | 'form_uuid' | 'qualitative_response' | 'question_uuid' | 'response_status' | 'response_type' | 'response_uuid' | 'tenant_uuid' | 'updated_at' | 'user_uuid'>
    & { quantitative_response?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormResponsesInFormQueryVariables = Exact<{
  form_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormResponsesInFormQuery = (
  { __typename?: 'Query' }
  & { getAllFormResponsesInForm: Array<(
    { __typename?: 'FormResponse' }
    & Pick<FormResponse, 'archived' | 'batch_uuid' | 'created_at' | 'form_uuid' | 'qualitative_response' | 'question_uuid' | 'response_status' | 'response_type' | 'response_uuid' | 'tenant_uuid' | 'updated_at' | 'user_uuid'>
    & { quantitative_response?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormResponsesInTenantQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetFormResponsesInTenantQuery = (
  { __typename?: 'Query' }
  & { getAllFormResponsesInTenant: Array<(
    { __typename?: 'FormResponse' }
    & Pick<FormResponse, 'archived' | 'batch_uuid' | 'created_at' | 'form_uuid' | 'qualitative_response' | 'question_uuid' | 'response_status' | 'response_type' | 'response_uuid' | 'tenant_uuid' | 'updated_at' | 'user_uuid'>
    & { quantitative_response?: Maybe<Array<(
      { __typename?: 'QuestionOption' }
      & Pick<QuestionOption, 'label' | 'option_uuid'>
    )>> }
  )> }
);

export type GetFormTemplateQueryVariables = Exact<{
  template_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetFormTemplateQuery = (
  { __typename?: 'Query' }
  & { getFormTemplate: (
    { __typename?: 'FormTemplate' }
    & Pick<FormTemplate, 'archived' | 'created_at' | 'description' | 'template_uuid' | 'tenant_uuid' | 'title' | 'updated_at' | 'version'>
    & { access: (
      { __typename?: 'Access' }
      & Pick<Access, 'editors' | 'owners' | 'viewers'>
    ), questions: Array<(
      { __typename?: 'QuestionReference' }
      & Pick<QuestionReference, 'is_locked' | 'order' | 'question_uuid'>
    )> }
  ) }
);

export type GetFormsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetFormsQuery = (
  { __typename?: 'Query' }
  & { getForms: Array<(
    { __typename?: 'Form' }
    & Pick<Form, 'archived' | 'created_at' | 'description' | 'form_uuid' | 'is_anonymous' | 'status' | 'template_uuid' | 'tenant_uuid' | 'title' | 'updated_at'>
    & { access: (
      { __typename?: 'Access' }
      & Pick<Access, 'editors' | 'owners' | 'viewers'>
    ) }
  )> }
);

export type GetFormsToCompleteQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type GetFormsToCompleteQuery = (
  { __typename?: 'Query' }
  & { getFormsToComplete: Array<(
    { __typename?: 'FormToComplete' }
    & Pick<FormToComplete, 'batch_uuid' | 'creator_uuid' | 'creator_name' | 'form_uuid' | 'form_name' | 'opened' | 'end_date'>
  )> }
);

export type GetInvoiceItemsQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type GetInvoiceItemsQuery = (
  { __typename?: 'Query' }
  & { getInvoiceItems: Array<(
    { __typename?: 'StripeInvoice' }
    & Pick<StripeInvoice, 'amount' | 'date' | 'description' | 'invoice_id' | 'link'>
  )> }
);

export type GetJobQueryVariables = Exact<{
  job_title: Scalars['String'];
}>;


export type GetJobQuery = (
  { __typename?: 'Query' }
  & { getJob: (
    { __typename?: 'Job' }
    & Pick<Job, 'job_uuid' | 'job_title'>
    & { top_skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'skill_uuid' | 'skill_label'>
    )> }
  ) }
);

export type GetJobsQueryVariables = Exact<{
  search_keyword: Scalars['String'];
}>;


export type GetJobsQuery = (
  { __typename?: 'Query' }
  & { getJobs: Array<(
    { __typename?: 'Job' }
    & Pick<Job, 'job_uuid' | 'job_title'>
    & { top_skills: Array<(
      { __typename?: 'Skill' }
      & Pick<Skill, 'skill_uuid' | 'skill_label'>
    )> }
  )> }
);

export type GetLocationQueryVariables = Exact<{
  location_uuid: Scalars['String'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { getLocation: (
    { __typename?: 'LocationsOutput' }
    & Pick<LocationsOutput, 'address' | 'address_notes' | 'amenities' | 'archive' | 'capacity_limit' | 'image' | 'location_uuid' | 'name' | 'notes' | 'slack_channel' | 'tenant_uuid'>
    & { community?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, hosts?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, hours_operation?: Maybe<(
      { __typename?: 'HoursOperation' }
      & Pick<HoursOperation, 'fri' | 'mon' | 'sat' | 'sun' | 'thu' | 'tue' | 'wed'>
    )>, wifi_information?: Maybe<(
      { __typename?: 'WifiInformation' }
      & Pick<WifiInformation, 'certificates' | 'contact' | 'network_name' | 'password' | 'security_type'>
    )> }
  ) }
);

export type GetLocationsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { listLocations: Array<(
    { __typename?: 'LocationsOutput' }
    & Pick<LocationsOutput, 'address' | 'address_notes' | 'amenities' | 'archive' | 'capacity_limit' | 'image' | 'location_uuid' | 'name' | 'notes' | 'slack_channel' | 'tenant_uuid'>
    & { community?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, hosts?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'image' | 'preferred_name' | 'user_uuid'>
    )>>, hours_operation?: Maybe<(
      { __typename?: 'HoursOperation' }
      & Pick<HoursOperation, 'fri' | 'mon' | 'sat' | 'sun' | 'thu' | 'tue' | 'wed'>
    )>, wifi_information?: Maybe<(
      { __typename?: 'WifiInformation' }
      & Pick<WifiInformation, 'certificates' | 'contact' | 'network_name' | 'password' | 'security_type'>
    )> }
  )> }
);

export type GetMostFrequentDayQueryVariables = Exact<{
  updateUserBehaviourInput: UpdateUserBehaviourInput;
}>;


export type GetMostFrequentDayQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMostFrequentDay'>
);

export type GetNotificationsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
  limit: Scalars['Float'];
  page: Scalars['Float'];
}>;


export type GetNotificationsQuery = (
  { __typename?: 'Query' }
  & { getNotifications: Array<(
    { __typename?: 'NotificationOutput' }
    & Pick<NotificationOutput, 'notification_uuid' | 'notification_type' | 'sender_uuid' | 'sender_image' | 'sender_name' | 'is_read' | 'link' | 'created_at'>
    & { message_parts: Array<(
      { __typename?: 'MessagePart' }
      & Pick<MessagePart, 'text' | 'text_weight'>
    )> }
  )> }
);

export type GetNotificationsSettingsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetNotificationsSettingsQuery = (
  { __typename?: 'Query' }
  & { getNotificationsSettings: (
    { __typename?: 'NotificationSettings' }
    & Pick<NotificationSettings, 'email_active' | 'email_coffee_chat_notification' | 'email_event_notification' | 'email_one_on_one_notification' | 'slack_active' | 'slack_coffee_chat_notification' | 'slack_event_notification' | 'slack_one_on_one_notification' | 'tenant_uuid' | 'user_uuid'>
  ) }
);

export type GetOfficeRecommendationsQueryVariables = Exact<{
  officeRecommendationsInput: OfficeRecommendationsInput;
}>;


export type GetOfficeRecommendationsQuery = (
  { __typename?: 'Query' }
  & { getOfficeRecommendations: Array<(
    { __typename?: 'OfficeRecommendationsOutput' }
    & Pick<OfficeRecommendationsOutput, 'date' | 'ranking' | 'reasons'>
  )> }
);

export type GetOfficeUtilizationQueryVariables = Exact<{
  getOfficeUtilizationInput: GetOfficeUtilizationInput;
}>;


export type GetOfficeUtilizationQuery = (
  { __typename?: 'Query' }
  & { getOfficeUtilization: (
    { __typename?: 'GetReportingOutput' }
    & { report: Array<(
      { __typename?: 'ReportingDataCluster' }
      & Pick<ReportingDataCluster, 'id'>
      & { data: Array<(
        { __typename?: 'ReportingData' }
        & Pick<ReportingData, 'y' | 'x'>
      )> }
    )> }
  ) }
);

export type GetOrgAnalyticsQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
}>;


export type GetOrgAnalyticsQuery = (
  { __typename?: 'Query' }
  & { getOrgAnalytics: Array<(
    { __typename?: 'OrgAnalytics' }
    & Pick<OrgAnalytics, 'available_hours' | 'blocked_hours' | 'chat_engagement' | 'created_at' | 'engagement_score' | 'event_count' | 'event_engagement' | 'focused_hours' | 'hours_saved' | 'office_engagement' | 'slack_engagement' | 'tenant_uuid'>
    & { slack_community_data: Array<(
      { __typename?: 'CommunityData' }
      & Pick<CommunityData, 'community_uuid' | 'interactions'>
    )> }
  )> }
);

export type GetOrgAvailabilityQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
}>;


export type GetOrgAvailabilityQuery = (
  { __typename?: 'Query' }
  & { getOrgAnalytics: Array<(
    { __typename?: 'OrgAnalytics' }
    & Pick<OrgAnalytics, 'available_hours' | 'focused_hours' | 'blocked_hours' | 'created_at'>
  )> }
);

export type GetOrgChatAnalyticsQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
}>;


export type GetOrgChatAnalyticsQuery = (
  { __typename?: 'Query' }
  & { getOrgAnalytics: Array<(
    { __typename?: 'OrgAnalytics' }
    & Pick<OrgAnalytics, 'chat_engagement' | 'created_at'>
  )> }
);

export type GetOrgEventAnalyticsQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
}>;


export type GetOrgEventAnalyticsQuery = (
  { __typename?: 'Query' }
  & { getOrgAnalytics: Array<(
    { __typename?: 'OrgAnalytics' }
    & Pick<OrgAnalytics, 'event_engagement' | 'created_at'>
  )> }
);

export type GetOrgOfficeAnalyticsQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
}>;


export type GetOrgOfficeAnalyticsQuery = (
  { __typename?: 'Query' }
  & { getOrgAnalytics: Array<(
    { __typename?: 'OrgAnalytics' }
    & Pick<OrgAnalytics, 'office_engagement' | 'created_at'>
  )> }
);

export type GetOrgTeamActivitiesAnalyticsQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
}>;


export type GetOrgTeamActivitiesAnalyticsQuery = (
  { __typename?: 'Query' }
  & { getOrgAnalytics: Array<(
    { __typename?: 'OrgAnalytics' }
    & Pick<OrgAnalytics, 'event_count' | 'created_at'>
  )> }
);

export type GetPastFourWeeksQueryVariables = Exact<{
  getPastFourWeeksInput: GetPastFourWeeksInput;
}>;


export type GetPastFourWeeksQuery = (
  { __typename?: 'Query' }
  & { getPastFourWeeks: (
    { __typename?: 'GetPastFourWeeksOutput' }
    & Pick<GetPastFourWeeksOutput, 'eventCount' | 'peopleMetAtEvents' | 'peopleMetAtOffice' | 'teamsAtOffice'>
  ) }
);

export type GetReportQueryVariables = Exact<{
  getReportInput: GetReportInput;
}>;


export type GetReportQuery = (
  { __typename?: 'Query' }
  & { getReport: (
    { __typename?: 'GetReportOutput' }
    & Pick<GetReportOutput, 'most_used_status' | 'office_utilization' | 'unique_users'>
    & { status_rate: (
      { __typename?: 'StatusRate' }
      & Pick<StatusRate, 'OFFICE' | 'REMOTE' | 'NOT_WORKING'>
    ), capacity_utilization: Array<(
      { __typename?: 'CapacityUtilization' }
      & Pick<CapacityUtilization, 'date' | 'utilization'>
    )> }
  ) }
);

export type GetRoomBookingQueryVariables = Exact<{
  roomBookingUuid: Scalars['String'];
}>;


export type GetRoomBookingQuery = (
  { __typename?: 'Query' }
  & { getRoomBooking: (
    { __typename?: 'RoomBookingDetailedOutput' }
    & Pick<RoomBookingDetailedOutput, 'created_at' | 'description' | 'end_time' | 'final_date' | 'location_uuid' | 'room_booking_name' | 'room_booking_uuid' | 'room_uuid' | 'start_time' | 'tenant_uuid'>
    & { guests: Array<(
      { __typename?: 'RoomUser' }
      & Pick<RoomUser, 'image' | 'preferred_name' | 'user_uuid'>
    )> }
  ) }
);

export type GetSlackChannelsQueryVariables = Exact<{
  tenantId: Scalars['String'];
}>;


export type GetSlackChannelsQuery = (
  { __typename?: 'Query' }
  & { getSlackChannels: Array<(
    { __typename?: 'SlackChannel' }
    & Pick<SlackChannel, 'id' | 'name'>
  )> }
);

export type GetSlackWorkflowsQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetSlackWorkflowsQuery = (
  { __typename?: 'Query' }
  & { getSlackWorkflows?: Maybe<(
    { __typename?: 'SlackWorkflows' }
    & Pick<SlackWorkflows, 'tenant_uuid'>
    & { watercooler_chats: (
      { __typename?: 'WatercoolerChats' }
      & Pick<WatercoolerChats, 'channel_id' | 'channel_name' | 'days' | 'frequency' | 'is_active'>
    ) }
  )> }
);

export type GetStatusesQueryVariables = Exact<{
  getStatusesInput: GetStatusesInput;
}>;


export type GetStatusesQuery = (
  { __typename?: 'Query' }
  & { getStatuses: Array<(
    { __typename?: 'StatusDetails' }
    & Pick<StatusDetails, 'duration' | 'preferred_name' | 'status' | 'status_uuid' | 'user_uuid' | 'date' | 'checked_in'>
  )> }
);

export type GetTeamQueryVariables = Exact<{
  team_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type GetTeamQuery = (
  { __typename?: 'Query' }
  & { getTeam: (
    { __typename?: 'GetTeamOutput' }
    & Pick<GetTeamOutput, 'label'>
    & { insights: (
      { __typename?: 'GetTeamInsightOutput' }
      & Pick<GetTeamInsightOutput, 'event_engagement' | 'top_five_interests'>
      & { in_office_frequency: (
        { __typename?: 'InOfficeFrequency' }
        & Pick<InOfficeFrequency, 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'>
      ), status_rate: (
        { __typename?: 'StatusRate' }
        & Pick<StatusRate, 'OFFICE' | 'REMOTE' | 'NOT_WORKING'>
      ) }
    ), users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'preferred_name' | 'interests' | 'goals' | 'skills' | 'user_uuid' | 'image'>
    )>, leads: Array<(
      { __typename?: 'User' }
      & Pick<User, 'preferred_name' | 'interests' | 'goals' | 'skills' | 'user_uuid' | 'image'>
    )> }
  ) }
);

export type GetTeamMemberQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetTeamMemberQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserOutput' }
    & Pick<UserOutput, 'created_at' | 'email' | 'preferred_name' | 'user_uuid' | 'role' | 'tenant_uuid' | 'is_onboarded' | 'updated_at' | 'image'>
    & { teams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'label' | 'team_uuid'>
    )>> }
  ) }
);

export type GetTeamOfficePresenceQueryVariables = Exact<{
  getTeamOfficePresenceInput: GetTeamOfficePresenceInput;
}>;


export type GetTeamOfficePresenceQuery = (
  { __typename?: 'Query' }
  & { getTeamOfficePresence: (
    { __typename?: 'GetReportingOutput' }
    & { report: Array<(
      { __typename?: 'ReportingDataCluster' }
      & Pick<ReportingDataCluster, 'id'>
      & { data: Array<(
        { __typename?: 'ReportingData' }
        & Pick<ReportingData, 'y' | 'x'>
      )> }
    )> }
  ) }
);

export type GetTenantQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetTenantQuery = (
  { __typename?: 'Query' }
  & { getTenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'tenant_uuid' | 'title' | 'logo' | 'preferred_office_day_count' | 'schedule_weekends' | 'company_type' | 'users'>
    & { payment: (
      { __typename?: 'Payment' }
      & Pick<Payment, 'plan' | 'currency' | 'discount' | 'billing_frequency' | 'premium_user_count'>
    ) }
  ) }
);

export type GetTenantUserCountQueryVariables = Exact<{
  tenantUuid: Scalars['String'];
}>;


export type GetTenantUserCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTenantUserCount'>
);

export type GetTenantUsersQueryVariables = Exact<{
  getTenantUsersInput: GetTenantUsersInput;
}>;


export type GetTenantUsersQuery = (
  { __typename?: 'Query' }
  & { getTenantUsers: (
    { __typename?: 'TenantUserOutput' }
    & Pick<TenantUserOutput, 'total_pages' | 'current_page' | 'total_users'>
    & { users: Array<(
      { __typename?: 'UserOutput' }
      & Pick<UserOutput, 'user_uuid' | 'preferred_name' | 'email' | 'selected_location_id' | 'role' | 'is_signed_up' | 'image'>
      & { teams?: Maybe<Array<(
        { __typename?: 'Team' }
        & Pick<Team, 'label'>
      )>> }
    )> }
  ) }
);

export type GetTimeBlocksQueryVariables = Exact<{
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  tenantUuid: Scalars['String'];
  userUuid: Scalars['String'];
}>;


export type GetTimeBlocksQuery = (
  { __typename?: 'Query' }
  & { getTimeBlocks: Array<(
    { __typename?: 'TimeBlock' }
    & Pick<TimeBlock, 'calendar' | 'created_at' | 'end' | 'end_date' | 'omitted_dates' | 'repeat' | 'start' | 'tenant_uuid' | 'time_block_uuid' | 'type' | 'user_uuid'>
  )> }
);

export type GetUpcomingActivitiesQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
}>;


export type GetUpcomingActivitiesQuery = (
  { __typename?: 'Query' }
  & { getBirthdays: Array<(
    { __typename?: 'UserOutput' }
    & Pick<UserOutput, 'birthday' | 'image' | 'user_uuid' | 'preferred_name'>
  )>, getChats: Array<(
    { __typename?: 'ChatDetails' }
    & Pick<ChatDetails, 'chat_uuid' | 'created_at' | 'duration' | 'expiration' | 'meeting_time' | 'organizer' | 'tenant_uuid' | 'title' | 'type'>
    & { chat_between: Array<(
      { __typename?: 'ChatRSVPDetails' }
      & Pick<ChatRsvpDetails, 'image' | 'job_title' | 'preferred_name' | 'rsvp' | 'user_uuid'>
    )>, meeting_details: (
      { __typename?: 'MeetingDetails' }
      & Pick<MeetingDetails, 'label' | 'link'>
    ) }
  )>, getAllEvents: (
    { __typename?: 'AllEventsOutput' }
    & { scheduled?: Maybe<Array<(
      { __typename?: 'EventDetailedOutput' }
      & Pick<EventDetailedOutput, 'category' | 'checklist' | 'communities' | 'description' | 'event_uuid' | 'faq' | 'final_date' | 'image' | 'is_poll' | 'location_uuid' | 'name' | 'poll_deadline' | 'requirements' | 'resources' | 'status' | 'teams' | 'tenant_uuid'>
    )>> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserOutput' }
    & Pick<UserOutput, 'user_uuid' | 'preferred_name' | 'full_name' | 'email' | 'isConnectedSlackUser' | 'bio' | 'birthday' | 'start_date' | 'timezone' | 'phone_number' | 'role' | 'tenant_uuid' | 'skills' | 'interests' | 'job_title' | 'was_invited' | 'created_at' | 'updated_at' | 'selected_location_id' | 'onboarding_step' | 'is_onboarded' | 'is_signed_up' | 'isFollowedByMe' | 'isConnectedWithSlack' | 'image' | 'isConnectedWithGoogleCal'>
    & { teams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'team_uuid' | 'label'>
    )>> }
  ) }
);

export type GetUserAnalyticsQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type GetUserAnalyticsQuery = (
  { __typename?: 'Query' }
  & { getUserAnalytics: Array<(
    { __typename?: 'UserAnalytics' }
    & Pick<UserAnalytics, 'connections' | 'created_at'>
    & { meeting_hours: Array<(
      { __typename?: 'WeeklyMeetingData' }
      & Pick<WeeklyMeetingData, 'day_number' | 'hours'>
    )> }
  )> }
);

export type GetUserAnalyticsConnectionsQueryVariables = Exact<{
  num_of_weeks: Scalars['Float'];
  tenant_uuid: Scalars['String'];
  user_uuid: Scalars['String'];
}>;


export type GetUserAnalyticsConnectionsQuery = (
  { __typename?: 'Query' }
  & { getUserAnalytics: Array<(
    { __typename?: 'UserAnalytics' }
    & Pick<UserAnalytics, 'connections' | 'created_at'>
    & { meeting_hours: Array<(
      { __typename?: 'WeeklyMeetingData' }
      & Pick<WeeklyMeetingData, 'day_number' | 'hours'>
    )> }
  )> }
);

export type GetUserSelectedLocationQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetUserSelectedLocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserSelectedLocation'>
);

export type GetUserStepsQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetUserStepsQuery = (
  { __typename?: 'Query' }
  & { getUserSteps: (
    { __typename?: 'UserStepsOutput' }
    & Pick<UserStepsOutput, 'create_teams' | 'invite_members' | 'slack_integration' | 'google_calendar' | 'follow_people' | 'filled_profile' | 'is_admin'>
  ) }
);

export type GetUserTeamsQueryVariables = Exact<{
  user_uuid: Scalars['String'];
}>;


export type GetUserTeamsQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserOutput' }
    & { teams?: Maybe<Array<(
      { __typename?: 'Team' }
      & Pick<Team, 'team_uuid' | 'label'>
    )>> }
  ) }
);

export type GetWaterCoolerTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWaterCoolerTopicsQuery = (
  { __typename?: 'Query' }
  & { getWaterCoolerTopics: Array<(
    { __typename?: 'WaterCoolerTopicOutput' }
    & Pick<WaterCoolerTopicOutput, 'category'>
    & { values: Array<(
      { __typename?: 'TopicValueOutput' }
      & Pick<TopicValueOutput, 'gif' | 'message'>
    )> }
  )> }
);

export type GetWorkflowQueryVariables = Exact<{
  tenant_uuid: Scalars['String'];
  workflow_uuid: Scalars['String'];
}>;


export type GetWorkflowQuery = (
  { __typename?: 'Query' }
  & { getWorkflow: (
    { __typename?: 'WorkflowDetailedOutput' }
    & Pick<WorkflowDetailedOutput, 'channel_id' | 'channel_name' | 'created_at' | 'days' | 'frequency' | 'is_active' | 'platform' | 'send_message_as' | 'send_time' | 'tenant_uuid' | 'time_zone' | 'updated_at' | 'workflow_access' | 'workflow_title' | 'workflow_owner' | 'workflow_type' | 'workflow_uuid'>
  ) }
);

export type GetWorkspaceInsightsQueryVariables = Exact<{
  getWorkspaceInsightsInput: GetWorkspaceInsightsInput;
}>;


export type GetWorkspaceInsightsQuery = (
  { __typename?: 'Query' }
  & { getWorkspaceInsights: (
    { __typename?: 'GetWorkspaceInsightsOutput' }
    & Pick<GetWorkspaceInsightsOutput, 'admin_hours' | 'engagement_score' | 'num_creators' | 'num_events' | 'num_guests' | 'trend_events' | 'trend_hours' | 'trend_score'>
  ) }
);

export type ListAllRoomBookingsQueryVariables = Exact<{
  location_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type ListAllRoomBookingsQuery = (
  { __typename?: 'Query' }
  & { listAllRoomBookings: (
    { __typename?: 'RoomBookingListOutput' }
    & { booked_rooms?: Maybe<Array<(
      { __typename?: 'RoomDetails' }
      & Pick<RoomDetails, 'capacity' | 'images' | 'description' | 'room_name' | 'room_uuid'>
      & { scheduled?: Maybe<Array<(
        { __typename?: 'RoomBookingDetails' }
        & Pick<RoomBookingDetails, 'description' | 'end_time' | 'final_date' | 'room_booking_name' | 'room_booking_uuid' | 'start_time'>
        & { guests: Array<(
          { __typename?: 'RoomUser' }
          & Pick<RoomUser, 'image' | 'preferred_name' | 'user_uuid'>
        )> }
      )>> }
    )>> }
  ) }
);

export type ListAllRoomsQueryVariables = Exact<{
  location_uuid: Scalars['String'];
  tenant_uuid: Scalars['String'];
}>;


export type ListAllRoomsQuery = (
  { __typename?: 'Query' }
  & { listAllRooms: (
    { __typename?: 'RoomListOutput' }
    & { booked_rooms?: Maybe<Array<(
      { __typename?: 'RoomDetailedOutput' }
      & Pick<RoomDetailedOutput, 'capacity' | 'description' | 'images' | 'location_uuid' | 'room_name' | 'room_uuid' | 'tenant_uuid'>
    )>> }
  ) }
);

export type ListGoogleCalEventsQueryVariables = Exact<{
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
}>;


export type ListGoogleCalEventsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'listGoogleCalEvents'>
);


export const AddTeamUserDocument = gql`
    mutation AddTeamUser($team_uuid: String!, $tenant_uuid: String!, $user_uuid: String!) {
  addTeamUser(
    team_uuid: $team_uuid
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    email
    preferred_name
    teams
    user_uuid
  }
}
    `;

export function useAddTeamUserMutation() {
  return Urql.useMutation<AddTeamUserMutation, AddTeamUserMutationVariables>(AddTeamUserDocument);
};
export const ArchiveFormDocument = gql`
    mutation ArchiveForm($form_uuid: String!, $tenant_uuid: String!) {
  archiveForm(form_uuid: $form_uuid, tenant_uuid: $tenant_uuid)
}
    `;

export function useArchiveFormMutation() {
  return Urql.useMutation<ArchiveFormMutation, ArchiveFormMutationVariables>(ArchiveFormDocument);
};
export const ArchiveFormBatchDocument = gql`
    mutation ArchiveFormBatch($batch_uuid: String!, $tenant_uuid: String!) {
  archiveFormBatch(batch_uuid: $batch_uuid, tenant_uuid: $tenant_uuid)
}
    `;

export function useArchiveFormBatchMutation() {
  return Urql.useMutation<ArchiveFormBatchMutation, ArchiveFormBatchMutationVariables>(ArchiveFormBatchDocument);
};
export const BuildFormDocument = gql`
    mutation BuildForm($buildFormInput: BuildFormInput!) {
  buildForm(buildFormInput: $buildFormInput)
}
    `;

export function useBuildFormMutation() {
  return Urql.useMutation<BuildFormMutation, BuildFormMutationVariables>(BuildFormDocument);
};
export const CancelChatDocument = gql`
    mutation CancelChat($chatUuid: String!, $tenantUuid: String!) {
  cancelChat(chat_uuid: $chatUuid, tenant_uuid: $tenantUuid)
}
    `;

export function useCancelChatMutation() {
  return Urql.useMutation<CancelChatMutation, CancelChatMutationVariables>(CancelChatDocument);
};
export const CancelPremiumSubscriptionDocument = gql`
    mutation CancelPremiumSubscription($returnUrl: String!, $tenantUuid: String!) {
  cancelPremiumSubscription(return_url: $returnUrl, tenant_uuid: $tenantUuid) {
    portal_id
    url
  }
}
    `;

export function useCancelPremiumSubscriptionMutation() {
  return Urql.useMutation<CancelPremiumSubscriptionMutation, CancelPremiumSubscriptionMutationVariables>(CancelPremiumSubscriptionDocument);
};
export const CreateAgentConversationDocument = gql`
    mutation CreateAgentConversation($agent_uuid: String!, $message: String!, $tenant_uuid: String!) {
  createAgentConversation(
    agent_uuid: $agent_uuid
    message: $message
    tenant_uuid: $tenant_uuid
  ) {
    agent_uuid
    conversation_uuid
    tenant_uuid
    title
    users
  }
}
    `;

export function useCreateAgentConversationMutation() {
  return Urql.useMutation<CreateAgentConversationMutation, CreateAgentConversationMutationVariables>(CreateAgentConversationDocument);
};
export const CreateCheckoutSessionDocument = gql`
    mutation CreateCheckoutSession($tenantUuid: String!, $billingFrequency: BillingFrequencyEnum!, $returnUrl: String!) {
  createCheckoutSession(
    tenant_uuid: $tenantUuid
    billing_frequency: $billingFrequency
    return_url: $returnUrl
  ) {
    portal_id
    url
  }
}
    `;

export function useCreateCheckoutSessionMutation() {
  return Urql.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument);
};
export const CreateCommunityDocument = gql`
    mutation CreateCommunity($createCommunityInput: CreateCommunityInput!) {
  createCommunity(createCommunityInput: $createCommunityInput) {
    communication_channel
    communication_guidelines
    community_guidelines
    community_uuid
    description
    hosts {
      image
      preferred_name
      user_uuid
    }
    image
    is_public
    members {
      image
      preferred_name
      user_uuid
    }
    name
    notes
    tags
    tenant_uuid
  }
}
    `;

export function useCreateCommunityMutation() {
  return Urql.useMutation<CreateCommunityMutation, CreateCommunityMutationVariables>(CreateCommunityDocument);
};
export const CreateEventDocument = gql`
    mutation CreateEvent($createEventInput: CreateEventInput!) {
  createEvent(createEventInput: $createEventInput) {
    category
    checklist
    communities
    description
    event_uuid
    faq
    final_date
    guests {
      image
      preferred_name
      rsvp
      user_uuid
    }
    image
    is_poll
    location_uuid
    name
    no_times_work {
      image
      preferred_name
      rsvp
      user_uuid
    }
    organizers {
      image
      preferred_name
      rsvp
      user_uuid
    }
    poll_deadline
    poll_options {
      date
      votes {
        user_uuid
        preferred_name
        image
      }
    }
    requirements
    resources
    status
    teams
    tenant_uuid
  }
}
    `;

export function useCreateEventMutation() {
  return Urql.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument);
};
export const CreateFormBatchDocument = gql`
    mutation CreateFormBatch($createFormBatchInput: CreateFormBatchInput!) {
  createFormBatch(createFormBatchInput: $createFormBatchInput) {
    archived
    batch_uuid
    created_at
    description
    form_uuid
    settings {
      distribution_list {
        opened
        response_status
        user_uuid
      }
      end_date
      is_single_response
      reminder {
        days
        frequency
        time
        timezone
      }
      start_date
    }
    tags {
      key
      value
    }
    tenant_uuid
    title
    updated_at
  }
}
    `;

export function useCreateFormBatchMutation() {
  return Urql.useMutation<CreateFormBatchMutation, CreateFormBatchMutationVariables>(CreateFormBatchDocument);
};
export const CreateGoogleCalAuthSessionDocument = gql`
    mutation CreateGoogleCalAuthSession {
  createGoogleCalAuthSession
}
    `;

export function useCreateGoogleCalAuthSessionMutation() {
  return Urql.useMutation<CreateGoogleCalAuthSessionMutation, CreateGoogleCalAuthSessionMutationVariables>(CreateGoogleCalAuthSessionDocument);
};
export const CreateGoogleCalEventDocument = gql`
    mutation CreateGoogleCalEvent($event: GoogleCalEvent!) {
  createGoogleCalEvent(event: $event)
}
    `;

export function useCreateGoogleCalEventMutation() {
  return Urql.useMutation<CreateGoogleCalEventMutation, CreateGoogleCalEventMutationVariables>(CreateGoogleCalEventDocument);
};
export const CreateLocationDocument = gql`
    mutation CreateLocation($createLocationsInput: CreateLocationsInput!) {
  createLocation(createLocationsInput: $createLocationsInput) {
    location_uuid
  }
}
    `;

export function useCreateLocationMutation() {
  return Urql.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument);
};
export const CreateMessagesDocument = gql`
    mutation CreateMessages($addMessageInput: [AddMessageInput!]!, $tenant_uuid: String!, $workflow_uuid: String!) {
  createMessages(
    AddMessageInput: $addMessageInput
    tenant_uuid: $tenant_uuid
    workflow_uuid: $workflow_uuid
  ) {
    category
    created_at
    created_by
    gif
    message
    message_uuid
    sent
    source
    tenant_uuid
    updated_at
    workflow_uuid
  }
}
    `;

export function useCreateMessagesMutation() {
  return Urql.useMutation<CreateMessagesMutation, CreateMessagesMutationVariables>(CreateMessagesDocument);
};
export const CreateOneOnOnesDocument = gql`
    mutation CreateOneOnOnes($oneOnOneInput: OneOnOneInput!) {
  createOneOnOnes(oneOnOneInput: $oneOnOneInput)
}
    `;

export function useCreateOneOnOnesMutation() {
  return Urql.useMutation<CreateOneOnOnesMutation, CreateOneOnOnesMutationVariables>(CreateOneOnOnesDocument);
};
export const CreateRoomDocument = gql`
    mutation CreateRoom($createRoomInput: CreateRoomInput!) {
  createRoom(CreateRoomInput: $createRoomInput) {
    capacity
    description
    images
    location_uuid
    room_name
    room_uuid
    tenant_uuid
  }
}
    `;

export function useCreateRoomMutation() {
  return Urql.useMutation<CreateRoomMutation, CreateRoomMutationVariables>(CreateRoomDocument);
};
export const CreateRoomBookingDocument = gql`
    mutation CreateRoomBooking($createRoomBookingInput: CreateRoomBookingInput!) {
  createRoomBooking(CreateRoomBookingInput: $createRoomBookingInput) {
    created_at
    description
    end_time
    final_date
    guests {
      image
      preferred_name
      user_uuid
    }
    location_uuid
    room_booking_name
    room_booking_uuid
    room_uuid
    start_time
    tenant_uuid
  }
}
    `;

export function useCreateRoomBookingMutation() {
  return Urql.useMutation<CreateRoomBookingMutation, CreateRoomBookingMutationVariables>(CreateRoomBookingDocument);
};
export const CreateTeamDocument = gql`
    mutation CreateTeam($createTeamInput: CreateTeamInput!) {
  createTeam(createTeamInput: $createTeamInput) {
    label
    team_uuid
    tenant_uuid
  }
}
    `;

export function useCreateTeamMutation() {
  return Urql.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument);
};
export const CreateTenantDocument = gql`
    mutation CreateTenant($createTenantInput: CreateTenantInput!) {
  createTenant(createTenantInput: $createTenantInput) {
    tenant_uuid
    title
  }
}
    `;

export function useCreateTenantMutation() {
  return Urql.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument);
};
export const CreateTimeBlockDocument = gql`
    mutation CreateTimeBlock($createTimeBlockInput: CreateTimeBlockInput!) {
  createTimeBlock(createTimeBlockInput: $createTimeBlockInput) {
    calendar
    end
    repeat
    start
    tenant_uuid
    user_uuid
    type
    time_block_uuid
  }
}
    `;

export function useCreateTimeBlockMutation() {
  return Urql.useMutation<CreateTimeBlockMutation, CreateTimeBlockMutationVariables>(CreateTimeBlockDocument);
};
export const CreateWorkflowDocument = gql`
    mutation CreateWorkflow($createWorkflowInput: CreateWorkflowInput!) {
  createWorkflow(CreateWorkflowInput: $createWorkflowInput) {
    channel_id
    channel_name
    created_at
    days
    frequency
    is_active
    platform
    send_message_as
    send_time
    tenant_uuid
    time_zone
    updated_at
    workflow_access
    workflow_title
    workflow_owner
    workflow_uuid
  }
}
    `;

export function useCreateWorkflowMutation() {
  return Urql.useMutation<CreateWorkflowMutation, CreateWorkflowMutationVariables>(CreateWorkflowDocument);
};
export const DeactivateUserDocument = gql`
    mutation DeactivateUser($user_uuid: String!, $tenant_uuid: String!) {
  deactivateUser(user_uuid: $user_uuid, tenant_uuid: $tenant_uuid) {
    users {
      user_uuid
      tenant_uuid
      email
      role
    }
  }
}
    `;

export function useDeactivateUserMutation() {
  return Urql.useMutation<DeactivateUserMutation, DeactivateUserMutationVariables>(DeactivateUserDocument);
};
export const DeleteCommunityDocument = gql`
    mutation DeleteCommunity($community_uuid: String!, $tenant_uuid: String!) {
  deleteCommunity(community_uuid: $community_uuid, tenant_uuid: $tenant_uuid)
}
    `;

export function useDeleteCommunityMutation() {
  return Urql.useMutation<DeleteCommunityMutation, DeleteCommunityMutationVariables>(DeleteCommunityDocument);
};
export const DeleteEventDocument = gql`
    mutation DeleteEvent($eventUuid: String!, $tenantUuid: String!) {
  deleteEvent(event_uuid: $eventUuid, tenant_uuid: $tenantUuid)
}
    `;

export function useDeleteEventMutation() {
  return Urql.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument);
};
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($location_uuid: String!) {
  deleteLocation(location_uuid: $location_uuid)
}
    `;

export function useDeleteLocationMutation() {
  return Urql.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument);
};
export const DeleteMultipleMessagesDocument = gql`
    mutation DeleteMultipleMessages($message_uuid: [String!]!) {
  deleteMultipleMessages(message_uuid: $message_uuid)
}
    `;

export function useDeleteMultipleMessagesMutation() {
  return Urql.useMutation<DeleteMultipleMessagesMutation, DeleteMultipleMessagesMutationVariables>(DeleteMultipleMessagesDocument);
};
export const DeleteRoomDocument = gql`
    mutation DeleteRoom($room_uuid: String!) {
  deleteRoom(room_uuid: $room_uuid)
}
    `;

export function useDeleteRoomMutation() {
  return Urql.useMutation<DeleteRoomMutation, DeleteRoomMutationVariables>(DeleteRoomDocument);
};
export const DeleteRoomBookingDocument = gql`
    mutation DeleteRoomBooking($room_booking_uuid: String!) {
  deleteRoomBooking(room_booking_uuid: $room_booking_uuid)
}
    `;

export function useDeleteRoomBookingMutation() {
  return Urql.useMutation<DeleteRoomBookingMutation, DeleteRoomBookingMutationVariables>(DeleteRoomBookingDocument);
};
export const DeleteTimeBlockDocument = gql`
    mutation DeleteTimeBlock($timeBlockUuid: String!) {
  deleteTimeBlock(time_block_uuid: $timeBlockUuid)
}
    `;

export function useDeleteTimeBlockMutation() {
  return Urql.useMutation<DeleteTimeBlockMutation, DeleteTimeBlockMutationVariables>(DeleteTimeBlockDocument);
};
export const DeleteWorkflowDocument = gql`
    mutation DeleteWorkflow($workflow_uuid: String!) {
  deleteWorkflow(workflow_uuid: $workflow_uuid)
}
    `;

export function useDeleteWorkflowMutation() {
  return Urql.useMutation<DeleteWorkflowMutation, DeleteWorkflowMutationVariables>(DeleteWorkflowDocument);
};
export const DeleteMessageFromQueueDocument = gql`
    mutation DeleteMessageFromQueue($messageUuid: String!) {
  deleteMessageFromQueue(message_uuid: $messageUuid)
}
    `;

export function useDeleteMessageFromQueueMutation() {
  return Urql.useMutation<DeleteMessageFromQueueMutation, DeleteMessageFromQueueMutationVariables>(DeleteMessageFromQueueDocument);
};
export const DisconnectGoogleCalUserAuthDocument = gql`
    mutation DisconnectGoogleCalUserAuth {
  disconnectGoogleCalUserAuth
}
    `;

export function useDisconnectGoogleCalUserAuthMutation() {
  return Urql.useMutation<DisconnectGoogleCalUserAuthMutation, DisconnectGoogleCalUserAuthMutationVariables>(DisconnectGoogleCalUserAuthDocument);
};
export const DisconnectSlackWorkspaceDocument = gql`
    mutation DisconnectSlackWorkspace($tenant_id: String!) {
  disconnectSlackWorkspace(tenant_id: $tenant_id)
}
    `;

export function useDisconnectSlackWorkspaceMutation() {
  return Urql.useMutation<DisconnectSlackWorkspaceMutation, DisconnectSlackWorkspaceMutationVariables>(DisconnectSlackWorkspaceDocument);
};
export const FollowUserDocument = gql`
    mutation FollowUser($user_uuid: String!) {
  followUser(user_uuid: $user_uuid)
}
    `;

export function useFollowUserMutation() {
  return Urql.useMutation<FollowUserMutation, FollowUserMutationVariables>(FollowUserDocument);
};
export const GenerateAiMessageDocument = gql`
    mutation GenerateAIMessage($count: Float!, $prompt: String!, $generateGIFs: Boolean!) {
  generateAIMessage(count: $count, prompt: $prompt, generateGIFs: $generateGIFs) {
    category
    gif
    message
    source
  }
}
    `;

export function useGenerateAiMessageMutation() {
  return Urql.useMutation<GenerateAiMessageMutation, GenerateAiMessageMutationVariables>(GenerateAiMessageDocument);
};
export const GenerateLlmFormQuestionsDocument = gql`
    mutation GenerateLLMFormQuestions($prompt: String!) {
  generateLLMFormQuestions(prompt: $prompt) {
    archived
    created_at
    form_uuid
    is_locked
    is_required
    label
    options {
      label
      option_uuid
    }
    order
    question_uuid
    response_type
    tenant_uuid
    themes
    updated_at
  }
}
    `;

export function useGenerateLlmFormQuestionsMutation() {
  return Urql.useMutation<GenerateLlmFormQuestionsMutation, GenerateLlmFormQuestionsMutationVariables>(GenerateLlmFormQuestionsDocument);
};
export const InviteUsersDocument = gql`
    mutation InviteUsers($emails: [String!]!, $tenant_uuid: String!, $office_uuid: String, $role: String) {
  inviteUsers(
    emails: $emails
    tenant_uuid: $tenant_uuid
    office_uuid: $office_uuid
    role: $role
  )
}
    `;

export function useInviteUsersMutation() {
  return Urql.useMutation<InviteUsersMutation, InviteUsersMutationVariables>(InviteUsersDocument);
};
export const JoinCommunityDocument = gql`
    mutation JoinCommunity($community_uuid: String!, $tenant_uuid: String!, $user_uuid: String!) {
  joinCommunity(
    community_uuid: $community_uuid
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    communication_channel
    communication_guidelines
    community_guidelines
    community_uuid
    description
    hosts {
      image
      preferred_name
      user_uuid
    }
    image
    is_public
    members {
      image
      preferred_name
      user_uuid
    }
    name
    notes
    tags
    tenant_uuid
  }
}
    `;

export function useJoinCommunityMutation() {
  return Urql.useMutation<JoinCommunityMutation, JoinCommunityMutationVariables>(JoinCommunityDocument);
};
export const LeaveCommunityDocument = gql`
    mutation LeaveCommunity($community_uuid: String!, $tenant_uuid: String!, $user_uuid: String!) {
  leaveCommunity(
    community_uuid: $community_uuid
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    communication_channel
    communication_guidelines
    community_guidelines
    community_uuid
    description
    hosts {
      image
      preferred_name
      user_uuid
    }
    image
    is_public
    members {
      image
      preferred_name
      user_uuid
    }
    name
    notes
    tags
    tenant_uuid
  }
}
    `;

export function useLeaveCommunityMutation() {
  return Urql.useMutation<LeaveCommunityMutation, LeaveCommunityMutationVariables>(LeaveCommunityDocument);
};
export const ReadAllNotificationsDocument = gql`
    mutation ReadAllNotifications($tenant_uuid: String!) {
  readAllNotifications(tenant_uuid: $tenant_uuid)
}
    `;

export function useReadAllNotificationsMutation() {
  return Urql.useMutation<ReadAllNotificationsMutation, ReadAllNotificationsMutationVariables>(ReadAllNotificationsDocument);
};
export const RemoveGoogleCalEventDocument = gql`
    mutation RemoveGoogleCalEvent($google_event_id: String!) {
  removeGoogleCalEvent(google_event_id: $google_event_id)
}
    `;

export function useRemoveGoogleCalEventMutation() {
  return Urql.useMutation<RemoveGoogleCalEventMutation, RemoveGoogleCalEventMutationVariables>(RemoveGoogleCalEventDocument);
};
export const RemoveInviteDocument = gql`
    mutation RemoveInvite($email: String!, $tenant_uuid: String!) {
  removeInvite(email: $email, tenant_uuid: $tenant_uuid)
}
    `;

export function useRemoveInviteMutation() {
  return Urql.useMutation<RemoveInviteMutation, RemoveInviteMutationVariables>(RemoveInviteDocument);
};
export const RemoveOneOnOneSettingDocument = gql`
    mutation RemoveOneOnOneSetting($chatUuid: String!, $tenantUuid: String!) {
  removeOneOnOneSetting(chat_uuid: $chatUuid, tenant_uuid: $tenantUuid) {
    recurring {
      chat_uuid
      chat_with {
        rsvp
        user_uuid
      }
      created_at
      duration
      frequency
      is_active
      location
      title
    }
  }
}
    `;

export function useRemoveOneOnOneSettingMutation() {
  return Urql.useMutation<RemoveOneOnOneSettingMutation, RemoveOneOnOneSettingMutationVariables>(RemoveOneOnOneSettingDocument);
};
export const RemoveTeamDocument = gql`
    mutation RemoveTeam($team_uuid: String!) {
  removeTeam(team_uuid: $team_uuid)
}
    `;

export function useRemoveTeamMutation() {
  return Urql.useMutation<RemoveTeamMutation, RemoveTeamMutationVariables>(RemoveTeamDocument);
};
export const RemoveUserTeamDocument = gql`
    mutation RemoveUserTeam($team_uuid: String!, $tenant_uuid: String!, $user_uuid: String!) {
  removeUserTeam(
    team_uuid: $team_uuid
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    teams
    user_uuid
  }
}
    `;

export function useRemoveUserTeamMutation() {
  return Urql.useMutation<RemoveUserTeamMutation, RemoveUserTeamMutationVariables>(RemoveUserTeamDocument);
};
export const RenewPendingCancelledSubscriptionDocument = gql`
    mutation RenewPendingCancelledSubscription($returnUrl: String!, $tenantUuid: String!) {
  renewPendingCancelledSubscription(
    return_url: $returnUrl
    tenant_uuid: $tenantUuid
  )
}
    `;

export function useRenewPendingCancelledSubscriptionMutation() {
  return Urql.useMutation<RenewPendingCancelledSubscriptionMutation, RenewPendingCancelledSubscriptionMutationVariables>(RenewPendingCancelledSubscriptionDocument);
};
export const RescheduleChatDocument = gql`
    mutation RescheduleChat($chatUuid: String!, $meetingTime: String!, $tenantUuid: String!) {
  rescheduleChat(
    chat_uuid: $chatUuid
    meeting_time: $meetingTime
    tenant_uuid: $tenantUuid
  )
}
    `;

export function useRescheduleChatMutation() {
  return Urql.useMutation<RescheduleChatMutation, RescheduleChatMutationVariables>(RescheduleChatDocument);
};
export const SendAgentMessageDocument = gql`
    mutation SendAgentMessage($agent_uuid: String!, $conversation_uuid: String!, $message: String!, $sender: String!, $tenant_uuid: String!) {
  sendAgentMessage(
    agent_uuid: $agent_uuid
    conversation_uuid: $conversation_uuid
    message: $message
    sender: $sender
    tenant_uuid: $tenant_uuid
  ) {
    agent_uuid
    content
    conversation_uuid
    created_at
    message_uuid
    sender
    tenant_uuid
    user_uuid
  }
}
    `;

export function useSendAgentMessageMutation() {
  return Urql.useMutation<SendAgentMessageMutation, SendAgentMessageMutationVariables>(SendAgentMessageDocument);
};
export const DirectSignUpDocument = gql`
    mutation DirectSignUp($email: String!, $password: String!, $full_name: String!, $timezone: String!) {
  directSignUp(
    email: $email
    password: $password
    full_name: $full_name
    timezone: $timezone
  )
}
    `;

export function useDirectSignUpMutation() {
  return Urql.useMutation<DirectSignUpMutation, DirectSignUpMutationVariables>(DirectSignUpDocument);
};
export const StoreGoogleCalAuthTokensDocument = gql`
    mutation StoreGoogleCalAuthTokens($authorizationCode: String!, $tenantUuid: String!) {
  storeGoogleCalAuthTokens(
    authorization_code: $authorizationCode
    tenant_uuid: $tenantUuid
  )
}
    `;

export function useStoreGoogleCalAuthTokensMutation() {
  return Urql.useMutation<StoreGoogleCalAuthTokensMutation, StoreGoogleCalAuthTokensMutationVariables>(StoreGoogleCalAuthTokensDocument);
};
export const SubmitFormResponseDocument = gql`
    mutation SubmitFormResponse($submitFormResponseInput: SubmitFormResponseInput!) {
  submitFormResponse(submitFormResponseInput: $submitFormResponseInput) {
    archived
    batch_uuid
    created_at
    form_uuid
    qualitative_response
    quantitative_response {
      label
      option_uuid
    }
    question_uuid
    response_status
    response_type
    response_uuid
    tenant_uuid
    updated_at
    user_uuid
  }
}
    `;

export function useSubmitFormResponseMutation() {
  return Urql.useMutation<SubmitFormResponseMutation, SubmitFormResponseMutationVariables>(SubmitFormResponseDocument);
};
export const UnfollowUserDocument = gql`
    mutation UnfollowUser($user_uuid: String!) {
  unfollowUser(user_uuid: $user_uuid)
}
    `;

export function useUnfollowUserMutation() {
  return Urql.useMutation<UnfollowUserMutation, UnfollowUserMutationVariables>(UnfollowUserDocument);
};
export const UpdateChatSettingsDocument = gql`
    mutation UpdateChatSettings($chat_type: String!, $frequency: String!, $is_active: Boolean!, $tenant_uuid: String!) {
  updateChatSettings(
    chat_type: $chat_type
    frequency: $frequency
    is_active: $is_active
    tenant_uuid: $tenant_uuid
  ) {
    discover {
      frequency
      is_active
    }
    stay_connected {
      frequency
      is_active
    }
    mentorship {
      frequency
      is_active
    }
    recurring {
      chat_with {
        rsvp
        user_uuid
      }
      duration
      frequency
      is_active
      title
    }
  }
}
    `;

export function useUpdateChatSettingsMutation() {
  return Urql.useMutation<UpdateChatSettingsMutation, UpdateChatSettingsMutationVariables>(UpdateChatSettingsDocument);
};
export const UpdateCommunityDocument = gql`
    mutation UpdateCommunity($updateCommunityInput: UpdateCommunityInput!) {
  updateCommunity(updateCommunityInput: $updateCommunityInput) {
    communication_channel
    communication_guidelines
    community_guidelines
    community_uuid
    description
    hosts {
      image
      preferred_name
      user_uuid
    }
    image
    is_public
    members {
      image
      preferred_name
      user_uuid
    }
    name
    notes
    tags
    tenant_uuid
  }
}
    `;

export function useUpdateCommunityMutation() {
  return Urql.useMutation<UpdateCommunityMutation, UpdateCommunityMutationVariables>(UpdateCommunityDocument);
};
export const UpdateEventDocument = gql`
    mutation UpdateEvent($updateEventInput: UpdateEventInput!, $is_finalized: Boolean) {
  updateEvent(updateEventInput: $updateEventInput, is_finalized: $is_finalized) {
    category
    checklist
    communities
    description
    event_uuid
    faq
    final_date
    guests {
      image
      preferred_name
      rsvp
      user_uuid
    }
    image
    is_poll
    location_uuid
    name
    no_times_work {
      image
      preferred_name
      rsvp
      user_uuid
    }
    organizers {
      image
      preferred_name
      rsvp
      user_uuid
    }
    poll_deadline
    poll_options {
      date
      votes {
        user_uuid
        preferred_name
        image
      }
    }
    requirements
    resources
    status
    teams
    tenant_uuid
  }
}
    `;

export function useUpdateEventMutation() {
  return Urql.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument);
};
export const UpdateFormBatchDocument = gql`
    mutation UpdateFormBatch($updateFormBatchInput: UpdateFormBatchInput!) {
  updateFormBatch(updateFormBatchInput: $updateFormBatchInput) {
    archived
    batch_uuid
    created_at
    description
    form_uuid
    settings {
      distribution_list {
        opened
        response_status
        user_uuid
      }
      end_date
      is_single_response
      reminder {
        days
        frequency
        time
        timezone
      }
      start_date
    }
    tags {
      key
      value
    }
    tenant_uuid
    title
    updated_at
  }
}
    `;

export function useUpdateFormBatchMutation() {
  return Urql.useMutation<UpdateFormBatchMutation, UpdateFormBatchMutationVariables>(UpdateFormBatchDocument);
};
export const UpdateFormBatchUserOpenedStatusDocument = gql`
    mutation UpdateFormBatchUserOpenedStatus($batch_uuid: String!, $tenant_uuid: String!, $user_uuid: String!) {
  updateFormBatchUserOpenedStatus(
    batch_uuid: $batch_uuid
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    batch_uuid
    settings {
      distribution_list {
        opened
        response_status
        user_uuid
      }
    }
  }
}
    `;

export function useUpdateFormBatchUserOpenedStatusMutation() {
  return Urql.useMutation<UpdateFormBatchUserOpenedStatusMutation, UpdateFormBatchUserOpenedStatusMutationVariables>(UpdateFormBatchUserOpenedStatusDocument);
};
export const UpdateGlobalLocationDocument = gql`
    mutation UpdateGlobalLocation($locationUuid: String!) {
  updateGlobalLocation(location_uuid: $locationUuid)
}
    `;

export function useUpdateGlobalLocationMutation() {
  return Urql.useMutation<UpdateGlobalLocationMutation, UpdateGlobalLocationMutationVariables>(UpdateGlobalLocationDocument);
};
export const UpdateGoogleCalAuthTokensDocument = gql`
    mutation UpdateGoogleCalAuthTokens {
  updateGoogleCalAuthTokens
}
    `;

export function useUpdateGoogleCalAuthTokensMutation() {
  return Urql.useMutation<UpdateGoogleCalAuthTokensMutation, UpdateGoogleCalAuthTokensMutationVariables>(UpdateGoogleCalAuthTokensDocument);
};
export const UpdateGoogleCalendarColorDocument = gql`
    mutation UpdateGoogleCalendarColor($color: String!) {
  updateGoogleCalendarColor(color: $color)
}
    `;

export function useUpdateGoogleCalendarColorMutation() {
  return Urql.useMutation<UpdateGoogleCalendarColorMutation, UpdateGoogleCalendarColorMutationVariables>(UpdateGoogleCalendarColorDocument);
};
export const UpdateGoogleCalEventDocument = gql`
    mutation UpdateGoogleCalEvent($event: UpdateGoogleCalEvent!) {
  updateGoogleCalEvent(event: $event)
}
    `;

export function useUpdateGoogleCalEventMutation() {
  return Urql.useMutation<UpdateGoogleCalEventMutation, UpdateGoogleCalEventMutationVariables>(UpdateGoogleCalEventDocument);
};
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($updateLocationsInput: UpdateLocationsInput!) {
  updateLocation(updateLocationsInput: $updateLocationsInput) {
    location_uuid
  }
}
    `;

export function useUpdateLocationMutation() {
  return Urql.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument);
};
export const UpdateMessageInQueueDocument = gql`
    mutation UpdateMessageInQueue($UpdateMessageInput: UpdateMessageInput!) {
  updateMessageInQueue(UpdateMessageInput: $UpdateMessageInput) {
    workflow_uuid
    message_uuid
    message
    gif
    source
  }
}
    `;

export function useUpdateMessageInQueueMutation() {
  return Urql.useMutation<UpdateMessageInQueueMutation, UpdateMessageInQueueMutationVariables>(UpdateMessageInQueueDocument);
};
export const UpdateNotificationSettingsDocument = gql`
    mutation UpdateNotificationSettings($email_active: Boolean!, $email_coffee_chat_notification: Boolean!, $email_event_notification: Boolean!, $email_one_on_one_notification: Boolean!, $slack_active: Boolean!, $slack_coffee_chat_notification: Boolean!, $slack_event_notification: Boolean!, $slack_one_on_one_notification: Boolean!, $tenant_uuid: String!) {
  updateNotificationSettings(
    email_active: $email_active
    email_coffee_chat_notification: $email_coffee_chat_notification
    email_event_notification: $email_event_notification
    email_one_on_one_notification: $email_one_on_one_notification
    slack_active: $slack_active
    slack_coffee_chat_notification: $slack_coffee_chat_notification
    slack_event_notification: $slack_event_notification
    slack_one_on_one_notification: $slack_one_on_one_notification
    tenant_uuid: $tenant_uuid
  ) {
    email_active
    email_coffee_chat_notification
    email_event_notification
    email_one_on_one_notification
    slack_active
    slack_coffee_chat_notification
    slack_event_notification
    slack_one_on_one_notification
    tenant_uuid
    user_uuid
  }
}
    `;

export function useUpdateNotificationSettingsMutation() {
  return Urql.useMutation<UpdateNotificationSettingsMutation, UpdateNotificationSettingsMutationVariables>(UpdateNotificationSettingsDocument);
};
export const UpdateOneOnOneSettingsDocument = gql`
    mutation UpdateOneOnOneSettings($oneOnOneSettings: UpdateOneOnOneSettingsInput!, $tenantUuid: String!) {
  updateOneOnOneSettings(
    oneOnOneSettings: $oneOnOneSettings
    tenant_uuid: $tenantUuid
  ) {
    recurring {
      chat_uuid
      chat_with {
        rsvp
        user_uuid
      }
      created_at
      duration
      frequency
      is_active
      location
      title
    }
  }
}
    `;

export function useUpdateOneOnOneSettingsMutation() {
  return Urql.useMutation<UpdateOneOnOneSettingsMutation, UpdateOneOnOneSettingsMutationVariables>(UpdateOneOnOneSettingsDocument);
};
export const UpdatePaymentMethodDocument = gql`
    mutation UpdatePaymentMethod($returnUrl: String!, $tenantUuid: String!) {
  updatePaymentMethod(return_url: $returnUrl, tenant_uuid: $tenantUuid) {
    portal_id
    url
  }
}
    `;

export function useUpdatePaymentMethodMutation() {
  return Urql.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(UpdatePaymentMethodDocument);
};
export const UpdateRoomDocument = gql`
    mutation UpdateRoom($updateRoom: UpdateRoomInput!) {
  updateRoom(updateRoom: $updateRoom) {
    capacity
    description
    images
    location_uuid
    room_name
    room_uuid
    tenant_uuid
  }
}
    `;

export function useUpdateRoomMutation() {
  return Urql.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(UpdateRoomDocument);
};
export const UpdateRoomBookingDocument = gql`
    mutation UpdateRoomBooking($updateRoomBookingInput: UpdateRoomBookingInput!) {
  updateRoomBooking(updateRoomBooking: $updateRoomBookingInput) {
    created_at
    description
    end_time
    final_date
    location_uuid
    room_booking_name
    room_booking_uuid
    room_uuid
    start_time
    tenant_uuid
    guests {
      image
      preferred_name
      user_uuid
    }
  }
}
    `;

export function useUpdateRoomBookingMutation() {
  return Urql.useMutation<UpdateRoomBookingMutation, UpdateRoomBookingMutationVariables>(UpdateRoomBookingDocument);
};
export const AddPremiumUserSubscriptionDocument = gql`
    mutation AddPremiumUserSubscription($tenantUuid: String!, $billingFrequency: BillingFrequencyEnum!) {
  addPremiumUserSubscription(
    tenant_uuid: $tenantUuid
    billing_frequency: $billingFrequency
  ) {
    portal_id
    url
  }
}
    `;

export function useAddPremiumUserSubscriptionMutation() {
  return Urql.useMutation<AddPremiumUserSubscriptionMutation, AddPremiumUserSubscriptionMutationVariables>(AddPremiumUserSubscriptionDocument);
};
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($updateTeamInput: UpdateTeamInput!) {
  updateTeam(updateTeamInput: $updateTeamInput) {
    label
    team_uuid
    tenant_uuid
  }
}
    `;

export function useUpdateTeamMutation() {
  return Urql.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument);
};
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($updateTenantInput: UpdateTenantInput!) {
  updateTenant(updateTenantInput: $updateTenantInput) {
    tenant_uuid
  }
}
    `;

export function useUpdateTenantMutation() {
  return Urql.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument);
};
export const UpdateTenantPlanDocument = gql`
    mutation UpdateTenantPlan($tenantUuid: String!) {
  updateTenantPlan(tenant_uuid: $tenantUuid) {
    cancel_plan_date
    cancel_request_date
  }
}
    `;

export function useUpdateTenantPlanMutation() {
  return Urql.useMutation<UpdateTenantPlanMutation, UpdateTenantPlanMutationVariables>(UpdateTenantPlanDocument);
};
export const UpdateTimeBlockDocument = gql`
    mutation UpdateTimeBlock($updateTimeBlockInput: UpdateTimeBlockInput!) {
  updateTimeBlock(updateTimeBlockInput: $updateTimeBlockInput) {
    calendar
    created_at
    end
    end_date
    omitted_dates
    repeat
    start
    tenant_uuid
    time_block_uuid
    type
    user_uuid
  }
}
    `;

export function useUpdateTimeBlockMutation() {
  return Urql.useMutation<UpdateTimeBlockMutation, UpdateTimeBlockMutationVariables>(UpdateTimeBlockDocument);
};
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    user_uuid
    preferred_name
    full_name
    teams {
      team_uuid
      label
    }
    email
    role
    tenant_uuid
    created_at
    updated_at
    selected_location_id
    was_invited
    onboarding_step
  }
}
    `;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
};
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($updateUserInput: UpdateUserInput!) {
  updateUser(updateUserInput: $updateUserInput) {
    role
    tenant_uuid
    user_uuid
  }
}
    `;

export function useUpdateUserRoleMutation() {
  return Urql.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument);
};
export const UpdateWorkflowDocument = gql`
    mutation UpdateWorkflow($updateWorkflowInput: UpdateWorkflowInput!) {
  updateWorkflow(UpdateWorkflowInput: $updateWorkflowInput) {
    channel_id
    channel_name
    created_at
    days
    frequency
    is_active
    platform
    send_message_as
    send_time
    tenant_uuid
    time_zone
    updated_at
    workflow_access
    workflow_title
    workflow_owner
    workflow_type
    workflow_uuid
  }
}
    `;

export function useUpdateWorkflowMutation() {
  return Urql.useMutation<UpdateWorkflowMutation, UpdateWorkflowMutationVariables>(UpdateWorkflowDocument);
};
export const UploadFileDocument = gql`
    mutation UploadFile($uploadFileInput: UploadFileInput!) {
  uploadFile(uploadFileInput: $uploadFileInput)
}
    `;

export function useUploadFileMutation() {
  return Urql.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument);
};
export const UpsertAiBrandVoiceDocument = gql`
    mutation UpsertAiBrandVoice($content: String!, $tenant_uuid: String!) {
  upsertAiBrandVoice(content: $content, tenant_uuid: $tenant_uuid) {
    brand_voice
    tenant_uuid
  }
}
    `;

export function useUpsertAiBrandVoiceMutation() {
  return Urql.useMutation<UpsertAiBrandVoiceMutation, UpsertAiBrandVoiceMutationVariables>(UpsertAiBrandVoiceDocument);
};
export const UpsertEventVoteDocument = gql`
    mutation UpsertEventVote($eventUuid: String!, $noTimesWork: Boolean!, $pollOptions: [DateTime!]!, $tenantUuid: String!) {
  upsertEventVote(
    event_uuid: $eventUuid
    no_times_work: $noTimesWork
    poll_options: $pollOptions
    tenant_uuid: $tenantUuid
  ) {
    poll_options {
      date
      votes {
        user_uuid
        preferred_name
        rsvp
      }
    }
    no_times_work {
      user_uuid
      preferred_name
      rsvp
    }
  }
}
    `;

export function useUpsertEventVoteMutation() {
  return Urql.useMutation<UpsertEventVoteMutation, UpsertEventVoteMutationVariables>(UpsertEventVoteDocument);
};
export const UpsertRsvpStatusDocument = gql`
    mutation UpsertRSVPStatus($eventUuid: String!, $rsvp: EventRSVPEnum!, $tenantUuid: String!) {
  upsertRSVPStatus(event_uuid: $eventUuid, rsvp: $rsvp, tenant_uuid: $tenantUuid) {
    guests {
      image
      preferred_name
      rsvp
      user_uuid
    }
  }
}
    `;

export function useUpsertRsvpStatusMutation() {
  return Urql.useMutation<UpsertRsvpStatusMutation, UpsertRsvpStatusMutationVariables>(UpsertRsvpStatusDocument);
};
export const UpsertRecommendationLogDocument = gql`
    mutation UpsertRecommendationLog($recommendationLogInput: RecommendationLogInput!) {
  upsertRecommendationLog(RecommendationLogInput: $recommendationLogInput)
}
    `;

export function useUpsertRecommendationLogMutation() {
  return Urql.useMutation<UpsertRecommendationLogMutation, UpsertRecommendationLogMutationVariables>(UpsertRecommendationLogDocument);
};
export const UpsertSlackWorkflowsDocument = gql`
    mutation UpsertSlackWorkflows($watercoolerChatsInput: SlackWorkflowsInput!) {
  upsertSlackWorkflows(watercoolerChatsInput: $watercoolerChatsInput) {
    tenant_uuid
    watercooler_chats {
      channel_id
      channel_name
      days
      frequency
      is_active
    }
  }
}
    `;

export function useUpsertSlackWorkflowsMutation() {
  return Urql.useMutation<UpsertSlackWorkflowsMutation, UpsertSlackWorkflowsMutationVariables>(UpsertSlackWorkflowsDocument);
};
export const UpsertStatusDocument = gql`
    mutation UpsertStatus($upsertStatusInput: [UpsertStatusInput!]!) {
  upsertStatus(upsertStatusInput: $upsertStatusInput)
}
    `;

export function useUpsertStatusMutation() {
  return Urql.useMutation<UpsertStatusMutation, UpsertStatusMutationVariables>(UpsertStatusDocument);
};
export const CheckScheduleRecommendationAcceptedDocument = gql`
    query CheckScheduleRecommendationAccepted($recommendationLogInput: RecommendationLogInput!) {
  checkScheduleRecommendationAccepted(
    RecommendationLogInput: $recommendationLogInput
  )
}
    `;

export function useCheckScheduleRecommendationAcceptedQuery(options: Omit<Urql.UseQueryArgs<CheckScheduleRecommendationAcceptedQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckScheduleRecommendationAcceptedQuery, CheckScheduleRecommendationAcceptedQueryVariables>({ query: CheckScheduleRecommendationAcceptedDocument, ...options });
};
export const GenerateGiFsDocument = gql`
    query GenerateGIFs($limit: Int!, $page: Int!, $searchTerm: String!) {
  generateGIFs(limit: $limit, page: $page, search_term: $searchTerm)
}
    `;

export function useGenerateGiFsQuery(options: Omit<Urql.UseQueryArgs<GenerateGiFsQueryVariables>, 'query'>) {
  return Urql.useQuery<GenerateGiFsQuery, GenerateGiFsQueryVariables>({ query: GenerateGiFsDocument, ...options });
};
export const GetAccountSetupDocument = gql`
    query GetAccountSetup($tenant_uuid: String!) {
  getAccountSetup(tenant_uuid: $tenant_uuid) {
    completed
    link
    step
  }
}
    `;

export function useGetAccountSetupQuery(options: Omit<Urql.UseQueryArgs<GetAccountSetupQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAccountSetupQuery, GetAccountSetupQueryVariables>({ query: GetAccountSetupDocument, ...options });
};
export const GetAgentConversationMessagesDocument = gql`
    query GetAgentConversationMessages($agent_uuid: String!, $page: Float!, $tenant_uuid: String!, $conversation_uuid: String!) {
  getAgentConversationMessages(
    agent_uuid: $agent_uuid
    page: $page
    tenant_uuid: $tenant_uuid
    conversation_uuid: $conversation_uuid
  ) {
    agent_uuid
    content
    conversation_uuid
    created_at
    message_uuid
    sender
    tenant_uuid
    user_uuid
  }
}
    `;

export function useGetAgentConversationMessagesQuery(options: Omit<Urql.UseQueryArgs<GetAgentConversationMessagesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAgentConversationMessagesQuery, GetAgentConversationMessagesQueryVariables>({ query: GetAgentConversationMessagesDocument, ...options });
};
export const GetAgentConversationsDocument = gql`
    query GetAgentConversations($agent_uuid: String!, $tenant_uuid: String!) {
  getAgentConversations(agent_uuid: $agent_uuid, tenant_uuid: $tenant_uuid) {
    agent_uuid
    conversation_uuid
    tenant_uuid
    title
    users
  }
}
    `;

export function useGetAgentConversationsQuery(options: Omit<Urql.UseQueryArgs<GetAgentConversationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAgentConversationsQuery, GetAgentConversationsQueryVariables>({ query: GetAgentConversationsDocument, ...options });
};
export const GetAiBrandVoiceDocument = gql`
    query GetAiBrandVoice($tenant_uuid: String!) {
  getAiBrandVoice(tenant_uuid: $tenant_uuid) {
    brand_voice
    tenant_uuid
  }
}
    `;

export function useGetAiBrandVoiceQuery(options: Omit<Urql.UseQueryArgs<GetAiBrandVoiceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAiBrandVoiceQuery, GetAiBrandVoiceQueryVariables>({ query: GetAiBrandVoiceDocument, ...options });
};
export const GetAllCommunitiesDocument = gql`
    query GetAllCommunities($tenant_uuid: String!, $search: String) {
  getAllCommunities(tenant_uuid: $tenant_uuid, search: $search) {
    community_uuid
    description
    hosts {
      image
      preferred_name
      user_uuid
    }
    image
    is_public
    members {
      image
      preferred_name
      user_uuid
    }
    name
    tags
  }
}
    `;

export function useGetAllCommunitiesQuery(options: Omit<Urql.UseQueryArgs<GetAllCommunitiesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllCommunitiesQuery, GetAllCommunitiesQueryVariables>({ query: GetAllCommunitiesDocument, ...options });
};
export const GetAllEventCatalogDocument = gql`
    query GetAllEventCatalog($eventCatalogInput: EventCatalogInput!) {
  getAllEventCatalog(eventCatalogInput: $eventCatalogInput) {
    events {
      capacity {
        label
        num
      }
      category
      checklist
      cost {
        label
        num
      }
      description
      duration {
        label
        num
      }
      event_uuid
      faq
      image
      location
      name
      recommended
      requirements
      resources
    }
    total_records
  }
}
    `;

export function useGetAllEventCatalogQuery(options: Omit<Urql.UseQueryArgs<GetAllEventCatalogQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllEventCatalogQuery, GetAllEventCatalogQueryVariables>({ query: GetAllEventCatalogDocument, ...options });
};
export const GetAllEventsDocument = gql`
    query GetAllEvents($tenant_uuid: String!) {
  getAllEvents(tenant_uuid: $tenant_uuid) {
    in_progress {
      category
      checklist
      communities
      description
      event_uuid
      faq
      final_date
      guests {
        image
        preferred_name
        rsvp
        user_uuid
      }
      image
      is_poll
      location_uuid
      name
      no_times_work {
        image
        preferred_name
        user_uuid
      }
      organizers {
        image
        preferred_name
        rsvp
        user_uuid
      }
      poll_deadline
      poll_options {
        date
        votes {
          user_uuid
          preferred_name
          image
        }
      }
      requirements
      resources
      status
      teams
      tenant_uuid
    }
    past {
      category
      checklist
      communities
      description
      event_uuid
      faq
      final_date
      image
      is_poll
      location_uuid
      name
      poll_deadline
      requirements
      resources
      status
      teams
      tenant_uuid
      guests {
        image
        preferred_name
        rsvp
        user_uuid
      }
      no_times_work {
        image
        preferred_name
        rsvp
        user_uuid
      }
      organizers {
        image
        preferred_name
        rsvp
        user_uuid
      }
      poll_options {
        date
        votes {
          user_uuid
          preferred_name
          image
        }
      }
    }
    scheduled {
      category
      checklist
      communities
      description
      event_uuid
      faq
      final_date
      image
      is_poll
      location_uuid
      name
      poll_deadline
      requirements
      resources
      status
      teams
      tenant_uuid
      guests {
        image
        preferred_name
        rsvp
        user_uuid
      }
      no_times_work {
        image
        preferred_name
        rsvp
        user_uuid
      }
      organizers {
        image
        preferred_name
        rsvp
        user_uuid
      }
      poll_options {
        date
        votes {
          user_uuid
          preferred_name
          image
        }
      }
    }
  }
}
    `;

export function useGetAllEventsQuery(options: Omit<Urql.UseQueryArgs<GetAllEventsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllEventsQuery, GetAllEventsQueryVariables>({ query: GetAllEventsDocument, ...options });
};
export const GetAllMessagesDocument = gql`
    query GetAllMessages($workflow_uuid: String!) {
  getAllMessages(workflow_uuid: $workflow_uuid) {
    category
    created_at
    created_by
    gif
    message
    message_uuid
    sent
    source
    tenant_uuid
    updated_at
    workflow_uuid
  }
}
    `;

export function useGetAllMessagesQuery(options: Omit<Urql.UseQueryArgs<GetAllMessagesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllMessagesQuery, GetAllMessagesQueryVariables>({ query: GetAllMessagesDocument, ...options });
};
export const GetAllTeamsDocument = gql`
    query GetAllTeams($tenant_uuid: String!) {
  getAllTeams(tenant_uuid: $tenant_uuid) {
    label
    team_uuid
    tenant_uuid
    users {
      user_uuid
      preferred_name
      image
    }
    leads {
      user_uuid
      preferred_name
    }
  }
}
    `;

export function useGetAllTeamsQuery(options: Omit<Urql.UseQueryArgs<GetAllTeamsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllTeamsQuery, GetAllTeamsQueryVariables>({ query: GetAllTeamsDocument, ...options });
};
export const GetAllWorkflowsDocument = gql`
    query GetAllWorkflows($tenant_uuid: String!, $search: String) {
  getAllWorkflows(tenant_uuid: $tenant_uuid, search: $search) {
    channel_id
    channel_name
    created_at
    days
    frequency
    is_active
    platform
    send_message_as
    send_time
    tenant_uuid
    time_zone
    updated_at
    workflow_access
    workflow_title
    workflow_owner
    workflow_uuid
    workflow_type
  }
}
    `;

export function useGetAllWorkflowsQuery(options: Omit<Urql.UseQueryArgs<GetAllWorkflowsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAllWorkflowsQuery, GetAllWorkflowsQueryVariables>({ query: GetAllWorkflowsDocument, ...options });
};
export const GetChatAvailabilityDocument = gql`
    query GetChatAvailability($chat_uuid: String!, $tenant_uuid: String!) {
  getCoffeeChatAvailability(chat_uuid: $chat_uuid, tenant_uuid: $tenant_uuid)
}
    `;

export function useGetChatAvailabilityQuery(options: Omit<Urql.UseQueryArgs<GetChatAvailabilityQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChatAvailabilityQuery, GetChatAvailabilityQueryVariables>({ query: GetChatAvailabilityDocument, ...options });
};
export const GetChatSettingsDocument = gql`
    query GetChatSettings($tenant_uuid: String!) {
  getChatSettings(tenant_uuid: $tenant_uuid) {
    discover {
      frequency
      is_active
    }
    mentorship {
      frequency
      is_active
    }
    recurring {
      chat_uuid
      chat_with {
        user_uuid
        rsvp
      }
      created_at
      duration
      frequency
      is_active
      title
      location
    }
    stay_connected {
      frequency
      is_active
    }
  }
}
    `;

export function useGetChatSettingsQuery(options: Omit<Urql.UseQueryArgs<GetChatSettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChatSettingsQuery, GetChatSettingsQueryVariables>({ query: GetChatSettingsDocument, ...options });
};
export const GetChatsDocument = gql`
    query GetChats($tenant_uuid: String!) {
  getChats(tenant_uuid: $tenant_uuid) {
    chat_between {
      image
      job_title
      preferred_name
      rsvp
      user_uuid
    }
    chat_uuid
    created_at
    duration
    expiration
    meeting_details {
      calendar {
        account_id
        calendar_id
        calendar_type
        event_id
      }
      label
      link
    }
    meeting_time
    organizer
    proposed_times {
      time
      user_uuid
    }
    tenant_uuid
    title
    type
  }
}
    `;

export function useGetChatsQuery(options: Omit<Urql.UseQueryArgs<GetChatsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetChatsQuery, GetChatsQueryVariables>({ query: GetChatsDocument, ...options });
};
export const GetCommunityDocument = gql`
    query GetCommunity($tenant_uuid: String!, $community_uuid: String!) {
  getCommunity(tenant_uuid: $tenant_uuid, community_uuid: $community_uuid) {
    communication_channel
    communication_guidelines
    community_guidelines
    community_uuid
    description
    hosts {
      image
      preferred_name
      user_uuid
    }
    image
    is_public
    members {
      image
      preferred_name
      user_uuid
    }
    name
    notes
    tags
  }
}
    `;

export function useGetCommunityQuery(options: Omit<Urql.UseQueryArgs<GetCommunityQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCommunityQuery, GetCommunityQueryVariables>({ query: GetCommunityDocument, ...options });
};
export const GetEmployeeInteractionDocument = gql`
    query GetEmployeeInteraction($getEmployeeInteractionInput: GetEmployeeInteractionInput!) {
  getEmployeeInteraction(
    getEmployeeInteractionInput: $getEmployeeInteractionInput
  ) {
    report {
      data {
        y
        x
      }
      id
    }
  }
}
    `;

export function useGetEmployeeInteractionQuery(options: Omit<Urql.UseQueryArgs<GetEmployeeInteractionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEmployeeInteractionQuery, GetEmployeeInteractionQueryVariables>({ query: GetEmployeeInteractionDocument, ...options });
};
export const GetEventDocument = gql`
    query GetEvent($event_uuid: String!, $tenant_uuid: String!) {
  getEvent(event_uuid: $event_uuid, tenant_uuid: $tenant_uuid) {
    category
    checklist
    communities
    description
    event_uuid
    faq
    final_date
    guests {
      image
      preferred_name
      rsvp
      user_uuid
    }
    image
    is_poll
    location_uuid
    name
    no_times_work {
      image
      preferred_name
      user_uuid
    }
    organizers {
      image
      preferred_name
      rsvp
      user_uuid
    }
    poll_deadline
    poll_options {
      date
      votes {
        user_uuid
        preferred_name
        image
      }
    }
    requirements
    resources
    status
    teams
    tenant_uuid
  }
}
    `;

export function useGetEventQuery(options: Omit<Urql.UseQueryArgs<GetEventQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEventQuery, GetEventQueryVariables>({ query: GetEventDocument, ...options });
};
export const GetEventDateRecommendationsDocument = gql`
    query GetEventDateRecommendations($guests: [String!]!, $start_date: DateTime!, $end_date: DateTime!, $tenant_uuid: String!, $location_uuid: String) {
  getEventDateRecommendations(
    guests: $guests
    start_date: $start_date
    end_date: $end_date
    tenant_uuid: $tenant_uuid
    location_uuid: $location_uuid
  ) {
    date
    recommended
    statuses {
      date
      duration
      preferred_name
      status
      status_uuid
      user_uuid
    }
  }
}
    `;

export function useGetEventDateRecommendationsQuery(options: Omit<Urql.UseQueryArgs<GetEventDateRecommendationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEventDateRecommendationsQuery, GetEventDateRecommendationsQueryVariables>({ query: GetEventDateRecommendationsDocument, ...options });
};
export const GetEventTemplateDocument = gql`
    query GetEventTemplate($event_uuid: String!) {
  getEventTemplate(event_uuid: $event_uuid) {
    capacity {
      label
      num
    }
    category
    checklist
    cost {
      label
      num
    }
    description
    duration {
      label
      num
    }
    event_uuid
    faq
    image
    location
    name
    recommended
    requirements
    resources
  }
}
    `;

export function useGetEventTemplateQuery(options: Omit<Urql.UseQueryArgs<GetEventTemplateQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEventTemplateQuery, GetEventTemplateQueryVariables>({ query: GetEventTemplateDocument, ...options });
};
export const GetFeaturesDocument = gql`
    query GetFeatures($tenant_uuid: String!) {
  getFeatures(tenant_uuid: $tenant_uuid) {
    feature_uuid
    plans
    slug
    title
  }
}
    `;

export function useGetFeaturesQuery(options: Omit<Urql.UseQueryArgs<GetFeaturesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>({ query: GetFeaturesDocument, ...options });
};
export const GetFormDocument = gql`
    query GetForm($edit: Boolean!, $form_uuid: String!, $tenant_uuid: String!) {
  getForm(edit: $edit, form_uuid: $form_uuid, tenant_uuid: $tenant_uuid) {
    access {
      editors
      owners
      viewers
    }
    archived
    created_at
    description
    form_uuid
    is_anonymous
    status
    template_uuid
    tenant_uuid
    title
    updated_at
  }
}
    `;

export function useGetFormQuery(options: Omit<Urql.UseQueryArgs<GetFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormQuery, GetFormQueryVariables>({ query: GetFormDocument, ...options });
};
export const GetFormBatchDocument = gql`
    query GetFormBatch($batch_uuid: String!, $tenant_uuid: String!) {
  getFormBatch(batch_uuid: $batch_uuid, tenant_uuid: $tenant_uuid) {
    archived
    batch_uuid
    created_at
    description
    form_uuid
    settings {
      distribution_list {
        opened
        response_status
        user_uuid
      }
      end_date
      is_single_response
      reminder {
        timezone
        time
        frequency
        days
      }
      start_date
    }
    tags {
      key
      value
    }
    tenant_uuid
    title
    updated_at
  }
}
    `;

export function useGetFormBatchQuery(options: Omit<Urql.UseQueryArgs<GetFormBatchQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormBatchQuery, GetFormBatchQueryVariables>({ query: GetFormBatchDocument, ...options });
};
export const GetFormBatchesDetailsDocument = gql`
    query GetFormBatchesDetails($form_uuid: String!, $tenant_uuid: String!) {
  getFormBatchesDetails(form_uuid: $form_uuid, tenant_uuid: $tenant_uuid) {
    batch_uuid
    completion_rate
    open_rate
    form_uuid
    responses
    title
    archived
    start_date
    end_date
  }
}
    `;

export function useGetFormBatchesDetailsQuery(options: Omit<Urql.UseQueryArgs<GetFormBatchesDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormBatchesDetailsQuery, GetFormBatchesDetailsQueryVariables>({ query: GetFormBatchesDetailsDocument, ...options });
};
export const GetFormBatchesInFormDocument = gql`
    query GetFormBatchesInForm($form_uuid: String!, $tenant_uuid: String!) {
  getFormBatchesInForm(form_uuid: $form_uuid, tenant_uuid: $tenant_uuid) {
    archived
    batch_uuid
    created_at
    description
    form_uuid
    settings {
      distribution_list {
        opened
        response_status
        user_uuid
      }
      end_date
      is_single_response
      reminder {
        timezone
        time
        frequency
        days
      }
      start_date
    }
    tags {
      key
      value
    }
    tenant_uuid
    title
    updated_at
  }
}
    `;

export function useGetFormBatchesInFormQuery(options: Omit<Urql.UseQueryArgs<GetFormBatchesInFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormBatchesInFormQuery, GetFormBatchesInFormQueryVariables>({ query: GetFormBatchesInFormDocument, ...options });
};
export const GetFormBatchesInTenantDocument = gql`
    query GetFormBatchesInTenant($tenant_uuid: String!) {
  getFormBatchesInTenant(tenant_uuid: $tenant_uuid) {
    archived
    batch_uuid
    created_at
    description
    form_uuid
    settings {
      distribution_list {
        opened
        response_status
        user_uuid
      }
      end_date
      is_single_response
      reminder {
        timezone
        time
        frequency
        days
      }
      start_date
    }
    tags {
      key
      value
    }
    tenant_uuid
    title
    updated_at
  }
}
    `;

export function useGetFormBatchesInTenantQuery(options: Omit<Urql.UseQueryArgs<GetFormBatchesInTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormBatchesInTenantQuery, GetFormBatchesInTenantQueryVariables>({ query: GetFormBatchesInTenantDocument, ...options });
};
export const GetFormsDetailsDocument = gql`
    query GetFormsDetails($tenant_uuid: String!) {
  getFormsDetails(tenant_uuid: $tenant_uuid) {
    form_uuid
    status
    last_updated
    title
    active_batches
  }
}
    `;

export function useGetFormsDetailsQuery(options: Omit<Urql.UseQueryArgs<GetFormsDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormsDetailsQuery, GetFormsDetailsQueryVariables>({ query: GetFormsDetailsDocument, ...options });
};
export const GetPreBuiltFormQuestionsInBankDocument = gql`
    query GetPreBuiltFormQuestionsInBank($question_uuids: [String!]!) {
  getPreBuiltFormQuestionsInBank(question_uuids: $question_uuids) {
    archived
    created_at
    label
    options {
      label
      option_uuid
    }
    question_uuid
    response_type
    tags
    themes
    updated_at
  }
}
    `;

export function useGetPreBuiltFormQuestionsInBankQuery(options: Omit<Urql.UseQueryArgs<GetPreBuiltFormQuestionsInBankQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPreBuiltFormQuestionsInBankQuery, GetPreBuiltFormQuestionsInBankQueryVariables>({ query: GetPreBuiltFormQuestionsInBankDocument, ...options });
};
export const GetFormPreBuiltTemplateDocument = gql`
    query GetFormPreBuiltTemplate($template_uuid: String!) {
  getPreBuiltFormTemplate(template_uuid: $template_uuid) {
    archived
    created_at
    description
    questions {
      is_locked
      order
      question_uuid
    }
    template_uuid
    title
    updated_at
    version
  }
}
    `;

export function useGetFormPreBuiltTemplateQuery(options: Omit<Urql.UseQueryArgs<GetFormPreBuiltTemplateQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormPreBuiltTemplateQuery, GetFormPreBuiltTemplateQueryVariables>({ query: GetFormPreBuiltTemplateDocument, ...options });
};
export const GetFormPreBuiltTemplatesDocument = gql`
    query GetFormPreBuiltTemplates {
  getPreBuiltFormTemplates {
    archived
    created_at
    description
    questions {
      is_locked
      order
      question_uuid
    }
    template_uuid
    title
    updated_at
    version
  }
}
    `;

export function useGetFormPreBuiltTemplatesQuery(options?: Omit<Urql.UseQueryArgs<GetFormPreBuiltTemplatesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormPreBuiltTemplatesQuery, GetFormPreBuiltTemplatesQueryVariables>({ query: GetFormPreBuiltTemplatesDocument, ...options });
};
export const GetFormQuestionDocument = gql`
    query GetFormQuestion($question_uuid: String!, $tenant_uuid: String!) {
  getFormQuestion(question_uuid: $question_uuid, tenant_uuid: $tenant_uuid) {
    archived
    created_at
    form_uuid
    is_required
    label
    options {
      label
      option_uuid
    }
    question_uuid
    response_type
    tenant_uuid
    themes
    updated_at
  }
}
    `;

export function useGetFormQuestionQuery(options: Omit<Urql.UseQueryArgs<GetFormQuestionQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormQuestionQuery, GetFormQuestionQueryVariables>({ query: GetFormQuestionDocument, ...options });
};
export const GetFormQuestionInsightsDocument = gql`
    query GetFormQuestionInsights($form_uuid: String!, $tenant_uuid: String!) {
  getFormQuestionInsights(form_uuid: $form_uuid, tenant_uuid: $tenant_uuid) {
    qualitative_response_analytics
    quantitative_response_analytics {
      option_count
      option_label
      option_percentage
      option_uuid
    }
    question_uuid
    response_type
  }
}
    `;

export function useGetFormQuestionInsightsQuery(options: Omit<Urql.UseQueryArgs<GetFormQuestionInsightsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormQuestionInsightsQuery, GetFormQuestionInsightsQueryVariables>({ query: GetFormQuestionInsightsDocument, ...options });
};
export const GetFormQuestionsInFormDocument = gql`
    query GetFormQuestionsInForm($form_uuid: String!, $tenant_uuid: String!) {
  getAllFormQuestionsInForm(form_uuid: $form_uuid, tenant_uuid: $tenant_uuid) {
    archived
    created_at
    form_uuid
    is_required
    label
    options {
      label
      option_uuid
    }
    question_uuid
    response_type
    tenant_uuid
    themes
    updated_at
    order
    is_locked
  }
}
    `;

export function useGetFormQuestionsInFormQuery(options: Omit<Urql.UseQueryArgs<GetFormQuestionsInFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormQuestionsInFormQuery, GetFormQuestionsInFormQueryVariables>({ query: GetFormQuestionsInFormDocument, ...options });
};
export const GetFormQuestionsInTenantDocument = gql`
    query GetFormQuestionsInTenant($tenant_uuid: String!) {
  getAllFormQuestionsInTenant(tenant_uuid: $tenant_uuid) {
    archived
    created_at
    form_uuid
    is_required
    label
    options {
      label
      option_uuid
    }
    question_uuid
    response_type
    tenant_uuid
    themes
    updated_at
  }
}
    `;

export function useGetFormQuestionsInTenantQuery(options: Omit<Urql.UseQueryArgs<GetFormQuestionsInTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormQuestionsInTenantQuery, GetFormQuestionsInTenantQueryVariables>({ query: GetFormQuestionsInTenantDocument, ...options });
};
export const GetFormResponseDocument = gql`
    query GetFormResponse($response_uuid: String!, $tenant_uuid: String!) {
  getFormResponse(response_uuid: $response_uuid, tenant_uuid: $tenant_uuid) {
    archived
    batch_uuid
    created_at
    form_uuid
    qualitative_response
    quantitative_response {
      label
      option_uuid
    }
    question_uuid
    response_status
    response_type
    response_uuid
    tenant_uuid
    updated_at
    user_uuid
  }
}
    `;

export function useGetFormResponseQuery(options: Omit<Urql.UseQueryArgs<GetFormResponseQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormResponseQuery, GetFormResponseQueryVariables>({ query: GetFormResponseDocument, ...options });
};
export const GetFormResponsesDetailsInFormDocument = gql`
    query GetFormResponsesDetailsInForm($form_uuid: String!, $tenant_uuid: String!) {
  getFormResponsesDetailsInForm(form_uuid: $form_uuid, tenant_uuid: $tenant_uuid) {
    batch_name
    batch_uuid
    form_uuid
    is_anonymous
    response_data {
      qualitative_response
      quantitative_response {
        label
        option_uuid
      }
      question_uuid
      response_type
      response_uuid
    }
    updated_at
    user_uuid
  }
}
    `;

export function useGetFormResponsesDetailsInFormQuery(options: Omit<Urql.UseQueryArgs<GetFormResponsesDetailsInFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormResponsesDetailsInFormQuery, GetFormResponsesDetailsInFormQueryVariables>({ query: GetFormResponsesDetailsInFormDocument, ...options });
};
export const GetFormResponsesToSubmitDocument = gql`
    query GetFormResponsesToSubmit($batch_uuid: String!, $tenant_uuid: String!, $user_uuid: String!) {
  getFormResponsesToSubmit(
    batch_uuid: $batch_uuid
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    archived
    batch_uuid
    created_at
    form_uuid
    qualitative_response
    quantitative_response {
      label
      option_uuid
    }
    question_uuid
    response_status
    response_type
    response_uuid
    tenant_uuid
    updated_at
    user_uuid
  }
}
    `;

export function useGetFormResponsesToSubmitQuery(options: Omit<Urql.UseQueryArgs<GetFormResponsesToSubmitQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormResponsesToSubmitQuery, GetFormResponsesToSubmitQueryVariables>({ query: GetFormResponsesToSubmitDocument, ...options });
};
export const GetFormResponsesInBatchDocument = gql`
    query GetFormResponsesInBatch($batch_uuid: String!, $tenant_uuid: String!) {
  getAllFormResponsesInBatch(batch_uuid: $batch_uuid, tenant_uuid: $tenant_uuid) {
    archived
    batch_uuid
    created_at
    form_uuid
    qualitative_response
    quantitative_response {
      label
      option_uuid
    }
    question_uuid
    response_status
    response_type
    response_uuid
    tenant_uuid
    updated_at
    user_uuid
  }
}
    `;

export function useGetFormResponsesInBatchQuery(options: Omit<Urql.UseQueryArgs<GetFormResponsesInBatchQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormResponsesInBatchQuery, GetFormResponsesInBatchQueryVariables>({ query: GetFormResponsesInBatchDocument, ...options });
};
export const GetFormResponsesInFormDocument = gql`
    query GetFormResponsesInForm($form_uuid: String!, $tenant_uuid: String!) {
  getAllFormResponsesInForm(form_uuid: $form_uuid, tenant_uuid: $tenant_uuid) {
    archived
    batch_uuid
    created_at
    form_uuid
    qualitative_response
    quantitative_response {
      label
      option_uuid
    }
    question_uuid
    response_status
    response_type
    response_uuid
    tenant_uuid
    updated_at
    user_uuid
  }
}
    `;

export function useGetFormResponsesInFormQuery(options: Omit<Urql.UseQueryArgs<GetFormResponsesInFormQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormResponsesInFormQuery, GetFormResponsesInFormQueryVariables>({ query: GetFormResponsesInFormDocument, ...options });
};
export const GetFormResponsesInTenantDocument = gql`
    query GetFormResponsesInTenant($tenant_uuid: String!) {
  getAllFormResponsesInTenant(tenant_uuid: $tenant_uuid) {
    archived
    batch_uuid
    created_at
    form_uuid
    qualitative_response
    quantitative_response {
      label
      option_uuid
    }
    question_uuid
    response_status
    response_type
    response_uuid
    tenant_uuid
    updated_at
    user_uuid
  }
}
    `;

export function useGetFormResponsesInTenantQuery(options: Omit<Urql.UseQueryArgs<GetFormResponsesInTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormResponsesInTenantQuery, GetFormResponsesInTenantQueryVariables>({ query: GetFormResponsesInTenantDocument, ...options });
};
export const GetFormTemplateDocument = gql`
    query GetFormTemplate($template_uuid: String!, $tenant_uuid: String!) {
  getFormTemplate(template_uuid: $template_uuid, tenant_uuid: $tenant_uuid) {
    access {
      editors
      owners
      viewers
    }
    archived
    created_at
    description
    questions {
      is_locked
      order
      question_uuid
    }
    template_uuid
    tenant_uuid
    title
    updated_at
    version
  }
}
    `;

export function useGetFormTemplateQuery(options: Omit<Urql.UseQueryArgs<GetFormTemplateQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>({ query: GetFormTemplateDocument, ...options });
};
export const GetFormsDocument = gql`
    query GetForms($tenant_uuid: String!) {
  getForms(tenant_uuid: $tenant_uuid) {
    access {
      editors
      owners
      viewers
    }
    archived
    created_at
    description
    form_uuid
    is_anonymous
    status
    template_uuid
    tenant_uuid
    title
    updated_at
  }
}
    `;

export function useGetFormsQuery(options: Omit<Urql.UseQueryArgs<GetFormsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormsQuery, GetFormsQueryVariables>({ query: GetFormsDocument, ...options });
};
export const GetFormsToCompleteDocument = gql`
    query GetFormsToComplete($tenant_uuid: String!, $user_uuid: String!) {
  getFormsToComplete(tenant_uuid: $tenant_uuid, user_uuid: $user_uuid) {
    batch_uuid
    creator_uuid
    creator_name
    form_uuid
    form_name
    opened
    end_date
  }
}
    `;

export function useGetFormsToCompleteQuery(options: Omit<Urql.UseQueryArgs<GetFormsToCompleteQueryVariables>, 'query'>) {
  return Urql.useQuery<GetFormsToCompleteQuery, GetFormsToCompleteQueryVariables>({ query: GetFormsToCompleteDocument, ...options });
};
export const GetInvoiceItemsDocument = gql`
    query GetInvoiceItems($tenantUuid: String!) {
  getInvoiceItems(tenant_uuid: $tenantUuid) {
    amount
    date
    description
    invoice_id
    link
  }
}
    `;

export function useGetInvoiceItemsQuery(options: Omit<Urql.UseQueryArgs<GetInvoiceItemsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInvoiceItemsQuery, GetInvoiceItemsQueryVariables>({ query: GetInvoiceItemsDocument, ...options });
};
export const GetJobDocument = gql`
    query GetJob($job_title: String!) {
  getJob(job_title: $job_title) {
    job_uuid
    job_title
    top_skills {
      skill_uuid
      skill_label
    }
  }
}
    `;

export function useGetJobQuery(options: Omit<Urql.UseQueryArgs<GetJobQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobQuery, GetJobQueryVariables>({ query: GetJobDocument, ...options });
};
export const GetJobsDocument = gql`
    query GetJobs($search_keyword: String!) {
  getJobs(search_keyword: $search_keyword) {
    job_uuid
    job_title
    top_skills {
      skill_uuid
      skill_label
    }
  }
}
    `;

export function useGetJobsQuery(options: Omit<Urql.UseQueryArgs<GetJobsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetJobsQuery, GetJobsQueryVariables>({ query: GetJobsDocument, ...options });
};
export const GetLocationDocument = gql`
    query GetLocation($location_uuid: String!) {
  getLocation(location_uuid: $location_uuid) {
    address
    address_notes
    amenities
    archive
    capacity_limit
    community {
      image
      preferred_name
      user_uuid
    }
    hosts {
      image
      preferred_name
      user_uuid
    }
    hours_operation {
      fri
      mon
      sat
      sun
      thu
      tue
      wed
    }
    image
    location_uuid
    name
    notes
    slack_channel
    tenant_uuid
    wifi_information {
      certificates
      contact
      network_name
      password
      security_type
    }
  }
}
    `;

export function useGetLocationQuery(options: Omit<Urql.UseQueryArgs<GetLocationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLocationQuery, GetLocationQueryVariables>({ query: GetLocationDocument, ...options });
};
export const GetLocationsDocument = gql`
    query GetLocations($tenant_uuid: String!) {
  listLocations(tenant_uuid: $tenant_uuid) {
    address
    address_notes
    amenities
    archive
    capacity_limit
    community {
      image
      preferred_name
      user_uuid
    }
    hosts {
      image
      preferred_name
      user_uuid
    }
    hours_operation {
      fri
      mon
      sat
      sun
      thu
      tue
      wed
    }
    image
    location_uuid
    name
    notes
    slack_channel
    tenant_uuid
    wifi_information {
      certificates
      contact
      network_name
      password
      security_type
    }
  }
}
    `;

export function useGetLocationsQuery(options: Omit<Urql.UseQueryArgs<GetLocationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLocationsQuery, GetLocationsQueryVariables>({ query: GetLocationsDocument, ...options });
};
export const GetMostFrequentDayDocument = gql`
    query GetMostFrequentDay($updateUserBehaviourInput: UpdateUserBehaviourInput!) {
  getMostFrequentDay(updateUserBehaviourInput: $updateUserBehaviourInput)
}
    `;

export function useGetMostFrequentDayQuery(options: Omit<Urql.UseQueryArgs<GetMostFrequentDayQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMostFrequentDayQuery, GetMostFrequentDayQueryVariables>({ query: GetMostFrequentDayDocument, ...options });
};
export const GetNotificationsDocument = gql`
    query GetNotifications($tenant_uuid: String!, $limit: Float!, $page: Float!) {
  getNotifications(tenant_uuid: $tenant_uuid, limit: $limit, page: $page) {
    notification_uuid
    notification_type
    sender_uuid
    sender_image
    sender_name
    message_parts {
      text
      text_weight
    }
    is_read
    link
    created_at
  }
}
    `;

export function useGetNotificationsQuery(options: Omit<Urql.UseQueryArgs<GetNotificationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>({ query: GetNotificationsDocument, ...options });
};
export const GetNotificationsSettingsDocument = gql`
    query GetNotificationsSettings($tenant_uuid: String!) {
  getNotificationsSettings(tenant_uuid: $tenant_uuid) {
    email_active
    email_coffee_chat_notification
    email_event_notification
    email_one_on_one_notification
    slack_active
    slack_coffee_chat_notification
    slack_event_notification
    slack_one_on_one_notification
    tenant_uuid
    user_uuid
  }
}
    `;

export function useGetNotificationsSettingsQuery(options: Omit<Urql.UseQueryArgs<GetNotificationsSettingsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetNotificationsSettingsQuery, GetNotificationsSettingsQueryVariables>({ query: GetNotificationsSettingsDocument, ...options });
};
export const GetOfficeRecommendationsDocument = gql`
    query GetOfficeRecommendations($officeRecommendationsInput: OfficeRecommendationsInput!) {
  getOfficeRecommendations(
    officeRecommendationsInput: $officeRecommendationsInput
  ) {
    date
    ranking
    reasons
  }
}
    `;

export function useGetOfficeRecommendationsQuery(options: Omit<Urql.UseQueryArgs<GetOfficeRecommendationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOfficeRecommendationsQuery, GetOfficeRecommendationsQueryVariables>({ query: GetOfficeRecommendationsDocument, ...options });
};
export const GetOfficeUtilizationDocument = gql`
    query GetOfficeUtilization($getOfficeUtilizationInput: GetOfficeUtilizationInput!) {
  getOfficeUtilization(getOfficeUtilizationInput: $getOfficeUtilizationInput) {
    report {
      id
      data {
        y
        x
      }
    }
  }
}
    `;

export function useGetOfficeUtilizationQuery(options: Omit<Urql.UseQueryArgs<GetOfficeUtilizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOfficeUtilizationQuery, GetOfficeUtilizationQueryVariables>({ query: GetOfficeUtilizationDocument, ...options });
};
export const GetOrgAnalyticsDocument = gql`
    query GetOrgAnalytics($num_of_weeks: Float!, $tenant_uuid: String!) {
  getOrgAnalytics(num_of_weeks: $num_of_weeks, tenant_uuid: $tenant_uuid) {
    available_hours
    blocked_hours
    chat_engagement
    created_at
    engagement_score
    event_count
    event_engagement
    focused_hours
    hours_saved
    office_engagement
    slack_community_data {
      community_uuid
      interactions
    }
    slack_engagement
    tenant_uuid
  }
}
    `;

export function useGetOrgAnalyticsQuery(options: Omit<Urql.UseQueryArgs<GetOrgAnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgAnalyticsQuery, GetOrgAnalyticsQueryVariables>({ query: GetOrgAnalyticsDocument, ...options });
};
export const GetOrgAvailabilityDocument = gql`
    query GetOrgAvailability($num_of_weeks: Float!, $tenant_uuid: String!) {
  getOrgAnalytics(num_of_weeks: $num_of_weeks, tenant_uuid: $tenant_uuid) {
    available_hours
    focused_hours
    blocked_hours
    created_at
  }
}
    `;

export function useGetOrgAvailabilityQuery(options: Omit<Urql.UseQueryArgs<GetOrgAvailabilityQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgAvailabilityQuery, GetOrgAvailabilityQueryVariables>({ query: GetOrgAvailabilityDocument, ...options });
};
export const GetOrgChatAnalyticsDocument = gql`
    query GetOrgChatAnalytics($num_of_weeks: Float!, $tenant_uuid: String!) {
  getOrgAnalytics(num_of_weeks: $num_of_weeks, tenant_uuid: $tenant_uuid) {
    chat_engagement
    created_at
  }
}
    `;

export function useGetOrgChatAnalyticsQuery(options: Omit<Urql.UseQueryArgs<GetOrgChatAnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgChatAnalyticsQuery, GetOrgChatAnalyticsQueryVariables>({ query: GetOrgChatAnalyticsDocument, ...options });
};
export const GetOrgEventAnalyticsDocument = gql`
    query GetOrgEventAnalytics($num_of_weeks: Float!, $tenant_uuid: String!) {
  getOrgAnalytics(num_of_weeks: $num_of_weeks, tenant_uuid: $tenant_uuid) {
    event_engagement
    created_at
  }
}
    `;

export function useGetOrgEventAnalyticsQuery(options: Omit<Urql.UseQueryArgs<GetOrgEventAnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgEventAnalyticsQuery, GetOrgEventAnalyticsQueryVariables>({ query: GetOrgEventAnalyticsDocument, ...options });
};
export const GetOrgOfficeAnalyticsDocument = gql`
    query GetOrgOfficeAnalytics($num_of_weeks: Float!, $tenant_uuid: String!) {
  getOrgAnalytics(num_of_weeks: $num_of_weeks, tenant_uuid: $tenant_uuid) {
    office_engagement
    created_at
  }
}
    `;

export function useGetOrgOfficeAnalyticsQuery(options: Omit<Urql.UseQueryArgs<GetOrgOfficeAnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgOfficeAnalyticsQuery, GetOrgOfficeAnalyticsQueryVariables>({ query: GetOrgOfficeAnalyticsDocument, ...options });
};
export const GetOrgTeamActivitiesAnalyticsDocument = gql`
    query GetOrgTeamActivitiesAnalytics($num_of_weeks: Float!, $tenant_uuid: String!) {
  getOrgAnalytics(num_of_weeks: $num_of_weeks, tenant_uuid: $tenant_uuid) {
    event_count
    created_at
  }
}
    `;

export function useGetOrgTeamActivitiesAnalyticsQuery(options: Omit<Urql.UseQueryArgs<GetOrgTeamActivitiesAnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrgTeamActivitiesAnalyticsQuery, GetOrgTeamActivitiesAnalyticsQueryVariables>({ query: GetOrgTeamActivitiesAnalyticsDocument, ...options });
};
export const GetPastFourWeeksDocument = gql`
    query GetPastFourWeeks($getPastFourWeeksInput: GetPastFourWeeksInput!) {
  getPastFourWeeks(getReportInput: $getPastFourWeeksInput) {
    eventCount
    peopleMetAtEvents
    peopleMetAtOffice
    teamsAtOffice
  }
}
    `;

export function useGetPastFourWeeksQuery(options: Omit<Urql.UseQueryArgs<GetPastFourWeeksQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPastFourWeeksQuery, GetPastFourWeeksQueryVariables>({ query: GetPastFourWeeksDocument, ...options });
};
export const GetReportDocument = gql`
    query GetReport($getReportInput: GetReportInput!) {
  getReport(getReportInput: $getReportInput) {
    most_used_status
    office_utilization
    status_rate {
      OFFICE
      REMOTE
      NOT_WORKING
    }
    unique_users
    capacity_utilization {
      date
      utilization
    }
  }
}
    `;

export function useGetReportQuery(options: Omit<Urql.UseQueryArgs<GetReportQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReportQuery, GetReportQueryVariables>({ query: GetReportDocument, ...options });
};
export const GetRoomBookingDocument = gql`
    query GetRoomBooking($roomBookingUuid: String!) {
  getRoomBooking(room_booking_uuid: $roomBookingUuid) {
    created_at
    description
    end_time
    final_date
    guests {
      image
      preferred_name
      user_uuid
    }
    location_uuid
    room_booking_name
    room_booking_uuid
    room_uuid
    start_time
    tenant_uuid
  }
}
    `;

export function useGetRoomBookingQuery(options: Omit<Urql.UseQueryArgs<GetRoomBookingQueryVariables>, 'query'>) {
  return Urql.useQuery<GetRoomBookingQuery, GetRoomBookingQueryVariables>({ query: GetRoomBookingDocument, ...options });
};
export const GetSlackChannelsDocument = gql`
    query GetSlackChannels($tenantId: String!) {
  getSlackChannels(tenant_id: $tenantId) {
    id
    name
  }
}
    `;

export function useGetSlackChannelsQuery(options: Omit<Urql.UseQueryArgs<GetSlackChannelsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSlackChannelsQuery, GetSlackChannelsQueryVariables>({ query: GetSlackChannelsDocument, ...options });
};
export const GetSlackWorkflowsDocument = gql`
    query GetSlackWorkflows($tenant_uuid: String!) {
  getSlackWorkflows(tenant_uuid: $tenant_uuid) {
    tenant_uuid
    watercooler_chats {
      channel_id
      channel_name
      days
      frequency
      is_active
    }
  }
}
    `;

export function useGetSlackWorkflowsQuery(options: Omit<Urql.UseQueryArgs<GetSlackWorkflowsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetSlackWorkflowsQuery, GetSlackWorkflowsQueryVariables>({ query: GetSlackWorkflowsDocument, ...options });
};
export const GetStatusesDocument = gql`
    query GetStatuses($getStatusesInput: GetStatusesInput!) {
  getStatuses(getStatusesInput: $getStatusesInput) {
    duration
    preferred_name
    status
    status_uuid
    user_uuid
    date
    checked_in
  }
}
    `;

export function useGetStatusesQuery(options: Omit<Urql.UseQueryArgs<GetStatusesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStatusesQuery, GetStatusesQueryVariables>({ query: GetStatusesDocument, ...options });
};
export const GetTeamDocument = gql`
    query GetTeam($team_uuid: String!, $tenant_uuid: String!) {
  getTeam(team_uuid: $team_uuid, tenant_uuid: $tenant_uuid) {
    insights {
      in_office_frequency {
        MON
        TUE
        WED
        THU
        FRI
        SAT
        SUN
      }
      status_rate {
        OFFICE
        REMOTE
        NOT_WORKING
      }
      event_engagement
      top_five_interests
    }
    label
    users {
      preferred_name
      interests
      goals
      skills
      user_uuid
      image
    }
    leads {
      preferred_name
      interests
      goals
      skills
      user_uuid
      image
    }
  }
}
    `;

export function useGetTeamQuery(options: Omit<Urql.UseQueryArgs<GetTeamQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTeamQuery, GetTeamQueryVariables>({ query: GetTeamDocument, ...options });
};
export const GetTeamMemberDocument = gql`
    query GetTeamMember($user_uuid: String!) {
  getUser(user_uuid: $user_uuid) {
    created_at
    email
    preferred_name
    teams {
      label
      team_uuid
    }
    user_uuid
    role
    tenant_uuid
    is_onboarded
    updated_at
    image
  }
}
    `;

export function useGetTeamMemberQuery(options: Omit<Urql.UseQueryArgs<GetTeamMemberQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTeamMemberQuery, GetTeamMemberQueryVariables>({ query: GetTeamMemberDocument, ...options });
};
export const GetTeamOfficePresenceDocument = gql`
    query GetTeamOfficePresence($getTeamOfficePresenceInput: GetTeamOfficePresenceInput!) {
  getTeamOfficePresence(getTeamOfficePresenceInput: $getTeamOfficePresenceInput) {
    report {
      data {
        y
        x
      }
      id
    }
  }
}
    `;

export function useGetTeamOfficePresenceQuery(options: Omit<Urql.UseQueryArgs<GetTeamOfficePresenceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTeamOfficePresenceQuery, GetTeamOfficePresenceQueryVariables>({ query: GetTeamOfficePresenceDocument, ...options });
};
export const GetTenantDocument = gql`
    query GetTenant($tenant_uuid: String!) {
  getTenant(tenant_uuid: $tenant_uuid) {
    tenant_uuid
    title
    logo
    preferred_office_day_count
    schedule_weekends
    company_type
    users
    payment {
      plan
      currency
      discount
      billing_frequency
      premium_user_count
    }
  }
}
    `;

export function useGetTenantQuery(options: Omit<Urql.UseQueryArgs<GetTenantQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantQuery, GetTenantQueryVariables>({ query: GetTenantDocument, ...options });
};
export const GetTenantUserCountDocument = gql`
    query GetTenantUserCount($tenantUuid: String!) {
  getTenantUserCount(tenant_uuid: $tenantUuid)
}
    `;

export function useGetTenantUserCountQuery(options: Omit<Urql.UseQueryArgs<GetTenantUserCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantUserCountQuery, GetTenantUserCountQueryVariables>({ query: GetTenantUserCountDocument, ...options });
};
export const GetTenantUsersDocument = gql`
    query GetTenantUsers($getTenantUsersInput: GetTenantUsersInput!) {
  getTenantUsers(getTenantUsersInput: $getTenantUsersInput) {
    users {
      user_uuid
      preferred_name
      email
      selected_location_id
      teams {
        label
      }
      role
      is_signed_up
      image
    }
    total_pages
    current_page
    total_users
  }
}
    `;

export function useGetTenantUsersQuery(options: Omit<Urql.UseQueryArgs<GetTenantUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTenantUsersQuery, GetTenantUsersQueryVariables>({ query: GetTenantUsersDocument, ...options });
};
export const GetTimeBlocksDocument = gql`
    query GetTimeBlocks($endDate: DateTime!, $startDate: DateTime!, $tenantUuid: String!, $userUuid: String!) {
  getTimeBlocks(
    end_date: $endDate
    start_date: $startDate
    tenant_uuid: $tenantUuid
    user_uuid: $userUuid
  ) {
    calendar
    created_at
    end
    end_date
    omitted_dates
    repeat
    start
    tenant_uuid
    time_block_uuid
    type
    user_uuid
  }
}
    `;

export function useGetTimeBlocksQuery(options: Omit<Urql.UseQueryArgs<GetTimeBlocksQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTimeBlocksQuery, GetTimeBlocksQueryVariables>({ query: GetTimeBlocksDocument, ...options });
};
export const GetUpcomingActivitiesDocument = gql`
    query GetUpcomingActivities($tenant_uuid: String!) {
  getBirthdays(tenant_uuid: $tenant_uuid) {
    birthday
    image
    user_uuid
    preferred_name
  }
  getChats(tenant_uuid: $tenant_uuid) {
    chat_between {
      image
      job_title
      preferred_name
      rsvp
      user_uuid
    }
    chat_uuid
    created_at
    duration
    expiration
    meeting_details {
      label
      link
    }
    meeting_time
    organizer
    tenant_uuid
    title
    type
  }
  getAllEvents(tenant_uuid: $tenant_uuid) {
    scheduled {
      category
      checklist
      communities
      description
      event_uuid
      faq
      final_date
      image
      is_poll
      location_uuid
      name
      poll_deadline
      requirements
      resources
      status
      teams
      tenant_uuid
    }
  }
}
    `;

export function useGetUpcomingActivitiesQuery(options: Omit<Urql.UseQueryArgs<GetUpcomingActivitiesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUpcomingActivitiesQuery, GetUpcomingActivitiesQueryVariables>({ query: GetUpcomingActivitiesDocument, ...options });
};
export const GetUserDocument = gql`
    query GetUser($user_uuid: String!) {
  getUser(user_uuid: $user_uuid) {
    user_uuid
    preferred_name
    full_name
    email
    isConnectedSlackUser
    bio
    birthday
    start_date
    timezone
    phone_number
    role
    tenant_uuid
    teams {
      team_uuid
      label
    }
    skills
    interests
    job_title
    was_invited
    created_at
    updated_at
    selected_location_id
    onboarding_step
    is_onboarded
    is_signed_up
    isFollowedByMe
    isConnectedWithSlack
    image
    isConnectedWithGoogleCal
  }
}
    `;

export function useGetUserQuery(options: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options });
};
export const GetUserAnalyticsDocument = gql`
    query GetUserAnalytics($num_of_weeks: Float!, $tenant_uuid: String!, $user_uuid: String!) {
  getUserAnalytics(
    num_of_weeks: $num_of_weeks
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    connections
    created_at
    meeting_hours {
      day_number
      hours
    }
  }
}
    `;

export function useGetUserAnalyticsQuery(options: Omit<Urql.UseQueryArgs<GetUserAnalyticsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>({ query: GetUserAnalyticsDocument, ...options });
};
export const GetUserAnalyticsConnectionsDocument = gql`
    query GetUserAnalyticsConnections($num_of_weeks: Float!, $tenant_uuid: String!, $user_uuid: String!) {
  getUserAnalytics(
    num_of_weeks: $num_of_weeks
    tenant_uuid: $tenant_uuid
    user_uuid: $user_uuid
  ) {
    connections
    created_at
    meeting_hours {
      day_number
      hours
    }
  }
}
    `;

export function useGetUserAnalyticsConnectionsQuery(options: Omit<Urql.UseQueryArgs<GetUserAnalyticsConnectionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserAnalyticsConnectionsQuery, GetUserAnalyticsConnectionsQueryVariables>({ query: GetUserAnalyticsConnectionsDocument, ...options });
};
export const GetUserSelectedLocationDocument = gql`
    query GetUserSelectedLocation($user_uuid: String!) {
  getUserSelectedLocation(user_uuid: $user_uuid)
}
    `;

export function useGetUserSelectedLocationQuery(options: Omit<Urql.UseQueryArgs<GetUserSelectedLocationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserSelectedLocationQuery, GetUserSelectedLocationQueryVariables>({ query: GetUserSelectedLocationDocument, ...options });
};
export const GetUserStepsDocument = gql`
    query getUserSteps($user_uuid: String!) {
  getUserSteps(user_uuid: $user_uuid) {
    create_teams
    invite_members
    slack_integration
    google_calendar
    follow_people
    filled_profile
    is_admin
  }
}
    `;

export function useGetUserStepsQuery(options: Omit<Urql.UseQueryArgs<GetUserStepsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserStepsQuery, GetUserStepsQueryVariables>({ query: GetUserStepsDocument, ...options });
};
export const GetUserTeamsDocument = gql`
    query GetUserTeams($user_uuid: String!) {
  getUser(user_uuid: $user_uuid) {
    teams {
      team_uuid
      label
    }
  }
}
    `;

export function useGetUserTeamsQuery(options: Omit<Urql.UseQueryArgs<GetUserTeamsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserTeamsQuery, GetUserTeamsQueryVariables>({ query: GetUserTeamsDocument, ...options });
};
export const GetWaterCoolerTopicsDocument = gql`
    query GetWaterCoolerTopics {
  getWaterCoolerTopics {
    category
    values {
      gif
      message
    }
  }
}
    `;

export function useGetWaterCoolerTopicsQuery(options?: Omit<Urql.UseQueryArgs<GetWaterCoolerTopicsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWaterCoolerTopicsQuery, GetWaterCoolerTopicsQueryVariables>({ query: GetWaterCoolerTopicsDocument, ...options });
};
export const GetWorkflowDocument = gql`
    query GetWorkflow($tenant_uuid: String!, $workflow_uuid: String!) {
  getWorkflow(tenant_uuid: $tenant_uuid, workflow_uuid: $workflow_uuid) {
    channel_id
    channel_name
    created_at
    days
    frequency
    is_active
    platform
    send_message_as
    send_time
    tenant_uuid
    time_zone
    updated_at
    workflow_access
    workflow_title
    workflow_owner
    workflow_type
    workflow_uuid
  }
}
    `;

export function useGetWorkflowQuery(options: Omit<Urql.UseQueryArgs<GetWorkflowQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWorkflowQuery, GetWorkflowQueryVariables>({ query: GetWorkflowDocument, ...options });
};
export const GetWorkspaceInsightsDocument = gql`
    query GetWorkspaceInsights($getWorkspaceInsightsInput: GetWorkspaceInsightsInput!) {
  getWorkspaceInsights(getWorkspaceInsightsInput: $getWorkspaceInsightsInput) {
    admin_hours
    engagement_score
    num_creators
    num_events
    num_guests
    trend_events
    trend_hours
    trend_score
  }
}
    `;

export function useGetWorkspaceInsightsQuery(options: Omit<Urql.UseQueryArgs<GetWorkspaceInsightsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWorkspaceInsightsQuery, GetWorkspaceInsightsQueryVariables>({ query: GetWorkspaceInsightsDocument, ...options });
};
export const ListAllRoomBookingsDocument = gql`
    query ListAllRoomBookings($location_uuid: String!, $tenant_uuid: String!) {
  listAllRoomBookings(location_uuid: $location_uuid, tenant_uuid: $tenant_uuid) {
    booked_rooms {
      capacity
      images
      description
      room_name
      room_uuid
      scheduled {
        description
        end_time
        final_date
        guests {
          image
          preferred_name
          user_uuid
        }
        room_booking_name
        room_booking_uuid
        start_time
      }
    }
  }
}
    `;

export function useListAllRoomBookingsQuery(options: Omit<Urql.UseQueryArgs<ListAllRoomBookingsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListAllRoomBookingsQuery, ListAllRoomBookingsQueryVariables>({ query: ListAllRoomBookingsDocument, ...options });
};
export const ListAllRoomsDocument = gql`
    query ListAllRooms($location_uuid: String!, $tenant_uuid: String!) {
  listAllRooms(location_uuid: $location_uuid, tenant_uuid: $tenant_uuid) {
    booked_rooms {
      capacity
      description
      images
      location_uuid
      room_name
      room_uuid
      tenant_uuid
    }
  }
}
    `;

export function useListAllRoomsQuery(options: Omit<Urql.UseQueryArgs<ListAllRoomsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListAllRoomsQuery, ListAllRoomsQueryVariables>({ query: ListAllRoomsDocument, ...options });
};
export const ListGoogleCalEventsDocument = gql`
    query ListGoogleCalEvents($endTime: DateTime!, $startTime: DateTime!) {
  listGoogleCalEvents(end_time: $endTime, start_time: $startTime)
}
    `;

export function useListGoogleCalEventsQuery(options: Omit<Urql.UseQueryArgs<ListGoogleCalEventsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListGoogleCalEventsQuery, ListGoogleCalEventsQueryVariables>({ query: ListGoogleCalEventsDocument, ...options });
};